import { CALCULATE_ODD, CLEAR_ODD, REMOVE_ODD, SHOW_DETAIL_HOME } from "../actions/actiontypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  showDetail: null,
  odds: [],
  uuid: null,
  requestBody: [],
  customSwitch: false,
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DETAIL_HOME: {
      return {
        ...state,
        showDetail: action.payload.showDetail,
      };
    }

    case CALCULATE_ODD: {
      if (state.odds.length === 0) {
        state.uuid = uuidv4();
      }
      return {
        ...state,
        odds: state.odds.some((odd) => odd.id === action.payload.id)
          ? state.odds.map((odd) => (odd.id === action.payload.id ? { ...odd, ...action.payload } : odd))
          : [...state.odds, action.payload],
      };
    }
    case REMOVE_ODD:
      return {
        ...state,
        odds: state.odds.filter((odd) => !(odd.id === action.id)),
      };

    case CLEAR_ODD:
      return {
        ...state,
        odds: [],
      };

    case "UPDATEREQUESTBODY": {
      return {
        ...state,
        requestBody: action.payload,
      };
    }
    case "CUSTOMSWITCHCHANGE": {
      return {
        ...state,
        customSwitch: !state.customSwitch,
      };
    }
    default:
      return state;
  }
};

export default homeReducer;
