import LoginPage from "./app/pages/auth/Login";
import RegisterPage from "./app/pages/auth/RegisterPage";
import BetGuide from "./app/pages/bet-guide/page";
import Dashboard from "./app/pages/dashboard";
import HomePage from "./app/pages/default/Homepage";
import LiveBetting from "./app/pages/livebetting/page";
import { UserMain } from "./app/pages/user-page/userMain";
import Payout from "./app/pages/user-page/payout";
import MyBetsLayout from "./app/Layout/MyBetsLayout";
import MyBets from "./app/pages/user-page/userWetten";
import UserLayout from "./app/Layout/UserLayout";
import { MobilDynamicMenus } from "./app/pages/default/mobilDynamicMenu";

const routes = [
  {
    path: "/login",
    element: LoginPage,
    isProtected: false,
  },
  {
    path: "/register",
    element: RegisterPage,
    isProtected: false,
  },
  {
    path: "*",
    element: HomePage,
    isProtected: false,
  },
  {
    path: "/live-betting",
    element: LiveBetting,
    isProtected: false,
  },
  {
    path: "/bet-guide",
    element: BetGuide,
    isProtected: false,
  },
  {
    path: "/userPage",
    element: UserMain,
    isProtected: true,
  },
  {
    path: "/payout",
    element: Payout,
    isProtected: false,
  },
  {
    path: "/meinWetten",
    element: MyBets,
    isProtected: true,
  },
  {
    path: "/mobilDynamic",
    element: MobilDynamicMenus,
    isProtected: false,
  },
];

export default routes;
