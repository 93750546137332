import { CALCULATE_BETSLIP_FAILURE, CALCULATE_BETSLIP_SUCCESS } from "../actions/actiontypes";
import { isEqual } from "lodash";

const initialState = {
  data: null,
  body: null,
  betBuilderMatches: [],
  bankers: [],
  selectedSystemTypes: [],
  nonCombineMarkets: [],
  errors: [],
  showPopup: {
    active: false,
    matchId: null,
    isShowing: false,
  },
  error: null,
};

export const CalculateBetSlipReducer = (state = initialState, action) => {
  switch (action.type) {
    case CALCULATE_BETSLIP_SUCCESS: {
      const errors = [];
      const nonCombineMarkets = [];
      const bankers = [];
      action.payload.data.markets.forEach((element) => {
        if (element.isHaveError) {
          errors.push(element.matchId);
        }
        if (element.banker) {
          bankers.push(element.matchId);
        }
        element.nonCombineMarkets?.forEach((innerArray) => {
          innerArray.forEach((e) => {
            nonCombineMarkets.push(element.matchId + "-" + e.marketId + "-" + e.outcomeId);
          });
        });
      });
      const updatedBetBuilderMatches = [...state.betBuilderMatches];

      updatedBetBuilderMatches.forEach((match) => {
        if (!action.payload.body.betBuilders.some((e) => Number(e.split("-")[0]) === match.matchId)) {
          const index = updatedBetBuilderMatches.indexOf(match);
          if (index !== -1) {
            updatedBetBuilderMatches.splice(index, 1);
          }
        }
      });

      action.payload.data.betBuilderCandidatesList
        .map((e) => Number(e.split("-")[0]))
        .forEach((e) => {
          if (!updatedBetBuilderMatches.some((k) => k.matchId === e)) {
            const [asd] = action.payload.data.markets.filter((z) => z.matchId === e);
            updatedBetBuilderMatches.push({
              matchId: e,
              name: asd.name,
              active: false,
            });
          }
        });
      const sortedUpdatedBetBuilderMatches = updatedBetBuilderMatches.sort((x, y) => x.matchId - y.matchId);

      const values = [...action.payload.data.selectedSystemTypes];
      if (values && values.length === 1) {
        if (!state?.data?.availableSystemTypes.some((e) => e.systemType === values[0])) {
          values.length = 0;
        }
      }

      if (!values || values.length === 0) {
        if (state?.data?.availableSystemTypes.some((e) => e.systemType === "COMBI")) {
          values.push("COMBI");
        } else if (state?.data?.availableSystemTypes.some((e) => e.systemType === "SINGLES")) {
          values.push("SINGLES");
        }
      }

      return {
        ...state,
        data: isEqual(action.payload.data, state.data) ? state.data : action.payload.data,
        body: isEqual(action.payload.body, state.body) ? state.body : action.payload.body,
        nonCombineMarkets: isEqual(nonCombineMarkets, state.nonCombineMarkets)
          ? state.nonCombineMarkets
          : nonCombineMarkets,
        betBuilderMatches: isEqual(state.betBuilderMatches, sortedUpdatedBetBuilderMatches)
          ? state.betBuilderMatches
          : sortedUpdatedBetBuilderMatches,
        selectedSystemTypes: isEqual(values, state.selectedSystemTypes) ? state.selectedSystemTypes : values,
        errors: isEqual(errors, state.errors) ? state.errors : errors,
      };
    }

    case CALCULATE_BETSLIP_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case "BET_BUILDER_CHANGE": {
      const updatedBetBuilderMatches = [...state.betBuilderMatches];
      const index = updatedBetBuilderMatches.findIndex((e) => e.matchId === action.payload.matchId);
      if (index === -1) {
        updatedBetBuilderMatches.push(action.payload);
        // return state;
      } else {
        updatedBetBuilderMatches[index].active = action.payload.active;
      }
      return {
        ...state,
        betBuilderMatches: updatedBetBuilderMatches,
      };
    }

    case "SHOW_POPUP": {
      return {
        ...state,
        showPopup: {
          ...action.payload.showPopup,
          isShowing: !state.showPopup.isShowing,
        },
      };
    }

    case "BANKER_CHANGE": {
      const updatedBankers = [...state.bankers];
      const index = updatedBankers.findIndex((e) => e.matchId === action.payload.matchId);

      if (index === -1) {
        updatedBankers.push(action.payload);
      } else {
        updatedBankers.splice(index, 1);
      }
      return {
        ...state,
        bankers: updatedBankers,
      };
    }

    case "SELECTED_OPTIONS_CHANGE": {
      const values = [...action.payload];
      if (!values || values.length === 0) {
        if (state?.data?.availableSystemTypes?.some((e) => e.systemType === "COMBI")) {
          values.push("COMBI");
        } else if (state?.data?.availableSystemTypes?.some((e) => e.systemType === "SINGLES")) {
          values.push("SINGLES");
        }
      }
      if (isEqual(values, state.selectedSystemTypes)) {
        return state;
      }
      return {
        ...state,
        selectedSystemTypes: values,
      };
    }

    default:
      return state;
  }
};
