import { isEqual } from "lodash";
import { sortAvailableOdds } from "../../components/utility/AvailableOddsMapSorting";
import {
  LIVEMATCHES_FAILURE,
  LIVEMATCHES_REMOVE,
  LIVEMATCHES_SUCCESS,
  LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES,
  LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES_SCORE,
  LIVEMATCHES_WEBSOCKET_UPDATE_ODDS_1,
} from "../actions/actiontypes";

const initialState = {
  isAuthenticated: false,
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  matches: [],
  availableSports: null,
  tournamentMatchMap: null,
  availableTournamentsMapBySport: null,
  oddCountsByMatchMap: null,
  dropDownCount: 5,
  availableDetailtOddsMap: null,
  availableOddsMap: null,
  scores: null,
  redCard: {},
  odds: null,
  error: null,
  selectedOdds: [],
  matchesTimer: null,
};

export const LiveMatchReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIVEMATCHES_SUCCESS:
      const sortedAvailableOddsMap = sortAvailableOdds(action.payload.data.availableOddsMap);

      const initialScore = action.payload.data.matches.reduce((acc, match) => {
        const scoreList = match.scores_map.find(([key]) => key === "ft")[1].scoreList;
        acc[match.id] = {
          homeTotalScore: scoreList[0],
          awayTotalScore: scoreList[1],
          periods: match.scores_map
            .filter(([key]) => key.startsWith("p"))
            .map(([_, value]) => ({
              homeScore: value.scoreList[0],
              awayScore: value.scoreList[1],
            })),
        };

        return acc;
      }, {});
      const matchesTimers = action.payload.data.matches.reduce((acc, obj) => {
        const asd = JSON.parse(obj.timer);
        const time = asd.length !== 0 ? Math.floor(asd[3] / 60) + "′" : obj.period_name;
        acc[obj.id] = time;
        return acc;
      }, {});

      return {
        ...state,
        isAuthenticated: true,
        totalItems: action.payload.data.totalItems,
        totalPages: action.payload.data.totalPages,
        currentPage: action.payload.data.currentPage,
        dropDownCount: action.payload.data.dropDownCount,
        matches: action.payload.data.matches,
        availableSports: action.payload.data.availableSports,
        tournamentMatchMap: action.payload.data.tournamentMatchMap,
        availableTournamentsMapBySport: action.payload.data.availableTournamentsMapBySport,
        oddCountsByMatchMap: action.payload.data.oddCountsByMatchMap,
        availableDetailtOddsMap: action.payload.data.availableDetailtOddsMap,
        availableOddsMap: sortedAvailableOddsMap,
        odds: action.payload.data.odds,
        oddCount: action.payload.data.odds.length,
        scores: initialScore,
        matchesTimer: matchesTimers,
        error: null,
      };

    case "LIVEMATCHES_SUCCESS_ADD": {
      const sortedAvailableOddsMap = sortAvailableOdds(action.payload.data.availableOddsMap);
      const initialScore = action.payload.data.matches.reduce((acc, match) => {
        const scoreList = match.scores_map.find(([key]) => key === "ft")[1].scoreList;
        acc[match.id] = {
          homeTotalScore: scoreList[0],
          awayTotalScore: scoreList[1],
          periods: match.scores_map
            .filter(([key]) => key.startsWith("p"))
            .map(([_, value]) => ({
              homeScore: value.scoreList[0],
              awayScore: value.scoreList[1],
            })),
        };

        return acc;
      }, {});

      const mergedScores = {
        ...state.scores,
        ...initialScore,
      };
      const matchesTimers = action.payload.data.matches.reduce((acc, obj) => {
        const asd = JSON.parse(obj.timer);
        const time = asd.length !== 0 ? Math.floor(asd[3] / 60) + "′" : obj.period_name;
        acc[obj.id] = time;
        return acc;
      }, {});

      const mergedMatchesTimers = {
        ...state.matchesTimer,
        ...matchesTimers,
      };

      return {
        ...state,
        isAuthenticated: true,
        totalItems: action.payload.data.totalItems,
        totalPages: action.payload.data.totalPages,
        currentPage: action.payload.data.currentPage,
        dropDownCount: action.payload.data.dropDownCount,
        matches: [...state.matches, ...action.payload.data.matches],
        availableSports: [...state.availableSports, ...action.payload.data.availableSports],
        tournamentMatchMap: {
          ...state.tournamentMatchMap,
          ...action.payload.data.tournamentMatchMap,
        },
        availableTournamentsMapBySport: {
          ...state.availableTournamentsMapBySport,
          ...action.payload.data.availableTournamentsMapBySport,
        },
        oddCountsByMatchMap: {
          ...state.oddCountsByMatchMap,
          ...action.payload.data.oddCountsByMatchMap,
        },
        availableDetailtOddsMap: {
          ...state.availableDetailtOddsMap,
          ...action.payload.data.availableDetailtOddsMap,
        },
        availableOddsMap: sortedAvailableOddsMap,
        odds: [...state.odds, ...action.payload.data.odds],
        oddCount: action.payload.data.odds.length,
        scores: mergedScores,
        matchesTimer: mergedMatchesTimers,
        error: null,
      };
    }

    case LIVEMATCHES_REMOVE: {
      const updatedMatches = state.matches.filter((match) => match.id !== action.payload);
      return {
        ...state,
        matches: updatedMatches,
      };
    }
    case LIVEMATCHES_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        error: action.payload,
      };
    case LIVEMATCHES_WEBSOCKET_UPDATE_ODDS_1: {
      if (!action.payload.data || action.payload.data.length === 0) {
        return {
          ...state,
          isAuthenticated: false,
          error: "No odds data found",
        };
      }
      if (!state.matches.some((e) => action.payload.matchId === e.id)) {
        return state;
      }
      const updatedOdds = { ...state.odds };
      action.payload.data.forEach((webOdd) => {
        const id = webOdd.match_id + "_" + webOdd.market_id;
        if (!updatedOdds[id]) {
          return;
        }

        const existingOddIndex = updatedOdds[id].findIndex((odd) => odd.name === webOdd.name);
        if (existingOddIndex !== -1 && updatedOdds[id][existingOddIndex].value !== webOdd.value) {
          updatedOdds[id][existingOddIndex].value = webOdd.value;
          updatedOdds[id][existingOddIndex].outcome_id = webOdd.outcome_id;
          updatedOdds[id][existingOddIndex].line = webOdd.line;
          updatedOdds[id][existingOddIndex].status = webOdd.status;
          updatedOdds[id][existingOddIndex].change = webOdd.change;
          updatedOdds[id][existingOddIndex].score = webOdd.score;
          updatedOdds[id][existingOddIndex].line_score = webOdd.line_score;
          updatedOdds[id][existingOddIndex].main_line = webOdd.main_line;
        } else {
          // updatedOdds[id].push(webOdd);
        }
      });
      return {
        ...state,
        odds: updatedOdds,
      };
    }
    case "LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES_OLD": {
      if (!action.payload.data) {
        return {
          ...state,
          isAuthenticated: false,
          error: "No matches data found",
        };
      }
      const match_id = action.payload.data.id;
      if (!state.matches.some((e) => e.id === match_id)) {
        return state;
      }

      const updatedMatches = [...state.matches];
      const updatedTimer = { ...state.matchesTimer };
      const webMatch = action.payload.data;

      const existingMatchIndex = updatedMatches.findIndex((e) => e.id === webMatch.id);
      let isChanged = false;
      if (existingMatchIndex !== -1) {
        if (!isEqual(updatedMatches[existingMatchIndex].status_id, webMatch.status_id)) {
          updatedMatches[existingMatchIndex].status_id = webMatch.status_id;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].match_time, webMatch.match_time)) {
          updatedMatches[existingMatchIndex].match_time = webMatch.match_time;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].neutral, webMatch.neutral)) {
          updatedMatches[existingMatchIndex].neutral = webMatch.neutral;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].period, webMatch.period)) {
          updatedMatches[existingMatchIndex].period = webMatch.period;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].m_type, webMatch.m_type)) {
          updatedMatches[existingMatchIndex].m_type = webMatch.m_type;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].hide, webMatch.hide)) {
          updatedMatches[existingMatchIndex].hide = webMatch.hide;
          isChanged = true;
        }
      }
      return {
        ...state,
        matches: isChanged ? updatedMatches : state.matches,
        matchesTimer: isEqual(updatedTimer[match_id], webMatch.timer)
          ? state.matchesTimer
          : {
              ...state.matchesTimer,
              [match_id]: webMatch.timer,
            },
      };
    }
    case LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES: {
      if (!action.payload.data) {
        return {
          ...state,
          isAuthenticated: false,
          error: "No matches data found",
        };
      }
      const match_id = action.payload.data.id;
      if (!state.matches.some((e) => e.id === match_id)) {
        return state;
      }

      const updatedMatches = [...state.matches];
      const updatedTimer = { ...state.matchesTimer };
      const webMatch = action.payload.data;

      const existingMatchIndex = updatedMatches.findIndex((e) => e.id === webMatch.id);
      let isChanged = false;
      if (existingMatchIndex !== -1) {
        if (!isEqual(updatedMatches[existingMatchIndex].status_id, webMatch.status_id)) {
          updatedMatches[existingMatchIndex].status_id = webMatch.status_id;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].match_time, webMatch.match_time)) {
          updatedMatches[existingMatchIndex].match_time = webMatch.match_time;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].neutral, webMatch.neutral)) {
          updatedMatches[existingMatchIndex].neutral = webMatch.neutral;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].period, webMatch.period)) {
          updatedMatches[existingMatchIndex].period = webMatch.period;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].m_type, webMatch.m_type)) {
          updatedMatches[existingMatchIndex].m_type = webMatch.m_type;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].hide, webMatch.hide)) {
          updatedMatches[existingMatchIndex].hide = webMatch.hide;
          isChanged = true;
        }
      }
      let time = "";
      if (webMatch.timer) {
        try {
          const asd = JSON.parse(webMatch.timer);
          time = asd.length !== 0 ? Math.floor(asd[3] / 60) + "′" : webMatch.period_name;
        } catch (error) {
          console.log(error);
        }
      }

      return {
        ...state,
        matches: isChanged ? updatedMatches : state.matches,
        matchesTimer: isEqual(updatedTimer[match_id], time)
          ? state.matchesTimer
          : {
              ...state.matchesTimer,
              [match_id]: time,
            },
      };
    }
    case LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES_SCORE:
      if (!state.matches.some((e) => e.id === action.payload.matchId)) {
        return state;
      }
      let newState = { ...state };
      let changed = false;
      //SCORE
      const newScores = {
        [action.payload.matchId]: {
          homeTotalScore: Number(action.payload.data.score.resultsList[0]),
          awayTotalScore: Number(action.payload.data.score.resultsList[1]),
          periods: action.payload.data.periodsList.map((e) => ({
            homeScore: Number(e.resultsList[0]),
            awayScore: Number(e.resultsList[1]),
          })),
        },
      };

      if (
        state.scores[action.payload.matchId] &&
        !isEqual(newScores[action.payload.matchId], state.scores[action.payload.matchId])
      ) {
        changed = true;
        newState = {
          ...newState,
          scores: {
            ...state.scores,
            [action.payload.matchId]: newScores[action.payload.matchId],
          },
        };
      }

      //REDCARD

      const [asd] = action.payload.data.statsList.filter((e) => e.type === 2);
      if (asd) {
        if (asd.incidentsList && asd.incidentsList.length > 0) {
          const newRedCards = {
            ...state.redCard,
            [action.payload.matchId]: {
              home: asd.incidentsList.filter((e) => e.belong === 1).length,
              away: asd.incidentsList.filter((e) => e.belong === 2).length,
            },
          };
          if (!isEqual(newRedCards, state.redCard)) {
            changed = true;
            newState = {
              ...newState,
              redCard: newRedCards,
            };
          }
        }
      }
      if (changed) return newState;
      return state;
    case "SELECTED_VALUES": {
      return {
        ...state,
        selectedOdds: isEqual(state.selectedOdds, action.payload) ? state.selectedOdds : action.payload,
      };
    }
    default:
      return state;
  }
};
