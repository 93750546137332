import { useDispatch, useSelector } from "react-redux";
import { OddButtons } from "./CustomOddButtons";
import React, { memo, useEffect, useState } from "react";
import { goal, shirt1, shirt2 } from "../../assets/images/index";
import { PeriodChecker } from "../utility/PeriodChecker";
import { removeMatches } from "../../store/actions/live_matches";
import { OddsNameGroup } from "./OddsNameGroup";
import ComboboxGroup from "./ComboboxGroup";
import { TbRectangleVerticalFilled } from "react-icons/tb";

const Minutes = memo(({ status, sport_id, matchId, scores, period }) => {
  const time = useSelector((state) => state?.livematches?.matchesTimer[matchId]);
  const [totalScore, setTotalScore] = useState(0);
  const [totalScoreChange, setTotalScoreChange] = useState(false);
  useEffect(() => {
    const score = scores[matchId]?.homeScore + scores[matchId]?.awayScore;
    if (totalScore !== (isNaN(score) || score === undefined ? 0 : score) && sport_id === 1) {
      setTotalScore(score);
      setTotalScoreChange(true);
      const timeoutId = setTimeout(() => {
        setTotalScoreChange(false);
      }, 10000);
      return () => clearTimeout(timeoutId);
    }
  }, [scores]);

  if (totalScoreChange) {
    return (
      <div className="col-span-2 grid grid-cols-1 text-xs text-gray-500 truncate">
        <img src={goal} alt="goal"></img>
      </div>
    );
  }

  return (
    <div className="col-span-1 grid grid-cols-1 text-xs text-gray-500 truncate place-content-center">
      <PeriodChecker match_id={matchId} period_id={period} sport_id={sport_id} status={status} time={time} />
    </div>
  );
});

const MatchItem = memo(({ item, selectedOddsIds, handleDetail }) => {
  const dispatch = useDispatch();

  const oddCountsByMatchMap = useSelector((state) => state?.livematches?.oddCountsByMatchMap);
  const redCards = useSelector((state) => state?.livematches?.redCard[item.id]);

  const hndleDetail = () => {
    if (oddCountsByMatchMap[item.id] && oddCountsByMatchMap[item.id] !== 0)
      handleDetail({
        match: item,
        // time: time,
      });
  };
  if (item.status > 3) {
    dispatch(removeMatches(item.id));
  }
  const scores = useSelector((state) => state?.livematches?.scores);
  return (
    <div
      key={item.id}
      className={
        "col-span-12 h-[85px] md:h-14 pl-2 grid grid-cols-12 lg:grid-cols-12 cursor-pointer bg-white hover:bg-gray-100 items-center px-4 md:px-6 border-b border-gray-200"
      }
    >
      <div onClick={hndleDetail} className={"col-span-11 md:col-span-5  grid grid-cols-11"}>
        <Minutes
          period={item.period}
          status={item.status_id}
          sport_id={item.sport_id}
          scores={scores}
          matchId={item.id}
        />

        <div className="flex justify-between col-span-10 ml-[6%] md:ml-0">
          <div className="flex flex-col place-content-center">
            {/* Home Team */}
            <div className="grid grid-cols-[auto,1fr,auto] items-center gap-2 truncate">
              <img
                className="text-center item-center"
                src={item.home_team_logo ? item.home_team_logo : shirt1}
                alt=""
                width={15}
                height={15}
              />
              <span className="text-sm text-gray-900">
                {item.home_team_name.length > 10 ? item.home_team_name.substring(0, 15) + "..." : item.home_team_name}
              </span>
              <span className="flex items-center gap-1">
                {Array.from({ length: redCards?.home }).map((_, index) => (
                  <TbRectangleVerticalFilled key={index} className="text-red-700 w-3" />
                ))}
              </span>
            </div>

            {/* Away Team */}
            <div className="grid grid-cols-[auto,1fr,auto] items-center gap-2 truncate">
              <img
                className="text-center item-center"
                src={item.away_team_logo ? item.away_team_logo : shirt2}
                alt=""
                width={15}
                height={15}
              />
              <span className="text-sm text-gray-900">
                {item.away_team_name.length > 10 ? item.away_team_name.substring(0, 15) + "..." : item.away_team_name}
              </span>
              <span className="flex items-center gap-1">
                {Array.from({ length: redCards?.away }).map((_, index) => (
                  <TbRectangleVerticalFilled key={index} className="text-red-700 w-3" />
                ))}
              </span>
            </div>
          </div>

          <div className="flex justify-evenly mr-1 md:mr-6">
            <div className="flex w-full flex-col justify-evenly">
              <ScoreMap scores={scores} matchId={item.id} />
            </div>
          </div>
        </div>
      </div>

      <div className={`col-span-12 md:col-span-7 md:mb-0 mb-2 grid grid-cols-12`}>
        <div className={`col-span-11 grid  grid-cols-${selectedOddsIds.length}  gap-2`}>
          <OddButtons item={item} selectedOddsIds={selectedOddsIds} />
        </div>
        <div onClick={hndleDetail} className={"col-span-1 items-center flex justify-end"}>
          <div className="flex hidden md:block justify-end">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28" fill="none">
                <path
                  d="M11.5 20V13H7V20H11.5ZM11.5 20H16M11.5 20V4H16V20M16 20H20.5V10H16V20Z"
                  stroke="#212121"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <div xmlns="" id="divScriptsUsed" />
                <script
                  xmlns=""
                  id="globalVarsDetection"
                  src="chrome-extension://cmkdbmfndkfgebldhnkbfhlneefdaaip/js/wrs_env.js"
                />
              </svg>
            </span>
          </div>
          <Details id={item.id} />
        </div>
      </div>
    </div>
  );
});

const ScoreMap = ({ scores, matchId }) => {
  if (!scores) {
    return null;
  }

  const periodScores = scores[matchId]?.periods.slice(0, -1);

  return (
    <div className="flex gap-2 justify-end">
      {periodScores?.map((pScore, index) => (
        <div key={index} className="flex flex-col gap-2 justify-end">
          <span className={`text-sm font-semibold text-combisColor text-right`}>{pScore.homeScore}</span>
          <span className={`text-sm font-semibold text-combisColor text-right`}>{pScore.awayScore}</span>
        </div>
      ))}
      <div className="flex flex-col gap-2 justify-end">
        <span className={`text-sm font-semibold text-combisColor text-right animate-pulse`}>
          {scores[matchId]?.homeTotalScore}
        </span>
        <span className={`text-sm font-semibold text-combisColor text-right animate-pulse`}>
          {scores[matchId]?.awayTotalScore}
        </span>
      </div>
    </div>
  );
};

const Details = ({ id }) => {
  const oddCountsByMatchMap = useSelector((state) => state?.livematches?.oddCountsByMatchMap);

  return (
    <div className="flex justify-start w-6">
      <span className="text-xs">{oddCountsByMatchMap[id]}</span>
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 -960 960 960" width="14px" fill="#CCCCCC">
          <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
        </svg>
      </span>
    </div>
  );
};

export const Matches = ({
  betTab,
  selectedOddsIds,
  handleDetail,
  showDetail,
  dropDownCount,
  handleComboboxValuesChange,
}) => {
  const matches = useSelector((state) => state?.livematches?.matches);

  const selectionMode = useSelector((state) => state?.homereducer?.customSwitch);

  const filteredMatches = () => {
    const matchesList = matches?.filter(
      (item) =>
        (item.sport_id === betTab || betTab === 9999) &&
        !item.hide &&
        (item.status_id === 1 || item.status_id === 2 || item.status_id === 3 || item.status_id === 4)
    );
    const groupedMatches = matchesList.reduce((acc, item) => {
      const sportKey = item.sportName;

      if (!acc[sportKey]) {
        acc[sportKey] = [];
      }
      acc[sportKey].push(item);
      return acc;
    }, {});

    const sortedGroupedMatches = Object.keys(groupedMatches).reduce((acc, sportKey) => {
      let matches = groupedMatches[sportKey];

      if (selectionMode) {
        matches = matches.sort((a, b) => {
          const timerA = JSON.parse(a.timer) || [0];
          const timerB = JSON.parse(b.timer) || [0];
          return (timerA[3] !== 0 ? timerA[3] : a.period_name) - (timerB[3] !== 0 ? timerB[3] : b.period_name);
        });
      } else {
        matches = matches.reduce((acc, item) => {
          const sportKey = item.tournamentName;

          if (!acc[sportKey]) {
            acc[sportKey] = [];
          }
          acc[sportKey].push(item);
          return acc;
        }, {});
      }

      acc[sportKey] = matches;
      return acc;
    }, {});

    const sortedKeys = Object.keys(sortedGroupedMatches).sort((a, b) => {
      const firstSportId = sortedGroupedMatches[a][0]?.sport_id || 0;
      const secondSportId = sortedGroupedMatches[b][0]?.sport_id || 0;
      return firstSportId - secondSportId;
    });

    const finalSortedGroupedMatches = {};
    sortedKeys.forEach((key) => {
      finalSortedGroupedMatches[key] = sortedGroupedMatches[key];
    });
    return finalSortedGroupedMatches;
  };

  if (!matches) {
    return <div>No matches found.</div>;
  }

  // console.log(filteredMatches());

  return (
    <div className="grid grid-cols-12 border-b border-gray-300 bg-customs col-span-12">
      {Object.entries(filteredMatches()).map(([sportName, matchesx]) => {
        return (
          <div key={sportName} className="grid grid-cols-12 border-b col-span-12 ">
            <div className="flex col-span-5 bg-customs items-center justify-center">
              <img
                className="h-7 ml-5 w-7 hidden md:block"
                src={"http://38.180.122.61/images/sports/" + (sportName + "").toLowerCase().replace(" ", "-") + ".png"}
                alt={sportName}
              />
              <span className="w-dvw p-6 pl-2 font-bold bg-customs col-span-5 hidden md:block">{sportName}</span>
            </div>

            <div className="sticky flex hidden md:block justify-between content-center bg-customs top-5 gap-3 z-20 col-span-7">
              <div className="flex gap-4  place-content-center">
                <ComboboxGroup
                  dropDownCount={dropDownCount}
                  hasEmptyLabel={false}
                  onValuesChange={handleComboboxValuesChange}
                />
              </div>
            </div>

            {selectionMode && (
              <div className="grid grid-cols-12 border-b bg-customs col-span-12">
                <div className="col-span-5"></div>
                <div className="w-full col-span-7 hidden md:block">
                  <OddsNameGroup selectedOddsIds={selectedOddsIds} showDetail={showDetail} />
                </div>
                {matchesx.map((item) => (
                  <div key={item.id} className="grid grid-cols-12 border-b bg-customs col-span-12">
                    {/* {item.live_tracker_mapping?.tid && <span>BUURASI</span>} */}
                    <MatchItem
                      item={item}
                      selectedOddsIds={selectedOddsIds}
                      handleDetail={handleDetail}
                      showDetail={showDetail}
                    />
                  </div>
                ))}
              </div>
            )}
            {!selectionMode &&
              Object.entries(matchesx).map(([tournamentName, matches]) => (
                <div key={tournamentName} className="grid grid-cols-12 border-b bg-customs col-span-12">
                  <span className="flex items-center gap-2 text-xs w-[150%] md:w-full col-span-5 h-7 text-center pl-2">
                    {tournamentName}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="12px"
                      viewBox="0 -960 960 960"
                      width="12px"
                      fill="#676767"
                    >
                      <path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z" />
                    </svg>
                  </span>

                  <div className="w-full col-span-7 hidden md:block">
                    <OddsNameGroup selectedOddsIds={selectedOddsIds} showDetail={showDetail} />
                  </div>
                  {matches.map((item) => (
                    <MatchItem
                      key={item.id}
                      item={item}
                      timer={JSON.parse(item.timer)}
                      selectedOddsIds={selectedOddsIds}
                      handleDetail={handleDetail}
                      showDetail={showDetail}
                    />
                  ))}
                </div>
              ))}
          </div>
        );
      })}
    </div>
  );
};
