// src/layouts/MainLayout.js
import React from "react";
import Header from "../../components/Headers/Header";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import { RightSidebar } from "../../components/Sidebar/RightSidebar";
import { BottomAppbar } from "../../components/BottomAppbar";
import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";

const LiveBattingLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen font-Source-Code-Pro ">
      <Header/>
      
      <div className="flex flex-grow md:mt-7">
        <main className="flex-1 bg-custom overflow-x-auto md:pl-3 ">{children}</main>
        <RightSidebar />
      </div>
      <BottomAppbar />
    </div>
  );
};

export default LiveBattingLayout;
