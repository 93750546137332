import { logo } from "../../assets/images";
import { Link } from "react-router-dom";
import { Button } from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/authActions";
import { FaRegUserCircle } from 'react-icons/fa';
import { IoNotificationsOutline } from "react-icons/io5";
import { ImDrawer } from "react-icons/im";
import { setSelectedItem } from "../../store/actions/sidebarActions";

function UserPageHeader({ activeMenu }) {
    const navigate = useNavigate();
    const storedUserData = JSON.parse(localStorage.getItem('authToken'));
    const balance = storedUserData?.user?.user?.balance;

    const useAuth = () => {
        const authToken = localStorage.getItem("authToken");
        return authToken ? JSON.parse(authToken) : { isAuthenticated: false };
    };

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
        navigate("/login");
    };

    const { isAuthenticated } = useAuth();

    return (
        <>
            <header className="block md:hidden">
                <div className="flex justify-center items-center w-full bg-[#c8102e] h-[56px] px-4">
                    <h1 className="text-white text-sm font-normal">{activeMenu}</h1>
                </div>
            </header>

        </>
    );
}

export default UserPageHeader;
