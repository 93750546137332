import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detail7, matchCombi, nodata } from "../../assets/images";
import { clearOdds, removeOdd } from "../../store/actions/homeTypes";
import {
  CalculateBetSlipApi,
  changeBetBuilder,
  setSelectedOptions,
  toggleBankers,
} from "../../store/actions/calculateBetSlipAction";
import "./rightSidebar.css";
import { isEqual } from "lodash";
import { Button } from "../Button/Button";
import { hideSuccess, PlaceBetSlipApi } from "../../store/actions/placeBetSlipAction";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { TbRefresh } from "react-icons/tb";
import { BsShare } from "react-icons/bs";

const reqBodyInitial = {
  betBuilders: [],
  singleOutcomes: [],
  stake: 5,
  bankers: [],
  selectedSystemTypes: [],
};

export const BetSlip = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reqBody, setReqBody] = useState(reqBodyInitial);
  const reqBodyRef = useRef(reqBody);
  const [bets, setBets] = useState([]);

  useEffect(() => {
    const fetchBets = async () => {
      try {
        const storedUserData = JSON.parse(localStorage.getItem("authToken"));
        const token = storedUserData?.user?.token;
        if (!token) return;

        const response = await fetch("http://38.180.122.61:3000/api/user/get-coupons", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          return;
        }

        const data = await response.json();
        const sortedData = data.sort((a, b) => new Date(b.placed_at) - new Date(a.placed_at));
        setBets(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchBets();
  }, []);

  const filterBets = () => {
    let filteredBets = bets;

    filteredBets = bets.filter((bet) => bet.coupon_status === "OPEN");
    return filteredBets;
  };

  const response = useSelector((state) => state?.calculateBetSlip?.data);
  const betBuilderMatches = useSelector((state) => state?.calculateBetSlip?.betBuilderMatches);
  const selectedSystemTypes = useSelector((state) => state?.calculateBetSlip?.selectedSystemTypes);
  const bankers = useSelector((state) => state?.calculateBetSlip?.bankers);

  const oddsList = useSelector((state) => state?.homereducer?.odds);
  const uuid = useSelector((state) => state?.homereducer?.uuid);

  useEffect(() => {
    let interval;
    const process = () => {
      if (oddsList.length === 0) {
        clearInterval(interval);
      }
      const bbBody = [];
      const soBody = [];
      const bankerBody = [];
      oddsList.forEach((odd) => {
        if (betBuilderMatches.some((e) => e.matchId === odd.matchId && e.active)) {
          bbBody.push(odd.id);
        } else {
          soBody.push(odd.id);
        }
        if (bankers.some((e) => e.matchId === odd.matchId)) {
          bankerBody.push(odd.id);
        }
      });

      const updatedBody = {
        ...reqBodyRef.current,
        betBuilders: bbBody,
        singleOutcomes: soBody,
        selectedSystemTypes: selectedSystemTypes,
        bankers: bankerBody,
      };

      setReqBody(updatedBody);
    };
    process();
    if (oddsList.length > 0) {
      interval = setInterval(() => {
        process();
      }, 5000);
    } else {
      setReqBody(reqBodyInitial);
    }
    return () => clearInterval(interval);
  }, [oddsList, betBuilderMatches, bankers, selectedSystemTypes]);

  useEffect(() => {
    reqBodyRef.current = reqBody;
    if (!selectedSystemTypes || selectedSystemTypes.length === 0) {
      setSelectedOption([]);
    }
    dispatch(CalculateBetSlipApi(reqBody, uuid));
  }, [reqBody]);

  const setSelectedOption = (values) => {
    if (!selectedSystemTypes) {
      return;
    }
    if (!values || values.length === 0) {
      if (response?.availableSystemTypes.some((e) => e.systemType === "COMBI")) {
        values.push("COMBI");
      } else if (response?.availableSystemTypes.some((e) => e.systemType === "SINGLES")) {
        values.push("SINGLES");
      }
    }
    dispatch(setSelectedOptions(values));
  };

  const setStakeValue = (stake) => {
    if (isEqual(stake, reqBody.stake)) {
      return;
    }
    setReqBody((prevReqBody) => ({
      ...prevReqBody,
      stake: stake,
    }));
  };

  const [betTab, setBet] = useState("Bet Slip");
  const marketsLength = response?.markets?.length;
  const hasError = response?.markets.some((e) => e.isHaveError);

  if ((!response || !reqBody || !marketsLength || marketsLength === 0) && betTab === "Bet Slip") {
    return (
      <div className="w-23% shadow-topCombisShadow ">
        <BetSlipHeader length={marketsLength} betTab={betTab} setBet={setBet} length2={filterBets().length} />
        <div className="flex  justify-between shadow-custom  w-full rounded-b-custom bg-white">
          <Congratulations />
        </div>
      </div>
    );
  }
  return (
    <div className="w-23% shadow-topCombisShadow ">
      <BetSlipHeader length={marketsLength} betTab={betTab} setBet={setBet} length2={filterBets().length} />
      {betTab === "Bet Slip" ? (
        <div className="flex  justify-between shadow-custom  w-full rounded-b-custom bg-white">
          <div className="flex flex-col bg-livePageTop  w-full items-start justify-start border border-gray-300 rounded-sm shadow-sm">
            <BetSlipSubHeader
              betBuilderMatches={betBuilderMatches}
              selectedSystemTypes={response.selectedSystemTypes}
              availableSystemTypes={response.availableSystemTypes}
              setSelectedOptions={setSelectedOption}
            />
            <BetSlipBody
              markets={response?.markets}
              selectedOptions={response?.selectedSystemTypes}
              betBuilderMatches={betBuilderMatches}
            />
            <TotalBets length={response?.markets.length.toString()} totalWin={response.stake.totalWin} />
            <RateOfBets
              comboCount={response?.availableSystemTypes
                .filter((item) => item.selected)
                .reduce((sum, item) => sum + item.count, 0)}
              setReqStake={setStakeValue}
              stake={response?.stake.stake}
              totalWin={response?.stake.totalWin}
            />
            <div className="px-2 w-full py-2 ">
              <Button
                disabled={response?.stake.stake === 0 || hasError}
                className={`text-white rounded-md h-12 w-full bg-green-600 `}
                onClick={() => {
                  const token = JSON.parse(localStorage.getItem("authToken"))?.user?.token;
                  if (!token) {
                    toast.error("Please login!");
                    navigate("/login");
                  } else {
                    dispatch(PlaceBetSlipApi(uuid));
                    dispatch(hideSuccess(false));
                  }
                }}
              >
                Bet leaving
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col bg-livePageTop place-content-center items-center max-h-96 h-64 bg-white overflow-y-scroll w-full items-start  border border-gray-300 rounded-sm shadow-sm">
          <Betting filteredBets={filterBets()} />
        </div>
      )}
    </div>
  );
};

const RateOfBets = ({ comboCount, setReqStake, stake, totalWin }) => {
  const [stakeValue, setStakeValue] = useState(stake);
  const [hasError, setHasError] = useState(false);
  const previousStakeRef = useRef(stakeValue);

  const handleStakeChange = (e) => {
    const value = e.target.value;
    const previousStake = previousStakeRef.current;
    const isEmpty = value === "" || value === null || value === undefined;
    if (isEmpty && previousStake === 0) {
      previousStakeRef.current = "";
      setStakeValue("");
    } else if (!isEmpty && previousStake === 0) {
      const numericValue = parseFloat(value.replace(/[^0-9]/g, ""));
      if (!isNaN(numericValue)) {
        setStakeValue(numericValue);
        setReqStake(numericValue);
        previousStakeRef.current = numericValue;
        setHasError(false);
      } else {
        setStakeValue("");
        setReqStake("");
        previousStakeRef.current = "";
      }
    } else if (value === "" || value === null || value === undefined) {
      previousStakeRef.current = 0;
      setHasError(true);
      setStakeValue(0);
      setReqStake(0);
    } else {
      const numericValue = parseFloat(value.replace(/[^0-9]/g, ""));
      previousStakeRef.current = numericValue;
      setStakeValue(numericValue);
      setReqStake(numericValue);
      setHasError(false);
    }
  };

  const handleBlur = () => {
    if (stakeValue === "" || stakeValue === null || stakeValue === 0 || stakeValue === undefined) {
      setStakeValue(5);
      setReqStake(5);
    }
  };

  return (
    <div className="BetslipStake-styles-module-betslip-stake">
      <div className={`BetslipStake-styles-module-col`}>
        <div id="betslip-stake-per-way-input-group" className="BetslipStake-styles-module-input-group">
          <div className={`BetslipStakeInput-styles-module-betslip-stake-input ${hasError ? "animate-shake" : ""}`}>
            <span className={`${comboCount > 1 ? "" : "hidden"} text-gray-400 `}>
              {comboCount}
              <span className="text-xs">X</span>
            </span>
            {hasError && <RiErrorWarningFill className="fill-red-600" />}
            <span>€</span>

            <input
              id="betslip-stake-per-way-input"
              type="text"
              autoComplete="off"
              inputMode="numeric"
              name="stake-per-way"
              className={`BetslipStakeInput-styles-module-input`}
              value={stakeValue}
              onChange={handleStakeChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="BetslipStake-styles-module-info-text">
            <span>Bet Per Bet</span>
          </div>
        </div>
      </div>

      {comboCount > 1 && (
        <div className="BetslipStake-styles-module-col">
          <div id="betslip-stake-per-way-input-group" className="BetslipStake-styles-module-input-group">
            <div className="BetslipStakeInput-styles-module-betslip-stake-input">
              <span>&nbsp;€</span>
              <input
                id="betslip-stake-per-way-input"
                name="stake-per-way"
                className="BetslipStakeInput-styles-module-input"
                autoComplete="off"
                type="text"
                value={stakeValue * comboCount}
                onChange={handleStakeChange}
              />
            </div>
            <div className="BetslipStake-styles-module-info-text">
              <span>Total Bet</span>
            </div>
          </div>
        </div>
      )}

      <div id="betslip-possible_win-info-group" className="PossibleWinnings-styles-module-possible-win">
        <div className="PossibleWinnings-styles-module-win-number">
          <span className="text-green-700">{totalWin} €</span>
        </div>
        <div className="PossibleWinnings-styles-module-win-text">
          <span>Olası kar</span>
        </div>
      </div>
    </div>
  );
};

const TotalBets = ({ length, totalWin }) => (
  <>
    <div className="flex bg-white w-full px-2 py-3 border-b border-gray-200">
      <div className="flex bg-white text-sm w-full justify-between">
        <span>Number of bets</span>
        <span>{length}</span>
      </div>
    </div>
    {totalWin > 1000000 && (
      <div className="">
        <div className="BetslipErrors-styles-module-error">
          <div className="BetslipErrors-styles-module-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path
                d="M12.297 21c-5.094 0-9.223-4.029-9.223-9s4.129-9 9.223-9c5.094 0 9.223 4.029 9.223 9s-4.129 9-9.223 9z"
                fill="#C23B21"
              />
              <path d="M12.297 12.5v-5" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <mask id="a" fill="#fff">
                <ellipse cx="12.297" cy="16" rx="1.025" ry="1" />
              </mask>
              <ellipse cx="12.297" cy="16" rx="1.025" ry="1" fill="#fff" />
              <path
                d="M11.822 16c0-.31.248-.5.475-.5v3c1.36 0 2.525-1.085 2.525-2.5h-3zm.475-.5c.228 0 .475.19.475.5h-3c0 1.415 1.165 2.5 2.525 2.5v-3zm.475.5c0 .31-.247.5-.475.5v-3c-1.36 0-2.525 1.085-2.525 2.5h3zm-.475.5a.488.488 0 0 1-.475-.5h3c0-1.415-1.165-2.5-2.525-2.5v3z"
                fill="#fff"
                mask="url(#a)"
              />
            </svg>
          </div>
          <div className="BetslipErrors-styles-module text-xs leading-relaxed text-red-700">
            Leider überschreitet der mögliche Gewinn das Auszahlungslimit pro Wettschein von 100.000,00&nbsp;€.
          </div>
        </div>
      </div>
    )}
  </>
);

const BetSlipSubHeader = ({ betBuilderMatches, selectedSystemTypes, availableSystemTypes, setSelectedOptions }) => {
  const dispatch = useDispatch();
  const getLabel = () => {
    const maxLength = 20;
    const combinedText = selectedSystemTypes.join(",");
    return combinedText.length > maxLength ? `${combinedText.substring(0, maxLength)}...` : combinedText;
  };
  return (
    <div className="flex justify-between w-full items-center mt-2 px-2 pb-1">
      <div>
        <div className="relative inline-block text-left w-36">
          <input type="checkbox" id="dropdown-toggle" className="hidden peer" />
          <label
            htmlFor="dropdown-toggle"
            className="inline-flex w-full justify-between text-xs items-center bg-white px-3 py-2 font-thin text-black shadow-none border  rounded-md cursor-pointer focus:outline-none pr-6"
          >
            {getLabel()}
            <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.72-3.71a.75.75 0 111.06 1.06L10.56 12a.75.75 0 01-1.06 0L5.23 8.28a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </label>
          <div className="absolute z-10 mt-2  bg-white w-[288px] border border-gray-300 rounded-md shadow-lg origin-top-right peer-checked:block hidden max-h-60 overflow-auto">
            <div className="py-1 space-y-2">
              <BetBuilderSelectCombo betBuilderMatches={betBuilderMatches} />

              {availableSystemTypes.map((option, index) => (
                <label
                  key={index}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    className="form-checkbox mr-2"
                    value={option.systemType}
                    checked={selectedSystemTypes.includes(option.systemType)}
                    onChange={(e) => {
                      let updatedSelected = [...selectedSystemTypes];
                      if (e.target.checked) {
                        updatedSelected.push(option.systemType);
                      } else {
                        updatedSelected = updatedSelected.filter((item) => item !== option.systemType);
                      }
                      setSelectedOptions(updatedSelected);
                    }}
                  />
                  <span>{option.systemType}</span>
                  <span className="text-gray-400">{" (" + option.count + " Wetten)"}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="cursor-pointer"
        onClick={() => {
          dispatch(clearOdds());
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#6b7280">
          <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
        </svg>
      </div>
    </div>
  );
};

const BetSlipBody = ({ markets, selectedOptions, betBuilderMatches }) => {
  return markets?.map((bet, index) => {
    if (betBuilderMatches?.some((e) => e.matchId === bet.matchId && e.active)) {
      return <BetBuilderBody key={index} bet={bet} />;
    }
    return <SlipBody key={index} bet={bet} selectedOptions={selectedOptions} />;
  });
};

const SlipBody = ({ selectedOptions, bet, live }) => {
  const dispatch = useDispatch();
  const market = bet.market[0];

  const getLive = () => {
    // live.scoreMap;
    return "33` | 1:0 (0:0)";
  };

  return (
    <div className="w-full">
      <div className="flex bg-white w-full px-2 py-2 border-b border-gray-200">
        <div className="flex items-center w-full">
          <BankerCheck selectedOptions={selectedOptions} bet={bet} />
          <div className="flex justify-between w-full">
            <div className="grid grid-cols-1 text-sm">
              <span>
                {market?.name} {market?.line ? `(${market?.line})` : ""}: {market?.outcomeName}
                <br />
                <span className="text-xs text-gray-500">{bet.name}</span>
              </span>
              {live && <span>{getLive()}</span>}
              <span>
                {bet.isHaveError &&
                  bet.error.map((error, errorIndex) => {
                    return (
                      <div key={errorIndex} className="flex">
                        <span className="text-red-600 text-xs">{error}</span>
                      </div>
                    );
                  })}
              </span>
            </div>

            <div className="flex gap-1 items-center">
              <span className="text-popupText">{bet.odd}</span>
              <span
                className="cursor-pointer"
                onClick={() => {
                  dispatch(
                    removeOdd({
                      id: market?.singleOutcome,
                    })
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20px"
                  viewBox="0 -960 960 960"
                  width="20px"
                  fill="#D9D9D9"
                >
                  <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BankerCheck = ({ selectedOptions, bet }) => {
  const dispatch = useDispatch();
  return (
    <div className=" pr-2">
      <label
        className={`border-2 border-opacity-40 border-black text-xs w-4 text-center ${
          selectedOptions?.some((e) => e.startsWith("Correct")) ? "block" : "hidden"
        } ${bet.banker ? "bg-blue-900 text-white" : "bg-white text-black"}`}
      >
        <input
          type="checkbox"
          checked={bet.banker}
          onChange={() => dispatch(toggleBankers(bet.matchId))}
          className="hidden bg-blue-900"
        />
        B
      </label>
    </div>
  );
};

const BetBuilderSelectCombo = ({ betBuilderMatches }) => {
  const dispatch = useDispatch();

  const oddsList = useSelector((state) => state?.homereducer?.odds);
  const toggleStatus = (matchId, name, isActive) => {
    dispatch(changeBetBuilder(matchId, name, isActive));
  };
  if (!betBuilderMatches) {
    return null;
  }

  return betBuilderMatches
    .filter((e) => oddsList.some((k) => k.matchId === e.matchId))
    .map((bbMatch, index) => (
      <div key={index} className="bg-gray-100 mx-2 pt-2 pb-2 gap-2 border-2 rounded-xl ">
        <div className="BetBuilderTogglePerEvent-styles-module-toggle-section-header">
          <img className="BetBuilderTogglePerEvent-styles-module-toggle-section-icon" src={matchCombi} alt={index} />
          <span className="BetBuilderTogglePerEvent-styles-module-toggle-section-title">
            <span className="text-xs">Umwandeln in MatchKombi+</span>
          </span>
        </div>
        <div className="BetBuilderTogglePerEvent-styles-module-toggle-section-content ">
          <span className="text-xs">{bbMatch.name}</span>
          <button
            onClick={() => toggleStatus(bbMatch.matchId, bbMatch.name, !bbMatch.active)}
            className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-300
      ${bbMatch.active ? "bg-blue-500" : "bg-gray-200"}
    `}
          >
            <span
              className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-300
        ${bbMatch.active ? "translate-x-5" : "translate-x-0"}
      `}
            ></span>
          </button>
        </div>
      </div>
    ));
};

const BetSlipHeader = ({ length, betTab, setBet, length2 }) => {
  return (
    <div className="flex items-center w-full h-16 bg-white shadow-custom rounded-t-md">
      <div
        className={`flex items-center cursor-pointer justify-center w-1/2 h-full  border-b-2 ${
          betTab === "Bet Slip" ? "border-b-2  border-red-700" : "border-b-2  border-gray-50"
        } text-center`}
        onClick={() => {
          setBet("Bet Slip");
        }}
      >
        <div className="flex flex-col">
          <span className="text-xl font-semibold text-gray-600">{length}</span>
          <span className="font-semibold text-xs">Bet Slip</span>
        </div>
      </div>
      <div
        className={`flex items-center cursor-pointer justify-center w-1/2 h-full text-center ${
          betTab === "Open Bets" ? "border-b-2  border-red-700" : "border-b-2  border-gray-50"
        } `}
        onClick={() => {
          setBet("Open Bets");
        }}
      >
        <div className="flex flex-col">
          <span className="text-xl font-semibold text-gray-600">{length2}</span>
          <span className="font-semibold text-xs"> Open Bets</span>
        </div>
      </div>
    </div>
  );
};

const Betting = ({ setLength, filteredBets }) => {
  const handleCashout = async (bet_slip_id) => {
    try {
      const storedUserData = JSON.parse(localStorage.getItem("authToken"));
      const token = storedUserData?.user?.token;
      if (!token) return;

      const response = await fetch("http://38.180.122.61:3000/api/combi/cashout-coupon?couponId=" + bet_slip_id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        toast.error("Cashout Failed");
        return;
      }
      toast.success("Winnigs Processed Succesfuly");
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  return (
    <div>
      {filteredBets.length > 0 ? (
        filteredBets.map((bet) => <BetsMy key={bet.id} bet={bet} handleCashout={handleCashout} />)
      ) : (
        <p>No bets found for this category.</p>
      )}
    </div>
  );
};

const BetsMy = ({ bet, activeTab, handleCashout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const formatDate = (date) => {
    const parsedDate = new Date(date);
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const hours = String(parsedDate.getHours()).padStart(2, "0");
    const minutes = String(parsedDate.getMinutes()).padStart(2, "0");
    return `${day}.${month}. ${hours}:${minutes}`;
  };
  return (
    <div className="border rounded-lg shadow-md my-2.5 bg-white" onClick={() => setIsOpen(!isOpen)}>
      <div className="relative flex flex-col bg-white p-4 space-y-2 cursor-pointer">
        <div className="flex items-center">
          <div className="flex-grow"></div>

          <span className="text-gray-400">
            <span
              className={`font-semibold mr-2 text-sm ${
                bet.coupon_status === "WON"
                  ? "text-green-500"
                  : bet.coupon_status === "LOST"
                  ? "text-red-500"
                  : "text-yellow-500"
              }`}
            >
              {bet.coupon_status}
            </span>
            {isOpen ? "▲" : "▼"}
          </span>
        </div>

        <span className="absolute top-4 left-4 text-xs text-black">{bet.system_type}</span>

        <div className="flex items-center space-x-12">
          <span className="text-xs text-gray-500">{formatDate(bet.placed_at)}</span>

          {activeTab !== "Settled" && bet.can_cashout && (
            <div className="flex space-x-3">
              <div className="border border-[#4d92aa] text-[#4d92aa] h-10 flex items-center justify-center px-3 py-1 rounded font-semibold">
                <span className="text-xs">Auto Cashout</span>
              </div>
              <button
                onClick={() => handleCashout(bet.id)}
                className="bg-[#4d92aa] text-white h-10 flex text-xs items-center justify-center px-3 py-1 rounded font-semibold"
              >
                Cashout for €{parseFloat(bet.cashout_val).toFixed(2)}
              </button>
            </div>
          )}
        </div>
        {isOpen &&
          Array.isArray(bet.coupon_lines) &&
          bet.coupon_lines.map((line, index) => (
            <div key={index} className="p-2  border-t border-gray-200 bg-customs">
              <div className="flex flex-col text-sm">
                <span className="text-customText ">
                  {line.market_name}
                  {line.line ? ` (${line.line})` : ""} : <span className="font-bold">{line.outcome_name}</span>
                </span>
                <span>
                  {line.match_name}
                  <span
                    className={`float-right font-bold ${
                      line.line_status === "WON"
                        ? "text-green-500"
                        : line.line_status === "LOST"
                        ? "text-red-500"
                        : "text-black"
                    }`}
                  >
                    {line.odd_value}
                  </span>
                </span>
                <span className="text-xs text-gray-500">
                  {new Date(line.match_time * 1000).toLocaleString()}{" "}
                  {activeTab === "Settled" && (
                    <span>
                      {line.match && line.match.scores_map && (
                        <span className="ml-2 text-gray-400">
                          {line.match.scores_map.map((item, index) => {
                            const [key, value] = item;
                            return (
                              <span key={index} className="ml-1">
                                {key}: [{value.scoreList[0] + "-" + value.scoreList[1]}]
                              </span>
                            );
                          })}
                        </span>
                      )}
                    </span>
                  )}
                </span>
              </div>

              {index === bet.coupon_lines.length - 1 && (
                <div className="flex flex-col mt-2">
                  <div className="flex justify-between md:hidden">
                    <div className="text-sm font-semibold text-gray-500">
                      <span className="text-xs text-gray-400">Stake</span> <br /> {bet.stake_amount}
                    </div>
                    <div className="text-sm font-semibold text-gray-500">
                      <span className="text-xs text-gray-400">Possible gain</span> <br /> {bet.possible_win}
                    </div>
                  </div>
                  <div className="border-t  border-gray-200">
                    <p className="text-xs  mt-2 text-gray-500">Betslip ID: {bet.id}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

const BetBuilderBody = ({ bet }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex justify-between w-full">
      <div className="grid grid-cols-1 text-sm pl-2 w-full pb-5">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img src={detail7} alt="7" />
            <span className="pl-2">{bet.market.length + "er MatchKombi"}</span>
          </div>
          <div className="flex gap-1 items-center">
            <span className="text-popupText">{bet.odd}</span>
            <span
              className="cursor-pointer"
              onClick={() => {
                bet.market.forEach((e) => {
                  dispatch(
                    removeOdd({
                      id: e.betBuilderOutcome,
                    })
                  );
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                fill="#D9D9D9"
              >
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
              </svg>
            </span>
          </div>
        </div>

        <span>{bet.name}</span>
        {bet.market.map((e, indexBet) => (
          <div className="flex items-baseline" key={indexBet}>
            <div className="relative flex flex-col items-baseline mr-2 h-full ">
              <div className="w-2 h-2 bg-transparent border-2 border-gray-500  rounded-full mt-1" />
              {indexBet !== bet.market.length - 1 && (
                <div className="w-px bg-gray-500 self-center h-8 flex-grow my-0.5" />
              )}
            </div>
            <BetBuilderMatches market={e} />
          </div>
        ))}
        <span>
          {bet.isHaveError &&
            bet.error.map((error, errorIndex) => {
              return (
                <div key={errorIndex} className="flex">
                  <span className="text-red-600 text-xs">{error}</span>
                </div>
              );
            })}
        </span>
      </div>
    </div>
  );
};

const BetBuilderMatches = ({ market }) => {
  const dispatch = useDispatch();
  return (
    <span className="flex items-center justify-between w-full">
      <span className="pt-1">
        {market.name} {market.line ? `(${market.line})` : ""}: {market.outcomeName}
      </span>
      <div className="flex gap-1 items-center">
        <span
          className="cursor-pointer"
          onClick={() => {
            dispatch(
              removeOdd({
                id: market.betBuilderOutcome,
              })
            );
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#D9D9D9">
            <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
          </svg>
        </span>
      </div>
    </span>
  );
};

const Congratulations = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.placeBetReducer?.data?.data);
  const hide = useSelector((state) => state?.placeBetReducer?.hide);

  const toggleHide = (value) => {
    dispatch(hideSuccess(value));
  };

  if (hide) {
    return <NoOdds />;
  }
  return (
    <div className="flex flex-col gap-y-4 w-full max-w-sm mx-auto justify-center items-center">
      <div className="flex flex-col items-center bg-white  p-6  w-full">
        <div className="flex items-center justify-center w-12 h-12 bg-green-500 rounded-full">
          <svg
            className="w-8 h-8 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
        </div>
        <p className="mt-3 text-lg font-semibold">Bet placed. Good luck!</p>
        <button onClick={() => toggleHide(true)} className="text-blue-600  mt-1">
          Hide Confirmation
        </button>
      </div>
      <div className="w-full space-y-3 p-6 bg-customs mt-5  border pt-5">
        <div className="flex justify-between text-sm items-center">
          <span className="font-semibold flex items-center">
            Einzel <FaChevronRight className="text-gray-600 ml-1" />
          </span>
        </div>
        <div className="flex justify-between text-sm">
          <span className="font-semibold text-gray-800">Stake</span>
          <span>
            {data.couponCount} x €{data.stakeAmount}
          </span>
        </div>
        <div className="flex justify-between text-sm font-semibold">
          <span className="font-semibold text-gray-800">Total Price</span>
          <span>€{data.stakeAmount}</span>
        </div>
        <div className="flex justify-between text-sm text-green-600 font-semibold">
          <span className="font-semibold text-gray-800">Possible winnings</span>
          <span>€{data.possibleWin?.toFixed(2)}</span>
        </div>
      </div>
      <div className="w-full border-b">
        <button className="w-full text-sm text-gray-500 flex items-center justify-center pb-3">
          <TbRefresh className="text-xl mr-2 font-bold" />
          Reuse
        </button>
      </div>
      <div className="w-full">
        <button className="w-full text-sm font-semibold text-gray-500 flex items-center justify-center pb-3">
          <BsShare className="text-xl mr-2 font-bold" />
          Share
        </button>
      </div>
    </div>
  );
};

const NoOdds = () => {
  return (
    <div className="flex flex-col gap-y-3 w-full  h-48 justify-center items-center">
      <img src={nodata} alt={nodata} width={50} height={110} />

      <p className="text-sm">
        <span>
          Dein Wettschein ist noch leer.
          <br />
          Wähle jetzt deine Wetten aus.
        </span>
      </p>
    </div>
  );
};
