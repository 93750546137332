import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { RightSidebarMobile } from "./RightSidebarMobile";
import { useDispatch, useSelector } from "react-redux";
import {  close,
} from "../../assets/images";
export function BottomAppbar() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);

  // Toggle popup visibility
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const response = useSelector((state) => state?.calculateBetSlip?.data);

  const marketsLength = response?.markets?.length;
  const markets = response?.markets;
  var totalRate = 1;
  markets?.map((bet, index) => {
    totalRate = totalRate * bet.odd
  })

  // Close popup when clicking outside of it (works for both mobile and desktop)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };

    // Listen for both mouse and touch events
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="fixed  bottom-0 sm:hidden text-sm fontse\  z-51 left-0 right-0 bg-white text-gray-400  p-1 shadow-lg">
        {marketsLength > 0 && (
          <div className="flex h-8 w-full bg-cyan-800 fixed bottom-[50px] left-0 right-0  transform transition-transform translate-y-0 sm:hidden">
            <div className="flex text-white text-center justify-center items-center w-full">
              Total Rate {totalRate.toFixed(2)}
            </div>
          </div>
        )}
        <div className="flex justify-evenly">
          <Link className="active" to="/">
            <div className="text-center flex flex-col justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 25 25">
                <path
                  d="m6 8.4c-.3 0-.6.2-.6.5l-.8 3.1c0 .2.1.4.3.5h3.6l-1.1 4.8c-.5 2.3.1 5.4 4.2 5.4h5.2c.3 0 .5-.2.6-.5l.6-2.7c0-.2-.1-.4-.3-.5h-4.9c-1.1 0-1.2-.9-1-1.7l1.2-4.8h6.1c.3 0 .5-.2.6-.5l.7-3.2c0-.2-.1-.4-.4-.4zm3.8-3.8c0 1.4 1.1 2.5 2.6 2.5 1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5c-1.4-.1-2.5 1-2.6 2.4z"
                  fill="#9d9d9d"
                />
                <div xmlns="" id="divScriptsUsed" />
                <script
                  xmlns=""
                  id="globalVarsDetection"
                  src="chrome-extension://cmkdbmfndkfgebldhnkbfhlneefdaaip/js/wrs_env.js"
                />
              </svg>
              <span className="text-xs">Home</span>{" "}
            </div>
          </Link>
          <Link className="active" to="/mobilDynamic">
            <div className="text-center flex flex-col justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 25 25">
                <g fill="#9d9d9d">
                  <path d="m6.25 14.25a4.23 4.23 0 0 0 -2.36.75 4.22 4.22 0 0 0 -1.81 4.36 4.38 4.38 0 0 0 1.16 2.18 4.38 4.38 0 0 0 2.18 1.16 4.22 4.22 0 0 0 4.36-1.81 4.23 4.23 0 0 0 .72-2.36 4.34 4.34 0 0 0 -.32-1.63 4.57 4.57 0 0 0 -.92-1.38 4.57 4.57 0 0 0 -1.38-.92 4.34 4.34 0 0 0 -1.63-.35zm-2 2.35a1.36 1.36 0 0 0 .1.36 2.12 2.12 0 0 1 .14.75 1.42 1.42 0 0 1 -.33.81 2.9 2.9 0 0 0 -.46 1.05 2.7 2.7 0 0 1 .55-3zm.54 4.23a1.71 1.71 0 0 1 .21-1.76 2.39 2.39 0 0 0 .49-1.31 3 3 0 0 0 -.18-1.1c-.1-.32-.16-.51-.06-.72a2.73 2.73 0 0 1 2 0 1.75 1.75 0 0 0 -.85 1 2.43 2.43 0 0 0 .6 2.44c.46.63.69 1 .08 1.73a2.75 2.75 0 0 1 -.81.14 2.7 2.7 0 0 1 -1.46-.42zm3.61-.66a2.75 2.75 0 0 0 -.64-1.38c-.42-.57-.64-.92-.44-1.53a.77.77 0 0 1 .22-.34.85.85 0 0 1 .37-.18.67.67 0 0 1 .46.05 2.72 2.72 0 0 1 0 3.38z" />
                  <path d="m10.29 17.25a.39.39 0 0 0 .07.27v-.12z" />
                  <path d="m8.63 11.88 1.91-1.29 2.24 1 .32.92.05-.06a11.2 11.2 0 0 1 4.5-2.63l-1.51-1-.4-3.56.37-.49a7.59 7.59 0 0 1 2.81 4.75 10.53 10.53 0 0 1 1.48-.2 8.75 8.75 0 0 0 -17.4 1.18 8.7 8.7 0 0 0 1.15 4.32 4.29 4.29 0 0 1 1.47-.51 7.36 7.36 0 0 1 -1.09-3.62l1-.27 2.1 1.63.2 2.5a4.31 4.31 0 0 1 1.17.77l-.14-.32zm5.93-6.35.44 3.35-2 1.53-2.15-.92-.65-2.23 2.25-2.41zm-9.4 3.79-.61.22a7.71 7.71 0 0 1 1.13-3l.26.46zm1.76-2.9-.48-.82a7.24 7.24 0 0 1 5.26-2.33l.06.79-2.28 2.36z" />
                  <path d="m22.94 9.89-.06-.05-.05-.06a4.19 4.19 0 0 0 -2.35-.52 11.64 11.64 0 0 0 -7.82 3.18l-.05.06-.06.06c-3.35 3.51-3.55 8.83-2.55 10.2l.11.11a4.19 4.19 0 0 0 2.35.52 11.56 11.56 0 0 0 7.82-3.19v-.06l.06-.06c3.44-3.5 3.66-8.82 2.6-10.19zm-2.46.87a4.37 4.37 0 0 1 1.34.17 6.38 6.38 0 0 1 .09 2.68 32.68 32.68 0 0 0 -2.78-2.74 10.24 10.24 0 0 1 1.35-.11zm-8 11.13a4.26 4.26 0 0 1 -1.33-.17 6.09 6.09 0 0 1 -.07-2.72 32.39 32.39 0 0 0 2.83 2.79 9.55 9.55 0 0 1 -1.41.1zm6.84-2.83v.05a9.75 9.75 0 0 1 -4.16 2.35 29 29 0 0 1 -3.77-3.75 9.67 9.67 0 0 1 2.3-4.15l.05-.05a9.75 9.75 0 0 1 4.17-2.36 30.64 30.64 0 0 1 3.77 3.7 9.64 9.64 0 0 1 -2.34 4.21z" />
                  <path d="m16.22 12.64a1.39 1.39 0 0 0 .15.75 7.4 7.4 0 0 0 -.72.53 1.51 1.51 0 0 0 -.62-.2 1.48 1.48 0 0 0 .14.62l-.37.34-.31.36a1.62 1.62 0 0 0 -.72-.17 1.39 1.39 0 0 0 .28.7 7.57 7.57 0 0 0 -.52.74 1.4 1.4 0 0 0 -.81-.12 1.46 1.46 0 0 0 .46.77 6.54 6.54 0 0 0 -.49 1.27c.34-.31.65-.63 1-.95a1.5 1.5 0 0 0 .81.11 1.36 1.36 0 0 0 -.31-.61l.61-.63a1.53 1.53 0 0 0 .6.12 1.37 1.37 0 0 0 -.18-.56l.33-.33.3-.31a1.52 1.52 0 0 0 .65.22 1.31 1.31 0 0 0 -.18-.7l.58-.59a1.53 1.53 0 0 0 .68.29 1.36 1.36 0 0 0 -.18-.79l1-1a6.5 6.5 0 0 0 -1.4.5 1.52 1.52 0 0 0 -.78-.36z" />
                </g>
                <div xmlns="" id="divScriptsUsed" />
                <script
                  xmlns=""
                  id="globalVarsDetection"
                  src="chrome-extension://cmkdbmfndkfgebldhnkbfhlneefdaaip/js/wrs_env.js"
                />
              </svg>
              <span className="text-xs">Batting</span>{" "}
            </div>
          </Link>
          <Link className="active" to="/meinWetten">
            <div className="text-center flex flex-col justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 25 25" width="25">
                <g fill="#9d9d9d">
                  <path d="m4.5 6h16a.94.94 0 0 1 1 1v.5h-18v-.5a.94.94 0 0 1 1-1z" />
                  <path d="m6 3.5h13a.94.94 0 0 1 1 1v.5h-15v-.5a.94.94 0 0 1 1-1z" />
                  <path d="m22 8.5h-19a.94.94 0 0 0 -1 1v11a.94.94 0 0 0 1 1h19a.94.94 0 0 0 1-1v-11a.94.94 0 0 0 -1-1zm-5 6h-9v-1h9z" />
                </g>
                <div xmlns="" id="divScriptsUsed" />
                <script
                  xmlns=""
                  id="globalVarsDetection"
                  src="chrome-extension://cmkdbmfndkfgebldhnkbfhlneefdaaip/js/wrs_env.js"
                />
              </svg>
              <span className="text-xs">My Bets</span>{" "}
            </div>

            {/* </Link>  <Link className="active" to="/">
         
         <div className="text-center flex flex-col justify-center items-center">
         <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#9ca3af"><path d="M160-120v-200q0-33 23.5-56.5T240-400h480q33 0 56.5 23.5T800-320v200H160Zm200-320q-83 0-141.5-58.5T160-640q0-83 58.5-141.5T360-840h240q83 0 141.5 58.5T800-640q0 83-58.5 141.5T600-440H360ZM240-200h480v-120H240v120Zm120-320h240q50 0 85-35t35-85q0-50-35-85t-85-35H360q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0-80q17 0 28.5-11.5T400-640q0-17-11.5-28.5T360-680q-17 0-28.5 11.5T320-640q0 17 11.5 28.5T360-600Zm240 0q17 0 28.5-11.5T640-640q0-17-11.5-28.5T600-680q-17 0-28.5 11.5T560-640q0 17 11.5 28.5T600-600ZM480-200Zm0-440Z"/></svg>
              <span>Games</span> </div> */}
          </Link>{" "}
          <Link className="active" to="#">
            <div onClick={togglePopup} className="text-center flex flex-col justify-center items-center">
              <button onClick={togglePopup} className="relative text-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="23" viewBox="0 0 25 25">
                  <path
                    d="M20 3H5a.94.94 0 0 0-1 1v19l1.4-1.4L6.8 23l1.4-1.4L9.7 23l1.4-1.4 1.4 1.4 1.4-1.4 1.4 1.4 1.4-1.4 1.4 1.4 1.4-1.4L21 23V4a.94.94 0 0 0-1-1zM7 9h5v1H7zm5 8H7v-1h5zm4-3H7v-1h9zm0-7H7V6h9z"
                    fill="#9d9d9d"
                  />
                  <div xmlns="" id="divScriptsUsed" />
                  <script
                    xmlns=""
                    id="globalVarsDetection"
                    src="chrome-extension://cmkdbmfndkfgebldhnkbfhlneefdaaip/js/wrs_env.js"
                  />
                </svg>
                {marketsLength > 0 && (
                  <span className="absolute -top-1 right-0 bg-red-600 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                    {marketsLength}
                  </span>
                )}

                <span className="text-xs">Bet slip</span>
              </button>
            </div>
          </Link>
        </div>

        <div className="flex inset-0 justify-evenly">
          {(isPopupOpen)&& (
            <>
              <div className="fixed  bg-gray-900 opacity-50 z-10"></div>
              <div className="fixed bottom-[50px] left-0 right-0  bg-gray-400  rounded-t-2xl transform transition-transform translate-y-0 z-50 sm:hidden shadow-sm">
                <header className={`relative flex items-center border-b border-gray-300 pb-2 popup-header ${marketsLength === 0 ? "hidden" : ""}`}>
                  <p className="absolute left-1/2 transform -translate-x-1/2 text-white text-lg">Bets Slip</p>
                  <button onClick={togglePopup} className="ml-auto p-2">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 129 129"
                      height="20px"
                      fill="#fff"
                    >
                      <g>
                        <path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"></path>
                      </g>
                    </svg>
                  </button>
                </header>
                <div className="bg-white rounded-t-2xl">
                  <div className={`flex flex-row items-center justify-between p-3 ${marketsLength > 0 ? "hidden" : ""}`}>
                    <div></div>
                    <div className={`text-black text-center font-bold `}>Betslip</div>
                    <button onClick={togglePopup}>
                      <img
                          className="md:mr-3 w-[12px] h-[12px]"
                          src={close || ""}
                          alt="icon"
                        />
                    </button>
                  </div>
                  <RightSidebarMobile />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}


