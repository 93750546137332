import React from 'react'


const Dashboard = () => {
  return (
    <>

      <div className=''>
        Welcome to Dashboard
      </div>

    </>
  )
}

export default Dashboard