import { toast } from "react-toastify";
import { PLACEBETSLIP_FAILURE, PLACEBETSLIP_SUCCESS } from "../actions/actiontypes";

const initialState = {
  error: null,
  data: null,
  hide: true,
};

export const PlaceBetSlipReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLACEBETSLIP_SUCCESS: {
      toast.success(action.payload.message);
      return {
        ...state,
        data: action.payload,
      };
    }
    case "SUCCESS_PLACE_HIDE": {
      return {
        ...state,
        hide: action.payload,
      };
    }
    case PLACEBETSLIP_FAILURE: {
      toast.error(action.payload);
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
