const initialState = {
    isAuthenticated: false,
    data: [],
    error: null,
  };
  
  export const MatchReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'MATCHES_SUCCESS':
        return {
          ...state,
          isAuthenticated: true,
          data: action.payload,
          error: null,
        };
      case 'MATCHES_FAILURE':
        return {
          ...state,
          isAuthenticated: false,
          data: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };

  
