// src/layouts/MainLayout.js
import React from "react";
import Header from "../../components/Headers/Header";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import { RightSidebar } from "../../components/Sidebar/RightSidebar";
import { BottomAppbar } from "../../components/BottomAppbar";
import { LeftSideBar } from "../../components/Sidebar/LeftSidebar";
import HomePageHeader from "../../components/Headers/HomePageHeader";
// import { Outlet } from 'react-router-dom';

const MainLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen font-Source-Code-Pro ">
      <HomePageHeader />
      <div className="flex flex-grow md:mt-14">
        <LeftSideBar />
        <main className="flex-1 overflow-x-auto h-[100vh] ">{children}</main>
        <RightSidebar />
      </div>
      <BottomAppbar />
      <Footer />
    </div>
  );
};

export default MainLayout;
