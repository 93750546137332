import React, { useState } from 'react';

const LeftSidebar = ({ onShowDetails }) => {
    return (
        <div className="w-3/12 bg-gray-100 h-screen p-4">
            <div className="text-gray-600 text-xs font-semibold flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
                <span>0 ungelesen</span>
                <a href="#" className="text-blue-500 hover:underline">Alle als gelesen markieren</a>
            </div>

            <div
                className="flex items-start space-x-2 p-2 bg-white rounded-lg shadow cursor-pointer"
                onClick={onShowDetails}
            >
                <div className="text-yellow-500 text-l">🏆</div>
                <div className="flex-1">
                    <div className="text-gray-900 font-semibold">Dein Tipico Update im Oktober! ⚽ 🎯</div>
                    <div className="text-gray-500 text-sm">1.10, 01:03</div>
                </div>
            </div>
        </div>
    );
};

const RightPanel = ({ onClose }) => {
    return (
        <div className="w-full h-screen flex flex-col">
            <header className="sticky top-0 w-full p-6 bg-white justify-between items-center border-b border-gray-300 z-10 flex">
                <h2 className="text-lg font-semibold text-gray-700">🏆 Dein Tipico Update im Oktober! ⚽ 🎯</h2>
                <button onClick={onClose} className="text-blue-500 hover:underline text-sm">Löschen</button>
            </header>

            <div className="flex-1 overflow-y-auto p-4 bg-gray-50">
                <div className="mt-4 max-w-2xl mx-auto bg-white p-6 rounded-md shadow-xl">
                    <h3 className="text-red-500 font-bold text-center mb-4">Dein <span className="text-black">Tipico Update</span> im Oktober</h3>

                    <img src="https://link.gaming.tipico.de/custloads/781971624/md_9572.gif" alt="Highlights" className="mx-auto mb-6" />

                    <div className="flex flex-col items-center space-y-4">
                        <button className="w-8/12 bg-combisColor text-white py-2 rounded hover:bg-blue-600">Zu Tipico</button>

                        <div className="text-left p-4 bg-gray-100 rounded-lg w-full">
                            <h4 className="text-lg font-semibold mb-2">Noch mehr Inhalte:</h4>
                            <p className="text-gray-700 text-sm">
                                In unserem Blog findest du jede Woche neue <span className="font-bold">Wett-Tipps</span> für die <span className="text-blue-600 font-semibold">Champions League</span>, <span className="text-blue-600 font-semibold">Bundesliga</span>, <span className="text-blue-600 font-semibold">DFB-Pokal</span> und viele weitere <span className="font-bold">Top-Ligen und Sportarten</span>.
                            </p>
                        </div>

                        <button className="w-8/12 bg-combisColor text-white py-2 rounded hover:bg-blue-600">Zum Blog</button>

                        <div className="bg-gray-100 text-left p-4 rounded-lg w-full">
                            <h4 className="text-lg font-semibold mb-2">Newsletter schon abonniert?</h4>
                            <p className="text-gray-700 text-sm">
                                Verpasse keine Angebote & Aktionen! <span className="text-blue-600 font-semibold">Einfach anmelden</span> und deine gewünschten Marketing-Kanäle und -Mitteilungen aktivieren.
                            </p>
                        </div>

                        <button className="w-8/12 bg-combisColor text-white py-2 rounded hover:bg-blue-600">Zu den Einstellungen</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const App = () => {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <div className="flex">
            <LeftSidebar onShowDetails={() => setShowDetails(true)} />
            {showDetails && <RightPanel onClose={() => setShowDetails(false)} />}
        </div>
    );
};

export default App;
