import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../store/actions/authActions";
import { useDispatch } from "react-redux";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { MdClose } from "react-icons/md";

import {
  IconUser,
  IconVerify,
  IconTransaction,
  IconLimits,
  IconLimitHistory,
  IconBalance,
  IconOffer,
  IconAuszahlung,
  IconEinstellungen,
  IconKontakt,
  IconLogout,
} from "./icon";

const UserPageSidebar = ({ setActiveMenu }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const storedUserData = JSON.parse(localStorage.getItem("authToken"));
  const userId = storedUserData?.user?.user?.id;
  const useAuth = () => {
    const authToken = localStorage.getItem("authToken");
    return authToken ? JSON.parse(authToken) : { isAuthenticated: false };
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };
  const [balance, setBalance] = useState();
  const userName = storedUserData?.user?.user?.name || "User Name";
  useEffect(() => {
    const getBalance = async () => {
      try {
        const storedUserData = JSON.parse(localStorage.getItem("authToken"));
        const token = storedUserData?.user?.token;
        if (!token) throw new Error("Token not found in localStorage");

        const response = await fetch("http://38.180.122.61:3000/api/user/get-balance", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) throw new Error("Network response was not ok");

        const data = await response.json();
        setBalance(data);
      } catch (error) {
        console.error("Veri çekilirken hata oluştu:", error.message);
      }
    };
    if (userName) {
      const timeoutId = setInterval(getBalance, 2000);
      return () => clearInterval(timeoutId);
    }
  }, []);

  const renderBalanceDetails = () => (
    <div className="balance-details mt-2">
      <ul className="text-sm text-black">
        {["Auszahlbar", "Bonus", "Einzahlungsumsatz"].map((item) => (
          <li key={item} className="flex justify-between py-1">
            <span>{item}</span>
            <span>{0.00} €</span>
          </li>
        ))}
      </ul>
    </div>
  );

  const toggleDetails = () => setIsVisible(!isVisible);

  const handleMenuClick = (menuItem) => {
    setActiveMenu(menuItem);
    if (window.innerWidth < 768) {
      setIsMobileMenuOpen(false);
    }
  };

  const openMenu = () => {
    setIsMobileMenuOpen(true);
  };

  return (
    <main className="page flex h-screen">
      {!isMobileMenuOpen && window.innerWidth < 768 && (
        <button onClick={openMenu} className="md:hidden p-2 text-white absolute top-4 left-4 rounded">
          <FaChevronLeft />
        </button>
      )}

      {isMobileMenuOpen && window.innerWidth < 768 && (
        <aside className="w-full absolute inset-0 z-20 h-[200vh] bg-customs md:hidden">
          <div className="w-full">
            <div className="flex items-center justify-between w-full p-4 bg-red-700 text-white">
              <button onClick={() => navigate("/")}>
                <MdClose className="text-2xl" />
              </button>
              <h2 className="text-lg flex-1 text-center">{userId}</h2>
            </div>
          </div>

          <div className="p-4 bg-white mb-2 mt-5">
            <div className="flex items-center justify-between text-gray-800">
              <div className="balance-header text-left">
                Hesabım
                <div
                  className="balance-amount text-2xl text-gray-500 font-semibold cursor-pointer"
                  onClick={toggleDetails}
                >
                  {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'EUR' }).format(balance)} <span className="text-sm text-black">▼</span>
                </div>
              </div>

              <button className="bg-green-500 text-white px-4 py-2 rounded">Para Yatır</button>
            </div>
            {isVisible && renderBalanceDetails()}
          </div>

          <div className="p-4 bg-yellow-100  text-yellow-800 rounded ">
            <h3 className="font-semibold">Hesap durumu</h3>
            <p>Hesabınız onaylı</p>
          </div>

          <div className="p-6">
            <div className="menu-container bg-white rounded-md shadow p-6 mb-4">
              <nav className="nav">
                <ul className="space-y-4">
                  {[
                    { name: "Meine Daten", icon: <IconUser /> },
                    { name: "Kontoverifizierung", icon: <IconVerify /> },
                    { name: "Limits & Schließung", icon: <IconLimits /> },
                    { name: "Limitverlauf", icon: <IconLimitHistory /> },
                    { name: "Transaktionsverlauf", icon: <IconTransaction /> },
                  ].map((item) => (
                    <li
                      className="flex items-center justify-between text-gray-900 p-2 border-b border-gray-200 cursor-pointer"
                      key={item.name}
                      onClick={() => handleMenuClick(item.name)}
                    >
                      <div className="flex items-center gap-2">
                        {item.icon}
                        {item.name}
                      </div>
                      <FaChevronRight className="text-gray-400" />
                    </li>
                  ))}
                </ul>
              </nav>
            </div>

            <div className="menu-container bg-white rounded-md shadow p-4">
              <nav className="nav">
                <ul className="space-y-4">
                  {[
                    { name: "Guthaben", icon: <IconBalance /> },
                    { name: "Angebote", icon: <IconOffer /> },
                    { name: "Auszahlung", icon: <IconAuszahlung /> },
                  ].map((item) => (
                    <li
                      className="flex items-center justify-between text-gray-900 p-2 border-b border-gray-200 cursor-pointer"
                      key={item.name}
                      onClick={() => handleMenuClick(item.name)}
                    >
                      <div className="flex items-center gap-2">
                        {item.icon}
                        {item.name}
                      </div>
                      <FaChevronRight className="text-gray-400" />
                    </li>
                  ))}
                </ul>
              </nav>
            </div>

            <div className="menu-container bg-white rounded-md shadow p-4 mt-4">
              <nav className="nav">
                <ul className="space-y-4">
                  {[
                    { name: "Einstellungen", icon: <IconEinstellungen /> },
                    { name: "Kontakt", icon: <IconKontakt /> },
                    { name: "Abmelden", icon: <IconLogout />, onClick: handleLogout },
                  ].map((item) => (
                    <li
                      className="flex items-center justify-between text-gray-900 p-2 border-b border-gray-200 cursor-pointer"
                      key={item.name}
                      onClick={item.onClick || (() => handleMenuClick(item.name))}
                    >
                      <div className="flex items-center gap-2">
                        {item.icon}
                        {item.name}
                      </div>
                      <FaChevronRight className="text-gray-400" />
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </aside>
      )}

      <aside className="hidden md:block w-80 bg-customs p-4">
        <div className="account-balance mb-6">
          <div className="balance-header text-left">
            <div className="balance-amount text-2xl text-gray-500 font-semibold cursor-pointer" onClick={toggleDetails}>
              {balance} € <span className="text-sm text-black">▼</span>
            </div>
          </div>
          {isVisible && renderBalanceDetails()}
          <span className="mt-4 block bg-green-500 h-12 flex items-center justify-center text-white py-2 rounded">
            <a href="/payout">Para Yatır</a>
          </span>
        </div>

        <div className="menu-container bg-white cursor-pointer text-detailText text-userText p-4 rounded-md shadow">
          <nav className="nav">
            <ul className="space-y-4">
              {[
                { name: "Meine Daten", icon: <IconUser /> },
                { name: "Kontoverifizierung", icon: <IconVerify /> },
                { name: "Transaktionsverlauf", icon: <IconTransaction /> },
                { name: "Limits & Schließung", icon: <IconLimits /> },
                { name: "Limitverlauf", icon: <IconLimitHistory /> },
              ].map((item) => (
                <li className="flex items-center gap-2" key={item.name} onClick={() => handleMenuClick(item.name)}>
                  {item.icon}
                  {item.name}
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="menu-container bg-white cursor-pointer text-detailText text-userText p-4 rounded-md shadow mt-4">
          <nav className="nav">
            <ul className="space-y-4">
              {[
                { name: "Guthaben", icon: <IconBalance /> },
                { name: "Angebote", icon: <IconOffer /> },
                { name: "Auszahlung", icon: <IconAuszahlung /> },
              ].map((item) => (
                <li className="flex items-center gap-2" key={item.name} onClick={() => handleMenuClick(item.name)}>
                  {item.icon}
                  {item.name}
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="menu-container bg-white cursor-pointer text-detailText text-userText p-4 rounded-md shadow mt-4">
          <nav className="nav">
            <ul className="space-y-4">
              {[
                { name: "Einstellungen", icon: <IconEinstellungen /> },
                { name: "Kontakt", icon: <IconKontakt /> },
                { name: "Abmelden", icon: <IconLogout />, onClick: handleLogout },
              ].map((item) => (
                <li
                  className="flex items-center gap-2"
                  key={item.name}
                  onClick={item.onClick || (() => handleMenuClick(item.name))}
                >
                  {item.icon}
                  {item.name}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </aside>

      {!isMobileMenuOpen && (
        <div className={`flex-1 overflow-auto w-full ${isMobileMenuOpen ? "hidden" : ""}`}>
          <h1 className="text-xl font-bold">{setActiveMenu}</h1>
        </div>
      )}
    </main>
  );
};

export default UserPageSidebar;
