import axios from "axios";
import { PLACEBETSLIP_SUCCESS, PLACEBETSLIP_FAILURE } from "./actiontypes";
import { placeBetSlip } from "../paths";
import { toast } from "react-toastify";
import { clearOdds } from "./homeTypes";

export const PlaceBetSlipApi = (uuid) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("authToken"))?.user?.token;
    const response = await axios.get(placeBetSlip + uuid, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: PLACEBETSLIP_SUCCESS,
      payload: {
        data: response.data,
      },
    });
    dispatch(clearOdds());
    toast.success(response.data.message);
  } catch (error) {
    toast.error(error.response?.data?.error || "api failed. Please try again.");
    dispatch({
      type: PLACEBETSLIP_FAILURE,
      payload: error.response?.data?.error || "api failed. Please try again.",
    });
  }
};

export const hideSuccess = (hide) => {
  return {
    type: "SUCCESS_PLACE_HIDE",
    payload: hide,
  };
};
