import axios from "axios";
import { calculateBetSlip } from "../paths";
import { CALCULATE_BETSLIP_FAILURE, CALCULATE_BETSLIP_SUCCESS } from "./actiontypes";

export const CalculateBetSlipApi = (body, uuid) => async (dispatch) => {
  try {
    const response = await axios.post(calculateBetSlip + uuid, body);
    dispatch({
      type: CALCULATE_BETSLIP_SUCCESS,
      payload: {
        data: response.data,
        body: body,
      },
    });
  } catch (error) {
    dispatch({
      type: CALCULATE_BETSLIP_FAILURE,
      payload: error.response?.data?.message || "api failed. Please try again.",
    });
  }
};

export const changeBetBuilder = (matchId, name, active) => async (dispatch) => {
  dispatch({
    type: "BET_BUILDER_CHANGE",
    payload: {
      matchId: matchId,
      name: name,
      active: active,
    },
  });
};

export const toggleBankers = (matchId) => async (dispatch) => {
  dispatch({
    type: "BANKER_CHANGE",
    payload: {
      matchId: matchId,
    },
  });
};

export const setSelectedOptions = (values) => async (dispatch) => {
  dispatch({
    type: "SELECTED_OPTIONS_CHANGE",
    payload: values,
  });
};

export const showPopup = (active, matchId) => async (dispatch) => {
  dispatch({
    type: "SHOW_POPUP",
    payload: {
      showPopup: {
        active: active,
        matchId: matchId,
      },
    },
  });
};
