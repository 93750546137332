import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { TopMatchesApi } from "../../store/actions/top_matches";
import { TMTopMatchesBody } from "../TopMatches/TopMatchesBody";
import CustomSwitch from "../CustomSwitch";

export const TopEvents = ({ showDetail, inMain = false }) => {
  const dispatch = useDispatch();
  const [betTab, setBetTab] = useState();
  const [selectedOddsIds, setSelectedOddsIds] = useState([]);
  const [dropDownCount, setDropDownCount] = useState(5);

  const maxDropDownCount = useSelector(
    (state) => state?.topmatches?.dropDownCount
  );

  const updateDropDownCount = () => {
    const width = window.innerWidth;
    let count;

    if (width < 650) {
      count = 1;
    } else if (width < 1000) {
      count = 2;
    } else if (width < 1250) {
      count = 3;
    } else if (width < 1500) {
      count = 4;
    } else {
      count = maxDropDownCount;
    }
    if (showDetail) {
      count -= 2;
    }
    setDropDownCount(count);
  };

  useEffect(() => {
    updateDropDownCount();
    window.addEventListener("resize", updateDropDownCount);
    return () => {
      window.removeEventListener("resize", updateDropDownCount);
    };
  }, [maxDropDownCount, showDetail]);

  const availableSports = useSelector(
    (state) => state?.topmatches?.availableSports
  );

  const sportsArray = useMemo(() => {
    if (!availableSports) {
      return [];
    }
    const array = Object.entries(availableSports).map(([id, sport]) => ({
      id,
      ...sport,
    }));
    setBetTab(array[0].id);
    return array;
  }, [availableSports]);

  useEffect(() => {
    dispatch(
      TopMatchesApi({
        pageNumber: 1,
        pageSize: 10,
      })
    );
  }, [dispatch]);

  return (
    <div className="grid mt-6 grid-cols-12">
      <div className={`col-span-12 bg-custom  overflow-auto scrollbar-hide`}>
        {!inMain && (
          <div className={`flex items-center w-full gap-4  pb-4`}>
            <span>Sort by:</span>
            <CustomSwitch />
          </div>
        )}
        <span className="ml-4  mt-6 text-sm font-semibold">Top-Events</span>
        <div className="shadow-topCombisShadow">
          <div className="flex bg-white items-center shadow-topCombisShadow  w-full overflow-x-auto gap-1.5 h-16 mt-3">
            {sportsArray.map((entries, index) => {
              return (
                <div
                  key={index}
                  className={`flex  items-center cursor-pointer px-3 h-full border-b-2 ${
                    betTab === entries.id ? "border-red-500" : " border-gray-50"
                  } text-center`}
                  onClick={async () => {
                    setBetTab(entries.id);
                  }}
                >
                  <span className="font-semibold truncate font-Source-Code-Pro text-sm">
                    {entries.name}
                  </span>
                </div>
              );
            })}
          </div>

          <div className="grid">
            <TMTopMatchesBody
              betTab={betTab}
              showDetail={showDetail}
              selectedOddsIds={selectedOddsIds}
              setSelectedOddsIds={setSelectedOddsIds}
              dropDownCount={dropDownCount}
            />
          </div>
        </div>
        {showDetail ? (
          <div
            className={`${
              showDetail ? "col-span-3 flex justify-center" : ""
            } bg-custom overflow-auto scrollbar-hide  `}
          >
            <div
              className={`fixed mt-11 h-[100vh] col-md-5 transition-transform duration-1000 ease-in-out transform ${
                showDetail ? "translate-x-1" : "translate-x-12"
              }`}
            ></div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
