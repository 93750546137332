// Icons.js
import React from "react";

export const IconUser = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    className="fill-icon-highlight"
  >
    <path
      d="m12 2a10 10 0 1 0 10 10 10 10 0 0 0 -10-10zm0 1.5a8.49 8.49 0 0 1 6.81 13.57 6.34 6.34 0 0 0 -4.47-3.31 4.26 4.26 0 1 0 -4.84 0h-.09l-.52.13-.17.05a4.56 4.56 0 0 0 -.52.18h-.11l-.49.22h-.07a4.79 4.79 0 0 0 -.45.25h-.06l-.4.28a2.6 2.6 0 0 0 -.36.3 3 3 0 0 0 -.3.32v.05a3.92 3.92 0 0 0 -.25.32v.07a3.28 3.28 0 0 0 -.2.31l-.06.1-.16.29a.93.93 0 0 0 -.05.1 8.49 8.49 0 0 1 6.76-13.23zm0 9.5a2.75 2.75 0 1 1 2.75-2.75 2.75 2.75 0 0 1 -2.75 2.75zm-5.81 5.19v-.1c0-.13.08-.25.13-.37a3.93 3.93 0 0 1 .21-.41 2.32 2.32 0 0 1 .22-.31 3.37 3.37 0 0 1 .3-.36c.07-.08.15-.18.23-.26l.38-.29c.1-.07.19-.15.29-.21a4.09 4.09 0 0 1 .47-.23 2 2 0 0 1 .33-.16c.18-.07.38-.12.57-.18l.37-.1c.22-.05.47-.08.72-.12l.34-.05a11.47 11.47 0 0 1 1.25-.04c3 0 5.05 1.18 5.75 3.31a8.46 8.46 0 0 1 -11.51-.1z"
      fill="inherit"
    ></path>
  </svg>
);

export const IconVerify = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    className="fill-icon-highlight"
  >
    <g fill="inherit">
      <path d="m10.49 15.88a.86.86 0 0 1 -.61-.25l-3.61-3.63a.85.85 0 1 1 1.2-1.2l3 3 7.93-9.31a.85.85 0 1 1 1.29 1.1l-8.52 10a.84.84 0 0 1 -.61.3z"></path>
      <path d="m20.49 6.73-1.04 1.27a8.43 8.43 0 0 1 1.05 4.27 4.58 4.58 0 0 0 0 .53 8.5 8.5 0 1 1 -7.71-9.23 8.57 8.57 0 0 1 3.58 1.18l1-1.16a10 10 0 1 0 -6.26 18.41h.91a10 10 0 0 0 9.98-9.11c0-.21 0-.41 0-.62s0 0 0 0a8.53 8.53 0 0 0 -.06-1.34.49.49 0 0 0 0-.12 8.68 8.68 0 0 0 -.26-1.33s0 0 0-.08a11.08 11.08 0 0 0 -.51-1.4s0 0 0 0a10.36 10.36 0 0 0 -.5-1z"></path>
    </g>
  </svg>
);

export const IconTransaction = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    className="fill-icon-highlight"
  >
    <g fill="inherit">
      <path d="m4 8.5h14.39l-2.22 2.22a.74.74 0 0 0 0 1.06.71.71 0 0 0 .53.22.74.74 0 0 0 .53-.22l3.5-3.5a.75.75 0 0 0 0-1.06l-3.5-3.5a.75.75 0 0 0 -1.06 0 .74.74 0 0 0 0 1.06l2.22 2.22h-14.39a.75.75 0 0 0 0 1.5z"></path>
      <path d="m20.2 15.5h-14.39l2.19-2.22a.75.75 0 1 0 -1-1.06l-3.5 3.5a.75.75 0 0 0 0 1.06l3.5 3.5a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06l-2.25-2.22h14.39a.75.75 0 1 0 0-1.5z"></path>
    </g>
  </svg>
);

export const IconLimits = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    className="fill-icon-highlight"
  >
    <g fill="inherit">
      <path d="m19.5 4h-2.25v-.5a.75.75 0 0 0 -1.5 0v.5h-3v-.5a.75.75 0 0 0 -1.5 0v.5h-3v-.5a.75.75 0 0 0 -1.5 0v.5h-2.25a1.5 1.5 0 0 0 -1.5 1.5v14a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-14a1.5 1.5 0 0 0 -1.5-1.5zm-12.75 1.5v.5a.75.75 0 0 0 1.5 0v-.5h3v.5a.75.75 0 0 0 1.5 0v-.5h3v.5a.75.75 0 0 0 1.5 0v-.5h2.25v2.5h-15v-2.5zm-2.25 14v-10h15v10z"></path>
      <path d="m12 15.05a.85.85 0 0 0 .85-.85v-2.5a.85.85 0 0 0 -1.7 0v2.5a.85.85 0 0 0 .85.85z"></path>
      <circle cx="12" cy="17" r="1"></circle>
    </g>
  </svg>
);

export const IconLimitHistory = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-icon-highlight"
  >
    <path d="M20,3H17.25a.75.75,0,0,0-1.5,0h-3a.75.75,0,0,0-1.5,0h-3a.75.75,0,0,0-1.5,0H4A1.5,1.5,0,0,0,2.5,4.5v15A1.5,1.5,0,0,0,4,21H20a1.5,1.5,0,0,0,1.5-1.5V4.5A1.5,1.5,0,0,0,20,3ZM6.75,4.5v1a.75.75,0,0,0,1.5,0v-1h3v1a.75.75,0,0,0,1.5,0v-1h3v1a.75.75,0,0,0,1.5,0v-1H20V6.25H4V4.5ZM4,7.75H20v7.5H17.3a1.56,1.56,0,0,0-1.55,1.55v2.7H4ZM17.26,19.5l0-2.75H20V19.5Z"></path>
    <path d="M14,11.25H7a.75.75,0,0,0,0,1.5h7a.75.75,0,0,0,0-1.5Z"></path>
    <path d="M12.25,15.25H7a.75.75,0,0,0,0,1.5h5.25a.75.75,0,0,0,0-1.5Z"></path>
  </svg>
);

export const IconBalance = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-icon-highlight"
  >
    <g fill="inherit">
      <path d="m20.88 10.52v-3.61a2.13 2.13 0 0 0 -2.13-2.13h-.95l-1.1-2a1.47 1.47 0 0 0 -1.95-.56l-4.54 2.56h-5.08a2.13 2.13 0 0 0 -2.13 2.13v12a2.14 2.14 0 0 0 2.13 2.09h13.62a2.14 2.14 0 0 0 2.13-2.13v-3.12h.87v-5.23zm-10.56-4.12 5.1-2.84 1.58 2.84zm8.3-.15h.13a.69.69 0 0 1 .41.15h-.46zm-11.34.15h-1.93a.74.74 0 0 0 0 1.48h14.05v2.64h-2.16a2.54 2.54 0 0 0 -2.53 2.54v.15a2.54 2.54 0 0 0 2.53 2.54h2.16v3.12a.65.65 0 0 1 -.65.65h-13.62a.65.65 0 0 1 -.65-.65v-12a.66.66 0 0 1 .65-.66h2.42zm8.91 6.66a1.06 1.06 0 0 1 1-1.06h3v2.27h-3a1.06 1.06 0 0 1 -1-1.06z"></path>
      <path d="m17.75 13.87a.75.75 0 1 0 -.75-.75.75.75 0 0 0 .75.75z"></path>
    </g>
  </svg>
);

export const IconOffer = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-icon-highlight"
  >
    <path
      d="m20.5 7h-4a5.18 5.18 0 0 0 .5-.43 2.85 2.85 0 0 0 0-4 2.85 2.85 0 0 0 -4 0 7.25 7.25 0 0 0 -1.33 2.08 7.25 7.25 0 0 0 -1.33-2.08 2.82 2.82 0 0 0 -4 0 2.87 2.87 0 0 0 0 4 5.18 5.18 0 0 0 .51.43h-3.35a1.5 1.5 0 0 0 -1.5 1.5v2.5a1.48 1.48 0 0 0 1 1.41s0 .06 0 .09v7a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-7s0-.06 0-.09a1.48 1.48 0 0 0 1-1.41v-2.5a1.5 1.5 0 0 0 -1.5-1.5zm0 4h-8v-2.5h8zm-6.43-7.41a1.35 1.35 0 0 1 1.93 1.92 6.18 6.18 0 0 1 -3.08 1.49.53.53 0 0 1 -.24 0c-.29-.39.55-2.56 1.39-3.41zm-6.68 0a1.35 1.35 0 0 1 1.9 0c.84.85 1.68 3 1.39 3.32a.48.48 0 0 1 -.24 0 6.18 6.18 0 0 1 -3-1.44 1.38 1.38 0 0 1 -.05-1.88zm-3.89 4.91h7.5v2.5h-7.5zm1 4h6.5v7h-6.5zm15 7h-7v-7h7z"
      fill="inherit"
    ></path>
  </svg>
);

export const IconAngebote = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-icon-highlight"
  >
    <path
      d="m20.5 7h-4a5.18 5.18 0 0 0 .5-.43 2.85 2.85 0 0 0 0-4 2.85 2.85 0 0 0 -4 0 7.25 7.25 0 0 0 -1.33 2.08 7.25 7.25 0 0 0 -1.33-2.08 2.82 2.82 0 0 0 -4 0 2.87 2.87 0 0 0 0 4 5.18 5.18 0 0 0 .51.43h-3.35a1.5 1.5 0 0 0 -1.5 1.5v2.5a1.48 1.48 0 0 0 1 1.41s0 .06 0 .09v7a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-7s0-.06 0-.09a1.48 1.48 0 0 0 1-1.41v-2.5a1.5 1.5 0 0 0 -1.5-1.5zm0 4h-8v-2.5h8zm-6.43-7.41a1.35 1.35 0 0 1 1.93 1.92 6.18 6.18 0 0 1 -3.08 1.49.53.53 0 0 1 -.24 0c-.29-.39.55-2.56 1.39-3.41zm-6.68 0a1.35 1.35 0 0 1 1.9 0c.84.85 1.68 3 1.39 3.32a.48.48 0 0 1 -.24 0 6.18 6.18 0 0 1 -3-1.44 1.38 1.38 0 0 1 -.05-1.88zm-3.89 4.91h7.5v2.5h-7.5zm1 4h6.5v7h-6.5zm15 7h-7v-7h7z"
      fill="inherit"
    ></path>
  </svg>
);
export const IconAuszahlung = () => (
  <svg
    s
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-icon-highlight"
  >
    <g fill="inherit">
      <path d="m9.75 17.5h4.5a.75.75 0 0 0 0-1.5h-4.5a.75.75 0 0 0 0 1.5z"></path>
      <path d="m12 14.45a2.7 2.7 0 1 0 -2.7-2.7 2.7 2.7 0 0 0 2.7 2.7zm0-4a1.3 1.3 0 1 1 -1.3 1.3 1.3 1.3 0 0 1 1.3-1.3z"></path>
      <path d="m20.76 3h-17.52a1.22 1.22 0 0 0 -1.24 1.21v4.58a1.22 1.22 0 0 0 1.24 1.21h2.76v9.5a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5-1.5v-9.5h2.76a1.22 1.22 0 0 0 1.24-1.21v-4.58a1.22 1.22 0 0 0 -1.24-1.21zm-4.26 16.5h-9v-12h9zm4-11h-2.5v-1h.5a.75.75 0 0 0 0-1.5h-13a.75.75 0 0 0 0 1.5h.5v1h-2.5v-4h17z"></path>
    </g>
  </svg>
);
export const IconEinstellungen = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-icon-highlight"
  >
    <g fill="inherit">
      <path d="m12.62 3.5.2 1.17a1.51 1.51 0 0 0 1 1.19 6.1 6.1 0 0 1 1.18.47 1.57 1.57 0 0 0 .7.17 1.48 1.48 0 0 0 .87-.28l1-.69.92.92-.69 1a1.51 1.51 0 0 0 -.12 1.55 6 6 0 0 1 .46 1.11 1.48 1.48 0 0 0 1.18 1l1.18.2v1.32l-1.18.19a1.51 1.51 0 0 0 -1.19 1.05 6 6 0 0 1 -.46 1.1 1.51 1.51 0 0 0 .11 1.57l.7 1-.93.93-1-.7a1.46 1.46 0 0 0 -.87-.28 1.57 1.57 0 0 0 -.7.17 6 6 0 0 1 -1.11.46 1.5 1.5 0 0 0 -1 1.18l-.2 1.19h-1.33l-.2-1.18a1.5 1.5 0 0 0 -1-1.19 6 6 0 0 1 -1.14-.43 1.57 1.57 0 0 0 -.7-.17 1.48 1.48 0 0 0 -.87.28l-1 .7-.93-.92.69-1a1.5 1.5 0 0 0 .16-1.58 6.52 6.52 0 0 1 -.47-1.09 1.47 1.47 0 0 0 -1.18-1l-1.2-.21v-1.32l1.17-.19a1.52 1.52 0 0 0 1.19-1 5.87 5.87 0 0 1 .44-1.19 1.51 1.51 0 0 0 -.1-1.57l-.71-1 .93-.93 1 .69a1.52 1.52 0 0 0 1.58.16 6.62 6.62 0 0 1 1.1-.47 1.49 1.49 0 0 0 1-1.18l.2-1.2zm.22-1.5h-1.76a1.23 1.23 0 0 0 -1.22 1l-.24 1.45a8.51 8.51 0 0 0 -1.36.55l-1.15-.8a1.2 1.2 0 0 0 -.71-.2 1.25 1.25 0 0 0 -.88.36l-1.25 1.22a1.24 1.24 0 0 0 -.13 1.59l.86 1.17a7.69 7.69 0 0 0 -.55 1.37l-1.45.23a1.23 1.23 0 0 0 -1 1.22v1.76a1.23 1.23 0 0 0 1 1.22l1.42.24a8.14 8.14 0 0 0 .58 1.36l-.8 1.15a1.23 1.23 0 0 0 .14 1.59l1.24 1.24a1.19 1.19 0 0 0 .87.37 1.23 1.23 0 0 0 .72-.23l1.17-.86a8 8 0 0 0 1.32.54l.24 1.46a1.23 1.23 0 0 0 1.22 1h1.76a1.23 1.23 0 0 0 1.22-1l.24-1.41a7.64 7.64 0 0 0 1.36-.59l1.16.83a1.23 1.23 0 0 0 .72.23 1.21 1.21 0 0 0 .87-.37l1.24-1.24a1.23 1.23 0 0 0 .14-1.59l-.83-1.16a7.73 7.73 0 0 0 .56-1.37l1.44-.23a1.23 1.23 0 0 0 1-1.22v-1.76a1.22 1.22 0 0 0 -1-1.22l-1.41-.24a7.34 7.34 0 0 0 -.59-1.36l.83-1.16a1.23 1.23 0 0 0 -.14-1.59l-1.23-1.24a1.23 1.23 0 0 0 -.88-.37 1.2 1.2 0 0 0 -.71.23l-1.16.83a8.2 8.2 0 0 0 -1.41-.58l-.24-1.42a1.23 1.23 0 0 0 -1.22-1z"></path>
      <path d="m12 9.5a2.5 2.5 0 1 1 -2.5 2.5 2.5 2.5 0 0 1 2.5-2.5m0-1.5a4 4 0 1 0 4 4 4 4 0 0 0 -4-4z"></path>
    </g>
  </svg>
);
export const IconKontakt = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth="1.5"
    stroke="currentColor"
    class="text-icon-highlight"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
    ></path>
  </svg>
);
export const IconLogout = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-icon-highlight"
  >
    <g fill="inherit">
      <path d="m22.5 11.22-3.18-3.22a.74.74 0 0 0 -1.06 0 .75.75 0 0 0 0 1.06l1.9 1.9h-11.57a.75.75 0 0 0 0 1.5h11.57l-1.9 1.9a.75.75 0 0 0 0 1.06.75.75 0 0 0 1.06 0l3.18-3.18a.75.75 0 0 0 0-1.02z"></path>
      <path d="m16.37 14.07a.74.74 0 0 0 -1 .36 6.25 6.25 0 1 1 0-5.36.75.75 0 1 0 1.35-.64 7.67 7.67 0 0 0 -6.97-4.43 7.75 7.75 0 0 0 0 15.5 7.67 7.67 0 0 0 7-4.43.75.75 0 0 0 -.38-1z"></path>
    </g>
  </svg>
);
export const TransactionSvg1 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 md:h-6 md:w-6 lg:h-7 lg:w-7 mr-1"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M3 4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v2a1 1 0 0 1-.293.707l-5.414 5.414a1 1 0 0 0-.293.707v4.586a1 1 0 0 1-.293.707l-2 2A1 1 0 0 1 11 20v-6.586a1 1 0 0 0-.293-.707L5.293 6.707A1 1 0 0 1 5 6V4z" />
  </svg>
);
export const TransactionSvg2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 md:h-6 md:w-6 lg:h-7 lg:w-7 mr-2 text-yellow-600"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13 16h-1v-4h-1m1 0h.01M12 9.75A.75.75 0 1112 8a.75.75 0 010 1.5zm0 9.25a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5z"
    />
  </svg>
);