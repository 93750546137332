import React, { useMemo, useEffect, useState, act, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showDetailHome } from "../../../store/actions/homeTypes";
import { shirt1, shirt2 } from "../../../assets/images/index";
import "../../../app/pages/user-page/userPage.css";
import moment from "moment";
import { useHandleOddsClick } from "../../utility/handleOdd";
import { changeBetBuilder, showPopup } from "../../../store/actions/calculateBetSlipAction";
import { FaRegEye, FaRegEyeSlash, FaRegUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../store/actions/authActions";
import { Button } from "@mui/material";

export const DetailCard = () => {
  const dispatch = useDispatch();
  const test = useSelector((state) => state?.homereducer);
  const odds = test.showDetail.odds;
  const match = test.showDetail.match;
  const [activeTab, setActiveTab] = useState(2);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBalanceVisible, setIsBalanceVisible] = useState(true);
  const navigate = useNavigate();
  const storedUserData = JSON.parse(localStorage.getItem("authToken"));
  const userName = storedUserData?.user?.user?.name || "User Name";

  const [balance, setBalance] = useState(() => {
    const savedBalance = localStorage.getItem("balance");
    return savedBalance ? JSON.parse(savedBalance) : 0;
  });

  useEffect(() => {
    const getBalance = async () => {
      try {
        const storedUserData = JSON.parse(localStorage.getItem("authToken"));
        const token = storedUserData?.user?.token;
        if (!token) {
          return;
        }

        const response = await fetch("http://38.180.122.61:3000/api/user/get-balance", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          dispatch(logout());
          return;
        }

        const data = await response.json();
        setBalance(data);
        localStorage.setItem("balance", JSON.stringify(data));
      } catch (error) {
        console.error("Veri çekilirken hata oluştu:", error.message);
      }
    };

    if (userName) {
      const timeoutId = setInterval(getBalance, 2000);
      return () => clearInterval(timeoutId);
    }
  }, [userName]);

  const useAuth = () => {
    const authToken = localStorage.getItem("authToken");
    return authToken ? JSON.parse(authToken) : { isAuthenticated: false };
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const { isAuthenticated } = useAuth();
  const ids = storedUserData?.user?.user?.id;
  const showDetail = useSelector((state) => state?.homereducer?.showDetail);
  const oddList = useSelector((state) => state?.homereducer?.odds);
  const betBuilderMatches = useSelector((state) => state?.calculateBetSlip?.betBuilderMatches);

  const alleCombiChange = (active, matchId, name) => {
    if (oddList && oddList.length > 0) {
      dispatch(showPopup(active, matchId));
    } else {
      dispatch(changeBetBuilder(matchId, name, active));
    }
  };

  const tabIcons = {
    1: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
        className="inline-block mr-1"
      >
        <path
          stroke="#212121"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M17.45 17.5H2.55c-.487 0-.883-.396-.883-.884V3.384c0-.488.396-.884.884-.884h14.9c.487 0 .882.396.882.884v13.233c0 .487-.395.883-.884.883z"
        />
        <path
          stroke="#212121"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M4.167 12.5h-2.5v-5h2.5c.46 0 .833.373.833.833v3.334c0 .46-.373.833-.833.833zM18.333 12.5h-2.5c-.46 0-.833-.373-.833-.833V8.333c0-.46.373-.833.833-.833h2.5v5zM11.768 8.232c.976.977.976 2.56 0 3.536-.977.976-2.56.976-3.536 0-.976-.977-.976-2.56 0-3.536.977-.976 2.56-.976 3.536 0zM10 2.5v5M10 13v4"
        />
      </svg>
    ),
    2: (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22" fill="none">
        <path
          d="M11.5 20V13H7V20H11.5ZM11.5 20H16M11.5 20V4H16V20M16 20H20.5V10H16V20Z"
          stroke="#717171"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M2.5 10C2.5 6.68629 5.18629 4 8.5 4" stroke="#717171" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M5 10C5 8.067 6.567 6.5 8.5 6.5" stroke="#717171" strokeWidth="1.5" strokeLinecap="round" />
      </svg>
    ),
    3: (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 24" fill="none">
        <path
          d="M18.6667 3H6.66669C4.45755 3 2.66669 4.79086 2.66669 7V17C2.66669 19.2091 4.45755 21 6.66669 21H18.6667C20.8758 21 22.6667 19.2091 22.6667 17V7C22.6667 4.79086 20.8758 3 18.6667 3Z"
          stroke="#717171"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.8826 10.6364L9.60135 12.0739L10.3457 10.6364H11.7462L10.5275 12.8182L11.7917 15H10.4025L9.60135 13.5511L8.8201 15H7.41101L8.67805 12.8182L7.47351 10.6364H8.8826ZM16.1986 11.0824C16.1665 10.9631 16.1191 10.858 16.0566 10.767C15.9941 10.6742 15.9174 10.5956 15.8265 10.5312C15.7356 10.4669 15.6314 10.4186 15.514 10.3864C15.3966 10.3523 15.2678 10.3352 15.1276 10.3352C14.8454 10.3352 14.6011 10.4034 14.3947 10.5398C14.1901 10.6761 14.032 10.875 13.9202 11.1364C13.8085 11.3958 13.7526 11.7112 13.7526 12.0824C13.7526 12.4555 13.8066 12.7737 13.9146 13.0369C14.0225 13.3002 14.1788 13.5009 14.3833 13.6392C14.5879 13.7775 14.836 13.8466 15.1276 13.8466C15.3852 13.8466 15.603 13.8049 15.781 13.7216C15.961 13.6364 16.0973 13.5161 16.1901 13.3608C16.2829 13.2055 16.3293 13.0227 16.3293 12.8125L16.5907 12.8438H15.1759V11.8182H17.6844V12.5881C17.6844 13.1089 17.5736 13.5549 17.3521 13.9261C17.1324 14.2973 16.8293 14.5824 16.443 14.7812C16.0585 14.9801 15.6163 15.0795 15.1163 15.0795C14.5613 15.0795 14.0736 14.9593 13.6532 14.7188C13.2327 14.4782 12.9051 14.1354 12.6702 13.6903C12.4354 13.2434 12.318 12.7131 12.318 12.0994C12.318 11.6222 12.389 11.1989 12.531 10.8295C12.6731 10.4583 12.871 10.1439 13.1248 9.88636C13.3805 9.62879 13.6759 9.43371 14.0111 9.30114C14.3483 9.16856 14.711 9.10227 15.0992 9.10227C15.4363 9.10227 15.7498 9.15057 16.0396 9.24716C16.3312 9.34375 16.5888 9.48011 16.8123 9.65625C17.0377 9.83239 17.2204 10.0417 17.3606 10.2841C17.5007 10.5265 17.5879 10.7926 17.6219 11.0824H16.1986Z"
          fill="#717171"
        />
      </svg>
    ),
    4: (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="-2 -2 24 22">
        <path
          stroke="#717171"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M15 10h2.116c.46 0 .833-.373.833-.833V5.913c0-.96-.55-1.836-1.416-2.253l-3.075-1.48c-.638-.307-1.4-.177-1.901.323l-.511.511c-.651.651-1.706.651-2.357 0l-.517-.518c-.497-.498-1.254-.628-1.89-.328L3.483 3.49c-.875.413-1.432 1.293-1.432 2.26v3.417c0 .46.373.833.833.833H5"
        />
        <path
          stroke="#717171"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M5.417 7.5v9.167c0 .46.373.833.833.833h7.5c.46 0 .833-.373.833-.833V7.5 10"
        />
        <path
          fill="#717171"
          d="M8.328 13.333v-.861H9.63V8.64H8.558v-.66c.293-.055.543-.12.751-.192.214-.08.413-.175.596-.285h.788v4.969h1.146v.861H8.33z"
        />
      </svg>
    ),
    5: (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
        <g clipPath="url(#clip0)">
          <path
            stroke="#717171"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M8.334 1.667h3.333M14.714 6.12c2.604 2.603 2.604 6.824 0 9.427-2.603 2.604-6.824 2.604-9.428 0-2.603-2.603-2.603-6.824 0-9.428 2.604-2.603 6.825-2.603 9.428 0M10 7.5v3.333M16.667 4.167L15 5.833M15.834 3.333L17.5 5"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0H20V20H0z" />
          </clipPath>
        </defs>
      </svg>
    ),
    6: (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
        <path
          d="M9.66638 20V12.8889H5V20H9.66638ZM9.66638 20H14.3328M9.66638 20V4H14.3328V20M14.3328 20H19V8.44444H14.3336L14.3328 20Z"
          stroke="#717171"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    7: (
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 64 64" fill="none">
        <path
          d="M40.0612 19.0774H33.735C33.1935 19.0774 32.6766 19.3974 32.4304 19.865L26.1289 32.1235L23.4212 20.2097C23.2735 19.545 22.6581 19.0527 21.9689 19.0527H14.6335C14.0919 19.0527 13.575 19.3727 13.3289 19.8404L1.66118 42.5112C1.14426 43.4958 1.8581 44.6773 2.96579 44.6773H9.26733C9.80887 44.6773 10.3258 44.382 10.5719 43.8897L16.7504 31.9266L19.335 43.545C19.4827 44.2097 20.0981 44.702 20.7873 44.702H28.3935C28.935 44.702 29.4519 44.4066 29.6981 43.9143L41.415 21.2435C41.9319 20.2589 41.2181 19.0774 40.1104 19.0774H40.0612Z"
          fill="black"
        />
        <path
          d="M45.8954 42.5367L40.0862 31.2383L35.2616 40.5675L36.9847 43.8906C37.2308 44.3829 37.7478 44.6783 38.2893 44.6783H44.5908C45.6985 44.6783 46.4124 43.4967 45.8954 42.5121V42.5367Z"
          fill="black"
        />
        <path
          d="M60.7874 28.2594H55.8643V23.3363C55.8643 22.524 55.1997 21.8594 54.3874 21.8594H50.2274C49.4151 21.8594 48.7505 22.524 48.7505 23.3363V28.2594H42.4243L46.092 35.3732H48.7505V40.2963C48.7505 41.1086 49.4151 41.7732 50.2274 41.7732H54.3874C55.1997 41.7732 55.8643 41.1086 55.8643 40.2963V35.3732H60.7874C61.5997 35.3732 62.2643 34.7086 62.2643 33.8963V29.7363C62.2643 28.924 61.5997 28.2594 60.7874 28.2594Z"
          fill="#C8102E"
        />
      </svg>
    ),
    8: (
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 64 64" fill="none">
        <path
          d="M61.7232 28.2589L53.994 19.5943C53.8463 19.422 53.674 19.2989 53.4771 19.2004C53.2802 19.102 53.0586 19.0527 52.8617 19.0527C52.6648 19.0527 52.4432 19.102 52.2463 19.2004C52.0494 19.2989 51.8771 19.422 51.7294 19.5943L44.0002 28.2589C43.8032 28.4804 43.6802 28.7512 43.6309 29.0466C43.5817 29.342 43.6309 29.6374 43.754 29.9081C43.8771 30.1789 44.074 30.4004 44.3202 30.5727C44.5663 30.745 44.8617 30.8189 45.1571 30.8189H48.6771V37.465C48.6771 37.8589 48.8494 38.2527 49.1202 38.5481C49.4155 38.8435 49.7848 38.9912 50.2032 38.9912H55.5694C55.9632 38.9912 56.3571 38.8189 56.6525 38.5481C56.9478 38.2527 57.0955 37.8835 57.0955 37.465V30.8189H60.6648C60.9602 30.8189 61.2309 30.7204 61.4771 30.5727C61.7232 30.4004 61.9202 30.1789 62.0186 29.9081C62.1417 29.6374 62.1663 29.342 62.1171 29.0466C62.0679 28.7512 61.9448 28.4804 61.7479 28.2589H61.7232Z"
          fill="#28A95B"
        />
        <path
          d="M40.1845 19.0774H33.8583C33.3168 19.0774 32.7999 19.3974 32.5537 19.865L26.2522 32.1235L23.5445 20.2097C23.3968 19.545 22.7814 19.0527 22.0922 19.0527H14.7568C14.2152 19.0527 13.6983 19.3727 13.4522 19.8404L1.78447 42.5112C1.26755 43.4958 1.98139 44.6773 3.08909 44.6773H9.39062C9.93216 44.6773 10.4491 44.382 10.6952 43.8897L16.8737 31.9266L19.4583 43.545C19.606 44.2097 20.2214 44.702 20.9106 44.702H28.5168C29.0583 44.702 29.5752 44.4066 29.8214 43.9143L41.5383 21.2435C42.0552 20.2589 41.3414 19.0774 40.2337 19.0774H40.1845Z"
          fill="black"
        />
        <path
          d="M46.0187 42.5367L40.2095 31.2383L35.3849 40.5675L37.108 43.8906C37.3541 44.3829 37.871 44.6783 38.4126 44.6783H44.7141C45.8218 44.6783 46.5357 43.4967 46.0187 42.5121V42.5367Z"
          fill="black"
        />
      </svg>
    ),
  };

  const tabLabels = {
    1: "Simulation",
    2: "Live-Statistics",
    3: "Live Goal",
    4: "Setup",
    5: "Liveticker",
    6: "Statistics",
  };

  const filteredData = useMemo(() => {
    const values = Object.keys(odds ?? []).reduce((acc, key) => {
      if (key.startsWith(match.id)) {
        acc.push(odds[key]);
      }
      return acc;
    }, []);
    return values;
  }, [match.id, odds]);

  const isMatchCombi = betBuilderMatches.some((e) => e.matchId === match.id && e.active);
  return (
    <div className=" pt-1 relative bg-oddColor md:rounded-md md:mr-3 w-screen md:pr-0 md:w-[400px]  text-white">
      <div className="bg-oddColor text-white">
        <div className="flex justify-between items-center mb-4 p-1 w-full border-b border-gray-600 md:border-b md:border-oddColor">
          <button
            onClick={() => {
              dispatch(showDetailHome(null));
            }}
            className="text-white md:hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>

          {/* Başlık */}
          <div className="text-center flex-1 ml-[10%] md:ml-4">
            <div className="font-bold text-sm">{match.tournamentName}</div>
          </div>

          <div className="flex justify-between md:block hidden ">
            <div className="grid grid-cols-1 text-center"></div>
            <div
              onClick={() => {
                dispatch(showDetailHome(null));
              }}
              className="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#e8eaed"
              >
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
              </svg>
            </div>
          </div>

          {/* Login veya Kullanıcı Bilgisi */}
          <div className="text-right md:hidden">
            {isAuthenticated ? (
              <div className="flex items-center space-x-4">
                <div className="relative">
                  <div
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="flex flex-col items-center cursor-pointer"
                  >
                    <FaRegUserCircle size={20} className="text-white" />
                    <div className="text-xs text-white mt-1">{balance} €</div>
                  </div>

                  {isDropdownOpen && (
                    <div className="absolute top-full right-0 mt-2 w-64 bg-white rounded-md shadow-lg z-50 p-4">
                      <div className="text-center font-semibold text-gray-800">{ids}</div>
                      <div className="flex items-center border-t border-gray-300 justify-between mt-4">
                        <div className="mt-3 flex">
                          <span className="text-gray-600 mr-7">Kontoguthaben</span>
                          <div className="flex items-center">
                            <button
                              onClick={() => setIsBalanceVisible(!isBalanceVisible)}
                              className="focus:outline-none"
                            >
                              {isBalanceVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                            </button>
                            <span className="ml-2 font-semibold text-gray-800">
                              {isBalanceVisible ? balance : "****"}
                            </span>
                          </div>
                        </div>
                      </div>

                      <button className="w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-2 rounded mt-4">
                        Einzahlung
                      </button>

                      <div className="mt-4 border-t pt-2 text-left">
                        <Link to="/userPage" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Mein Konto
                        </Link>
                        <Link to="/news" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Neuigkeiten
                        </Link>
                        <Link to="/payout" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Auszahlung
                        </Link>
                        <Link to="/help" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Hilfe
                        </Link>
                        <Link to="/settings" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Einstellungen
                        </Link>

                        <button onClick={handleLogout} className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <Link to="/login">
                <button className="text-xs w-14 h-8 border-2 p-1 rounded-md border-white text-white hover:bg-transparent">
                  Login
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* Alt Kısım: Sol, Orta, Sağ Bilgi */}
      <div className="flex justify-between items-center text-center mt-1 mb-2">
        {/* Sol Bilgi */}
        <div className="flex-1">
          <img src={shirt1} className="mx-auto mb-1" style={{ height: "30px" }} />
          <div className="text-xs font-bold">{match.home_team_name}</div>
        </div>

        {/* Orta Bilgi */}
        <div className="flex-1">
          <div className="text-lg text-gray-400 font-bold">{moment(match.match_time * 1000).format("hh:mm")}</div>
          <div className="text-xs text-gray-400 font-bold">{moment(match.match_time * 1000).format("dddd")}</div>
        </div>

        {/* Sağ Bilgi */}
        <div className="flex-1">
          <img src={shirt2} className="mx-auto mb-1" style={{ height: "30px" }} />
          <div className="text-xs font-bold">{match.away_team_name}</div>
        </div>
      </div>

      <div className="w-full h-[90vh] ">
        {/* Main Section */}
        <div className="w-full md:px-3 md:pt-2 h-[79vh] pb-4 overflow-auto">
          <div className="bg-custom">
            <ul className="flex flex-nowrap overflow-x-auto text-sm p-2 h-14 font-medium justify-center bg-white text-center text-black dark:text-gray-400">
              {[2, 3, 4, 5, 6].map((tab) => (
                <li className="me-2" key={tab}>
                  <button
                    onClick={() => setActiveTab(tab)}
                    className={`inline-block w-12 rounded-lg ${
                      activeTab === tab ? "text-gray-300" : "dark:hover:text-white"
                    }`}
                  >
                    <div className="flex flex-col items-center">
                      {tabIcons[tab]}
                      <div
                        className={`text-black text-nowrap ${activeTab === tab ? "text-lg" : "text-xxs text-gray-500"}`}
                      >
                        {activeTab === tab ? "▲" : tabLabels[tab]}
                      </div>
                    </div>
                  </button>
                </li>
              ))}
            </ul>

            {activeTab !== null && (
              <div className="flex items-center justify-center h-52 text-gray-700 bg-white p-4 shadow">
                {activeTab === 1
                  ? "Tab 1 içeriği"
                  : activeTab === 2
                  ? "Tab 2 içeriği"
                  : activeTab === 3
                  ? "Tab 3 içeriği"
                  : activeTab === 4
                  ? "Tab 4 içeriği"
                  : activeTab === 5
                  ? "Tab 5 içeriği"
                  : "Tab 6 içeriği"}
              </div>
            )}

            <div className="flex justify-center p-1 pb-5  mt-3 w-78 space-x-2">
              <button
                className={`px-1 pl-2 py-3 h-9 border-2 flex items-center justify-evenly flex-1 rounded-3xl text-customDetail font-semibold text-black ${
                  !isMatchCombi ? "bg-buttonbgdetail text-white" : "bg-white"
                }`}
                onClick={() => {
                  alleCombiChange(false, match.id, match.home_team_name + "-" + match.away_team_name);
                }}
              >
                Alle Wetten
              </button>

              <button
                className={`px-1 py-3 h-9 flex border-2 items-center  ${
                  isMatchCombi ? "bg-buttonbgdetail text-white" : " bg-white text-black"
                }  rounded-3xl text-customDetail font-semibold `}
                onClick={() => {
                  alleCombiChange(true, match.id, match.home_team_name + "-" + match.away_team_name);
                }}
              >
                <span className="mr-1">{tabIcons[7]}</span>
                MatchKombi+
                <span className="mr-2 px-1 bg-red-700 text-white font-semibold text-xxs p-1 rounded-xl ">Neu</span>
              </button>
              <button className="px-1 py-3 h-9 flex border-2 items-center bg-white rounded-3xl text-customDetail font-semibold text-black hover:bg-gray-100">
                <span className="mr-1">{tabIcons[8]}</span>
                Top-MatchKombis
              </button>
            </div>
          </div>

          <div className="flex flex-wrap w-full justify-between pt-2 shadow-md px-3 bg-white text-gray-500">
            {filteredData.map((e, index) => {
              const groupedData = e.reduce((acc, k) => {
                if (!acc[k.line]) {
                  acc[k.line] = [];
                }
                acc[k.line].push({
                  value: k.value,
                  name: k.name,
                  id: k.id,
                  line: k.line,
                  market_name: k.market_name,
                  outcome_id: k.outcome_id,
                  market_id: k.market_id,
                  status: k.status,
                });
                return acc;
              }, {});

              return (
                <div key={index} className="w-full">
                  <div className="font-bold text-left text-detailText text-sm mt-5 mb-3">{e[0].market_name}</div>
                  <div className="flex flex-wrap gap-2">
                    {Object.keys(groupedData).map((line, indexLine) => (
                      <div key={indexLine} className="flex w-full mb-3">
                        {line ? <span className="text-xs text-detailText text-left w-1/4">{line}</span> : null}
                        <div className={`flex flex-wrap ${line ? "w-3/4" : "w-full"} gap-2 justify-end`}>
                          {groupedData[line].map((value, indexValue) => (
                            <OddButton key={indexValue} match={match} odd={value} />
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  <hr className="border-1 border-gray-200 " />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const OddButton = memo(({ match, odd }) => {
  const [isSelected, setIsSelected] = useState(false);
  const errors = useSelector((state) => state?.calculateBetSlip?.errors);
  const oddsList = useSelector((state) => state.homereducer.odds);
  const isHaveError =
    errors.includes(match.id) && oddsList.some((e) => e.id == match.id + "-" + odd.market_id + "-" + odd.outcome_id);

  const handleOdd = useHandleOddsClick();
  const nonCombineMarkets = useSelector((state) => state?.calculateBetSlip?.nonCombineMarkets);

  const isAvailable = nonCombineMarkets.includes(match.id + "-" + odd.market_id + "-" + odd.outcome_id);

  const bets = useSelector((state) => state?.homereducer.odds);

  useEffect(() => {
    const bet = bets.filter((e) => e.id === match.id + "-" + odd.market_id + "-" + odd.outcome_id);
    setIsSelected(bet !== null && bet.length !== 0);
  }, [bets]);

  if (odd.status !== 0) {
    return (
      <div className="flex justify-center h-[32px] w-[75px] md:h-8 bg-newButton md:bg-buttonbg rounded-custom items-center border md:shadow-md border-gray-300 py-2 cursor-not-allowed col-span-1">
        <span className="text-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15">
            <g fill="none" fillRule="evenodd" transform="translate(0 1)">
              <rect width="11" height="9" y="5" fill="#BBB" rx="1" />
              <path
                stroke="#BBB"
                strokeWidth="1.5"
                d="M3 6.106c-.017 0 4.543.003 5 0v-3.66C8 1.094 6.88 0 5.5 0S3 1.095 3 2.445v3.66z"
              />
            </g>
          </svg>
        </span>
      </div>
    );
  }

  return (
    <div
      onClick={() => {
        handleOdd({ match: match, odd: odd, isSelected: isSelected });
      }}
      className={`${isHaveError && "bg-red-500"} ${isAvailable ? "pointer-events-none bg-gray-400 opacity-50 " : ""} ${
        isSelected ? "bg-focusBg" : "bg-buttonbg"
      } flex relative items-center border border-gray-300 rounded h-8 py-1 text-detailText px-1 cursor-pointer w-[30%] justify-between`}
    >
      <span className={`text-gray-700 text-xxs p-1 ${isSelected ? "text-white" : "text-black"} `}>{odd.name}</span>
      <span className={` gap-2 text-xs font-semibold ${isSelected ? "text-white" : "text-black"} text-center`}>
        {odd.value}
      </span>
    </div>
  );
});
