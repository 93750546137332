import { useEffect, useState } from "react";
import "./rightSidebar.css";
import { bet_guide, carccashout, cashout, favorite } from "../../assets/images";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TopWinnerApi } from "../../store/actions/topWinners";
import { BetSlip } from "./BetSlip";

export function RightSidebar() {
  return (
    <>
      <div className="bg-custom sticky top-12 hidden md:block md:w-[18%] lg:w-[18%] h-[95vh] p-12 overflow-auto px-3 py-6 shadow-sm ">
        <BetSlip />
        <TopWinners />
        <Features />
      </div>
    </>
  );
}

const TopWinners = () => {
  const dispatch = useDispatch();
  const [winnerTab, setWinnerTab] = useState(0);
  const [winner, setWinner] = useState([]);
  const allWinner = useSelector((state) => state.topWinner);

  useEffect(() => {
    dispatch(TopWinnerApi());
  }, [dispatch]);

  const changeWinnerTab = (winnerTab) => {
    setWinnerTab(winnerTab);
  };

  useEffect(() => {
    if (allWinner?.data?.data) setWinner(allWinner?.data?.data[winnerTab]);
  }, [winnerTab]);

  return (
    <div className="w-full ">
      {/* main tabs  */}
      <div className="flex justify-start py-4 px-4 font-semibold">TOP WINNERS</div>
      <div className="shadow-topCombisShadow">
        <div className="flex items-center w-full h-12 bg-white shadow-custom rounded-t-md">
          <div
            className={`flex items-center cursor-pointer justify-center w-1/2 h-full  border-b-2 ${
              winnerTab === 0 ? "border-b-2  border-red-700" : "border-b-2  border-gray-50"
            } text-center`}
            onClick={() => {
              changeWinnerTab(0);
            }}
          >
            <div className="flex ">
              <span className="font-semibold text-xs">1 day</span>
            </div>
          </div>
          <div
            className={`flex items-center cursor-pointer justify-center w-1/2 h-full text-center ${
              winnerTab === 1 ? "border-b-2  border-red-700" : "border-b-2  border-gray-50"
            } `}
            onClick={() => {
              changeWinnerTab(1);
            }}
          >
            <div className="flex">
              <span className="font-semibold text-xs"> 1 week</span>
            </div>
          </div>
          <div
            className={`flex items-center cursor-pointer justify-center w-1/2 h-full text-center ${
              winnerTab === 2 ? "border-b-2  border-red-700" : "border-b-2  border-gray-50"
            } `}
            onClick={() => {
              changeWinnerTab(2);
            }}
          >
            <div className="flex ">
              <span className="font-semibold text-xs">1 month</span>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center shadow-custom  px-4 w-full h-12 bg-gray-100 text-gray-400">
          <span className="pl-4 ">Win</span>
          <span>Use</span>
        </div>
        {/* tabs div  */}
        <div className="flex flex-col  justify-between  w-full shadow-custom  bg-white">
          {Array.isArray(winner) && winner.length > 0 ? (
            winner.map((data, index) => {
              return (
                <div key={index} className="flex flex-col  w-full justify-center items-center ">
                  <Accordion slotProps={{ heading: { component: "h4" } }} className="">
                    <AccordionSummary
                      expandIcon={
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="#e8eaed"
                          >
                            <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
                          </svg>
                        </>
                      }
                      aria-controls=""
                      id="panel1-header"
                      className="hover:bg-gray-300 "
                    >
                      <div className="flex justify-between w-full items-center">
                        <div className="flex gap-3">
                          <span className="text-gray-400">{index + 1}.</span>
                          <span className="font-semibold text-sm">{data.totalPayout.toFixed(2)} €</span>
                        </div>

                        <span className="font-semibold text-sm text-gray-400">{data.originCurStake.toFixed(2)} €</span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-gray-200">
                      <div className="flex  ">
                        <div className="flex flex-col">
                          {[1, 2, 3, 4, 5].map((index) => {
                            return (
                              <div key={index} className="flex flex-col pl-3 border-b py-1 border-b-gray-200">
                                <span className="text-sm font-semibold">
                                  Rephaaol Gerrerio meets and Bayern Munich winds : Yes
                                </span>
                                <span className="text-xs font-extralight text-gray-500">
                                  Bayern Munich - Dinamo Zagreb
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })
          ) : (
            <div>
              <div className="shadow rounded-md p-4 max-w-sm w-64 mx-auto">
                <div className="animate-pulse flex space-x-4">
                  <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-200 rounded"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                      </div>
                      <div className="h-2 bg-slate-200 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const list = [
  {
    name: "Bet Guide",
    image: bet_guide,
  },
  {
    name: "Car ccashout",
    image: carccashout,
  },
  {
    name: "Favorite",
    image: favorite,
  },
  {
    name: "Cashout",
    image: cashout,
  },
];

const Features = () => {
  return (
    <div className="w-full ">
      {/* main tabs  */}
      <div className="flex justify-start py-4 px-4 font-semibold">FEATURES</div>

      {/* tabs div  */}
      <div className="flex flex-col gap-2 justify-between  w-full p-3 bg-white">
        <div>
          {list.map((item, index) => {
            return (
              <div key={index} className="flex  w-full justify-start p-1 gap-2 items-center border-b border-gray-200">
                <img src={item.image} alt="f" className="w-10 h-10" />
                <div className="flex flex-col">
                  <span className="text-sm font-semibold">{item.name}</span>
                  <span className="text-xs text-gray-500">
                    Learn the basics of sports betting in just a few minutes.
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <Link to="/bet-guide" className="logo">
          <div className="flex items-center justify-end gap-1 py-1 cursor-pointer" onClick={() => {}}>
            <span className="text-gray-400 font-semibold">Show all</span>
            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#9ca3af">
              <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
            </svg>
          </div>
        </Link>
      </div>
    </div>
  );
};
