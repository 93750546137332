import React from 'react';
import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer';
import { RightSidebar } from '../../components/Sidebar/RightSidebar';
import { BottomAppbar } from '../../components/BottomAppbar';
import BetGuideSidebar from '../../components/BetGuide/BetGuideSidebar';
import Payout from '../pages/user-page/payout';
import BetsPage from '../pages/user-page/userWetten';
import { MobilDynamicMenus } from '../pages/default/mobilDynamicMenu';




const SportLayout = ({ }) => {
    return (
        <div className="flex flex-col min-h-screen font-Source-Code-Pro ">
            <div className="flex flex-grow md:mt-14 h-[100vh]">
                <main className="flex-1 overflow-x-auto bg-custom ">
                    <MobilDynamicMenus/>
                </main>
            </div>
            <BottomAppbar />
            <Footer />
        </div>
    );
};

export default SportLayout;
