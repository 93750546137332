import React from 'react';

const AuthFooter = () => {
  return (
   <div className='w-full bg-gray-50'>
     <footer className="px-12 md:px-24  h-[200px] flex flex-col justify-center items-center text-gray-700">
      <div className="w-full max-w-7xl flex justify-between items-center px-6">
        {/* Left Section: Help and Support */}
        <div>
          <p>Help and support</p>
        </div>

        {/* Right Section: Language Selector */}
        <div className="flex items-center space-x-2">
          <div className="relative">
            <select className="bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm focus:ring-indigo-500 focus:border-indigo-500">
              <option value="en">English</option>
              <option value="gm">Germen</option>
      
            </select>
          </div>
        </div>
      </div>
      <hr className="bg-black w-full my-5 h-[1.5px] " />
      {/* Bottom Section: Version and Timestamp */}
      <div className="w-full max-w-7xl flex justify-between items-center px-6  mt-4">
        <p className="text-sm">V 3.122.21 m33</p>
        <p className="text-sm">11.09.2024 02:58</p>
      </div>
    </footer>
   </div>
  );
};

export default AuthFooter;
