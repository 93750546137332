import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Import default styles
import styles from './PhoneInput.module.css'; // Import custom styles

const CustomPhoneInput = ({ value, onChange }) => {
  return (
    <PhoneInput
      placeholder="Enter phone number"
      value={value}
      defaultCountry="DE"
      onChange={onChange} // Call onChange with updated value
      className={styles.container} // Apply container styles
      numberInputProps={{
        // required: true,
        className: styles.input, // Apply custom styles to the input
      }}
      buttonclassname={styles.button} // Apply country select button styles
      dropdownclassname={styles.dropdown} // Apply dropdown styles
      flagclassname={styles.flag} // Apply flag icon styles
    />
  );
};

export default CustomPhoneInput;