import React from "react";
import Header from "../../components/Headers/Header";
import Footer from "../../components/Footer";
import { RightSidebar } from "../../components/Sidebar/RightSidebar";
import { BottomAppbar } from "../../components/BottomAppbar";
import BetsPage from "../pages/user-page/userWetten";

const MyBetsLayout = ({}) => {
  return (
    <div className="flex flex-col min-h-screen font-Source-Code-Pro ">
      <Header />
      <div className="flex flex-grow md:mt-14">
        <main className="flex-1 overflow-x-auto bg-custom ">
          <BetsPage />
        </main>
        <RightSidebar />
      </div>
      <BottomAppbar />
      <Footer />
    </div>
  );
};

export default MyBetsLayout;
