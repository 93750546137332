import { useSelector } from "react-redux";

export const LMOddsNameGroup = ({ selectedOddsIds, showDetail }) => {
  const availableOdds = useSelector((state) => state?.lastmin?.availableOddsMap);
  return (
    // <div className={"col-span-8 grid grid-cols-12"}>
    <div className={`col-span-11 grid grid-cols-${selectedOddsIds.length} gap-2 font-semibold text-xs text-gray-40`}>
      {selectedOddsIds.map((oddId, index) => {
        if (availableOdds) {
          return null;
        }
        const availableOdd = availableOdds[oddId] || [];
        const captionsLength = availableOdd?.captions?.length;
        const count = captionsLength + (availableOdd?.isHaveLine ? 1 : 0);

        return (
          <div key={index} className={`col-span-1 grid grid-cols-${count} gap-1`}>
            {availableOdd?.isHaveLine && (
              <div className="text-xs font-normal min-w-5 items-center flex col-span-1 justify-center text-right"></div>
            )}
            {availableOdd?.captions?.map((caption, idx) => (
              <div key={idx} className="flex min-w-5 text-right justify-center items-center col-span-1">
                <span
                  className={` text-xs font-semibold flex justify-center text-gray-400 items-center rounded-md py-2 w-8`}
                >
                  {caption}
                </span>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};
