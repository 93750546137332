import React, { useState } from "react";

// Collapsible Section Component
const CollapsibleSection = ({ title, amount, description }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="border-b border-gray-300 py-2">
            <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                <h2 className="text-sm text-gray-700 font-semibold">{title}</h2>
                <div className="flex items-center">
                    <p className="text-sm text-gray-700 mr-2">{amount}</p>
                    <span className={`transform transition-transform ${isOpen ? 'rotate-180' : ''}`}>&#9650;</span>
                </div>
            </div>
            {isOpen && (
                <div className="bg-gray-100 p-4 mt-2 text-gray-600 text-sm rounded-md">
                    {description}
                </div>
            )}
        </div>
    );
};

// Main Component with Tabs and Sections
export const Guthaben = () => {
    const [activeTab, setActiveTab] = useState("userData");
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    return (
        <div className="flex flex-col items-center w-full max-w-5xl mx-auto md:p-6">
            {/* Başlık */}
            <h2 className="w-full text-lg font-semibold text-gray-700 mb-4 text-left hidden md:block">Meine Daten</h2>

            {/* İçerik Kartı */}
            <div className="w-full bg-white rounded-lg shadow-lg p-6">
                {/* Tab Menüsü */}
                <div className="flex border-b border-gray-200 mb-6">
                    <button
                        onClick={() => handleTabClick("userData")}
                        className={`pb-4 px-4 text-xs font-semibold ${activeTab === "userData" ? "border-b-2 border-red-500 " : "text-gray-600"}`}
                    >
                        Sport
                    </button>

                </div>

                {/* Tab İçerikleri */}
                <div className={` ${activeTab === "userData" ? "block" : "hidden"}`}>
                    <div className="flex justify-center w-full md:p-4 md:p-8">


                        <div className="w-full space-y-6 md:p-4 md:p-8 ">
                            <CollapsibleSection
                                title="Guthaben Sport"
                                amount="0,00 €"
                                description="Dieser Betrag kann beim Sportwetten eingesetzt werden."
                            />
                            <CollapsibleSection
                                title="Auszahlbar"
                                amount="0,00 €"
                                description="Betrag, der zur Auszahlung zur Verfügung steht."
                            />
                            <CollapsibleSection
                                title="Auszahlbar nur in Tipico Shops"
                                amount="0,00 €"
                                description="Betrag, der nur in Tipico Shops ausgezahlt werden kann."
                            />
                            <CollapsibleSection
                                title="Einzahlungsumsatz"
                                amount="0,00 €"
                                description="Der Betrag, der eingesetzt werden muss, bevor er ausgezahlt werden kann. Einzahlungen müssen mindestens einmal umgesetzt werden, bevor du sie auszahlen kannst."
                            />
                            <CollapsibleSection
                                title="Bonus-Guthaben Sport"
                                amount="0,00 €"
                                description="Bonus-Guthaben, das für Sportwetten verwendet werden kann."
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Guthaben;
