import { useDispatch, useSelector } from "react-redux";
import { changeBetBuilder, showPopup } from "../../store/actions/calculateBetSlipAction";
import { clearOdds } from "../../store/actions/homeTypes";

export const PopupMatchCombi = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state?.calculateBetSlip?.showPopup);

  if (!show || !show.isShowing) {
    return null;
  }

  const closePopup = () => {
    dispatch(showPopup(false, null));
  };

  return (
    <div className="fixed inset-0 bg-black z-50 bg-opacity-25 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-lg font-semibold text-center mb-2">MatchKombi+ aktivieren</h2>
        <p className="text-gray-600 text-bold text-sm text-center mb-6">
          Wenn du eine MatchKombi erstellen möchtest, musst du Einzelwetten aus demselben Event entfernen.
        </p>
        <div className="flex flex-col items-center gap-4">
          {!show.active ? (
            <button  className="bg-combisColor text-white py-2 px-6 h-12 rounded hover:bg-combisColor w-full text-center font-semibold"
              onClick={() => {
                dispatch(changeBetBuilder(show.matchId, show.name, show.active));
                // dispatch(clearOdds());
                closePopup();
              }}
            >
              Keep MatchKombi
            </button>
          ) : (
            <button
              className="bg-combisColor text-white py-2 px-6 h-12 rounded hover:bg-combisColor w-full text-center font-semibold"
              onClick={() => {
                dispatch(changeBetBuilder(show.matchId, show.name, show.active));
                dispatch(clearOdds());
                closePopup();
              }}
            >
              Yes, activate MatchKombi+
            </button>
          )}
          {!show.active ? (
            <button className="text-combisColor py-2 px-6 rounded font-semibold w-full text-center hover:text-combisColor"
              onClick={() => {
                dispatch(clearOdds());
                dispatch(changeBetBuilder(show.matchId, show.name, show.active));
                closePopup();
              }}
            >
              Remove MatchKombi
            </button>
          ) : (
            <button
              className="text-combisColor py-2 px-6 rounded font-semibold w-full text-center hover:text-combisColor"
              onClick={closePopup}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
