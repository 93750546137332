import React, { useMemo, useEffect, useState, useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  detail1,
  detail2,
  detail3,
  detail4,
  detail5,
  detail6,
  detail7,
  detail8,
  shirt1,
  shirt2,
} from "../../../assets/images/index";
import "../../../app/pages/user-page/userPage.css";
import { useHandleOddsClick } from "../../utility/handleOdd";
import { changeBetBuilder, showPopup } from "../../../store/actions/calculateBetSlipAction";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../store/actions/authActions";
import { showDetailHome } from "../../../store/actions/homeTypes";
import { FaRegEye, FaRegEyeSlash, FaRegUserCircle } from "react-icons/fa";
import { LockButton } from "../../Button/LockButton";

export const DetailCard2 = ({ data, hndleDetail }) => {
  const odds = useSelector((state) => state?.livematches?.odds);

  const [bgColors, setBgColors] = useState({});
  const [previousValues, setPreviousValues] = useState({});

  const availableOdds = useSelector((state) => state?.livematches?.availableOddsMap);

  const betBuilderMatches = useSelector((state) => state?.calculateBetSlip?.betBuilderMatches);

  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBalanceVisible, setIsBalanceVisible] = useState(true);
  const navigate = useNavigate();
  const storedUserData = JSON.parse(localStorage.getItem("authToken"));
  const userName = storedUserData?.user?.user?.name || "User Name";

  const [balance, setBalance] = useState(() => {
    const savedBalance = localStorage.getItem("balance");
    return savedBalance ? JSON.parse(savedBalance) : 0;
  });

  useEffect(() => {
    const getBalance = async () => {
      try {
        const storedUserData = JSON.parse(localStorage.getItem("authToken"));
        const token = storedUserData?.user?.token;
        if (!token) {
          return;
        }

        const response = await fetch("http://38.180.122.61:3000/api/user/get-balance", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          dispatch(logout());
          return;
        }

        const data = await response.json();
        setBalance(data);
        localStorage.setItem("balance", JSON.stringify(data));
      } catch (error) {
        console.error("Veri çekilirken hata oluştu:", error.message);
      }
    };

    if (userName) {
      const timeoutId = setInterval(getBalance, 2000);
      return () => clearInterval(timeoutId);
    }
  }, [userName]);

  const useAuth = () => {
    const authToken = localStorage.getItem("authToken");
    return authToken ? JSON.parse(authToken) : { isAuthenticated: false };
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const { isAuthenticated } = useAuth();
  const ids = storedUserData?.user?.user?.id;
  const showDetail = useSelector((state) => state?.homereducer?.showDetail);
  const oddList = useSelector((state) => state?.homereducer?.odds);
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const tabIcons = {
    1: detail1,
    2: detail2,
    3: detail3,
    4: detail4,
    5: detail5,
    6: detail6,
    7: detail7,
    8: detail8,
  };

  useEffect(() => {
    const selectedOddsIds = Object.keys(availableOdds); // Assuming you have available odds to monitor
    const newValues = selectedOddsIds.reduce((acc, oddId) => {
      const oddData = Object.values(odds[data.match.id + "_" + oddId] ?? []);
      oddData.forEach((odd) => {
        if (odd.id !== undefined) acc[odd.id] = odd.value;
      });
      return acc;
    }, {});

    Object.keys(newValues).forEach((id) => {
      const oldValue = previousValues[id];
      const newValue = newValues[id];
      if (oldValue === undefined || oldValue === newValue) {
        return;
      }
      handleDataChange(id, oldValue > newValue ? "text-red-500" : "text-green-500");
    });

    setPreviousValues(newValues);
  }, [odds, availableOdds, data.match.id]);

  const alleCombiChange = (active, matchId, name) => {
    if (oddList && oddList.length > 0) {
      dispatch(showPopup(active, matchId));
    } else {
      dispatch(changeBetBuilder(matchId, name, active));
    }
  };
  const handleDataChange = useCallback((id, color) => {
    setBgColors((prev) => ({ ...prev, [id]: color }));
    setTimeout(() => {
      setBgColors((prev) => ({ ...prev, [id]: undefined }));
    }, 3000);
  }, []);

  const filteredData = useMemo(() => {
    const values = Object.keys(odds).reduce((acc, key) => {
      if (key.startsWith(data.match.id)) {
        acc.push(odds[key]);
      }
      return acc;
    }, []);
    return values;
  }, [data.match.id, odds]);

  const isMatchCombi = betBuilderMatches.some((e) => e.matchId === data.match.id && e.active);

  return (
    <div className=" pt-1 relative bg-oddColor md:rounded-md md:mr-3  md:pr-0 md:w-[400px]  text-white">
      <div className="bg-oddColor text-white">
        <div className="flex justify-between items-center md:mb-0 mb-4 p-1 w-full border-b border-gray-600 md:border-b md:border-oddColor">
          <button
            onClick={() => {
              hndleDetail(null);
            }}
            className="text-white md:hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>

          {/* Başlık */}
          <div className="text-center flex-1 ml-[10%] md:ml-4">
            <div className="font-bold text-sm">{data.match.tournamentName}</div>
          </div>

          <div className="flex justify-between md:block hidden ">
            <div className="grid grid-cols-1 text-center"></div>
            <div
              onClick={() => {
                hndleDetail(null);
              }}
              className="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#e8eaed"
              >
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
              </svg>
            </div>
          </div>
          {/* Login veya Kullanıcı Bilgisi */}
          <div className="text-right md:hidden">
            {isAuthenticated ? (
              <div className="flex items-center space-x-4">
                <div className="relative">
                  <div
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="flex flex-col items-center cursor-pointer"
                  >
                    <FaRegUserCircle size={20} className="text-white" />
                    <div className="text-xs text-white mt-1">{balance} €</div>
                  </div>

                  {isDropdownOpen && (
                    <div className="absolute top-full right-0 mt-2 w-64 bg-white rounded-md shadow-lg z-50 p-4">
                      <div className="text-center font-semibold text-gray-800">{ids}</div>
                      <div className="flex items-center border-t border-gray-300 justify-between mt-4">
                        <div className="mt-3 flex">
                          <span className="text-gray-600 mr-7">Kontoguthaben</span>
                          <div className="flex items-center">
                            <button
                              onClick={() => setIsBalanceVisible(!isBalanceVisible)}
                              className="focus:outline-none"
                            >
                              {isBalanceVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                            </button>
                            <span className="ml-2 font-semibold text-gray-800">
                              {isBalanceVisible ? balance : "****"}
                            </span>
                          </div>
                        </div>
                      </div>

                      <button className="w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-2 rounded mt-4">
                        Einzahlung
                      </button>

                      <div className="mt-4 border-t pt-2 text-left">
                        <Link to="/userPage" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Mein Konto
                        </Link>
                        <Link to="/news" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Neuigkeiten
                        </Link>
                        <Link to="/payout" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Auszahlung
                        </Link>
                        <Link to="/help" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Hilfe
                        </Link>
                        <Link to="/settings" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Einstellungen
                        </Link>

                        <button onClick={handleLogout} className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <Link to="/login">
                <button className="text-xs w-14 h-8 border-2 p-1 rounded-md border-white text-white hover:bg-transparent">
                  Login
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className="w-full  h-full ">
        {/* Top Section */}

        {data.match.sport_id === 1 && (
          <div data-testid="event-info-header" className="Event-styles-module-event-info-header-desktop-margins">
            <section className="SoccerLive-styles-module-content">
              <div className="SoccerLive-styles-module-content-wrapper">
                <div className="SoccerLive-styles-module-team">
                  <div className="SoccerLive-styles-module-team-data">
                    <img className="text-center item-center" src={shirt1} alt="" width={30} height={30} />
                    <div className="SoccerLive-styles-module-team-name">{data.match.home_team_name}</div>
                  </div>
                  <div className="SoccerLive-styles-module-team-cards"></div>
                </div>
                <div className="SoccerLive-styles-module-team-score">
                  <span className="SoccerLive-styles-module-square text-black">
                    {data.match.scores_map[0][1].scoreList[0]}
                  </span>
                  <span className="SoccerLive-styles-module-separator">:</span>
                  <span className="SoccerLive-styles-module-square text-black">
                    {data.match.scores_map[0][1].scoreList[1]}
                  </span>
                </div>
                <div className="SoccerLive-styles-module-team SoccerLive-styles-module-team-right">
                  <div className="SoccerLive-styles-module-team-cards SoccerLive-styles-module-right"></div>
                  <div className="SoccerLive-styles-module-team-data">
                    <img src={shirt2} alt="" width={30} height={30} />
                    <div className="SoccerLive-styles-module-team-name">{data.match.away_team_name}</div>
                  </div>
                </div>
              </div>
              <div className="SoccerLive-styles-module-info">
                <div className="SoccerLive-styles-module-time">
                  <span className="SoccerLive-styles-module-clock">
                    <span>{data.time}</span>
                  </span>
                </div>
              </div>
            </section>
          </div>
        )}
        {data.match.sport_id === 2 && (
          <div data-testid="event-info-header" className="Event-styles-module-event-info-header-desktop-margins">
            <section className="flex BasketballLive-styles-module-scorecenter md:p-4">
              <div className="BasketballLive-styles-module-teams">
                <div className="BasketballLive-styles-module-date-time">
                  <span>{data.time}</span>&nbsp;
                </div>
                <div className="BasketballLive-styles-module-team">
                  <div className="BasketballLive-styles-module-name">{data.match.home_team_name}</div>
                </div>
                <div className="BasketballLive-styles-module-team">
                  <div className="BasketballLive-styles-module-name">{data.match.away_team_name}</div>
                </div>
              </div>
              <div className="BasketballLive-styles-module-score">
                <ScoreMapBasketball scoreMap={data.match.scores_map} />
              </div>
            </section>
          </div>
        )}
        {data.match.sport_id >= 3 && data.match.sport_id <= 10 && (
          <div data-testid="event-info-header" className="Event-styles-module-event-info-header-desktop-margins">
            <section>
              <div className="TennisLive-styles-module-scorecenter p-2">
                <div className="TennisLive-styles-module-teams">
                  <div className="TennisLive-styles-module-date-time">{data.time}</div>
                  <div className="TennisLive-styles-module-team">
                    <div className="TennisLive-styles-module-name">{data.match.home_team_name}</div>
                  </div>
                  <div className="TennisLive-styles-module-team">
                    <div className="TennisLive-styles-module-name">{data.match.away_team_name}</div>
                  </div>
                </div>
                <div className="TennisLive-styles-module-score">
                  <ScoreMapTennis scoreMap={data.match.scores_map} />
                </div>
              </div>
            </section>
          </div>
        )}

        {/* Main Section */}
        <div className="w-full md:px-3 md:pt-2 h-[75vh] md:pb-4 overflow-auto">
          <div className="bg-custom">
            {data.match.live_tracker_mapping?.tid && (
              <iframe
                src={
                  "https://widgets-v2.thesports01.com/tr/pro/football?profile=mo4h9z8gl8dcluy&uuid=" +
                  data.match.live_tracker_mapping.tid
                }
                style={{ width: "100%", height: "256px" }}
                className="overflow-hidden"
              ></iframe>
            )}

            <div className="flex justify-center p-1 pb-5  mt-3 w-78 space-x-2">
              <button
                onClick={() => {
                  alleCombiChange(false, data.match.id, data.match.home_team_name + "-" + data.match.away_team_name);
                }}
                className={`px-1 pl-2 py-3 h-9 border-2 flex items-center justify-evenly flex-1 rounded-3xl text-customDetail font-semibold text-black ${
                  !isMatchCombi ? "bg-buttonbgdetail text-white" : "bg-white"
                }`}
              >
                Alle Wetten
              </button>

              <button
                className={`px-1 py-3 h-9 flex border-2 items-center  ${
                  isMatchCombi ? "bg-buttonbgdetail text-white" : " bg-white text-black"
                }  rounded-3xl text-customDetail font-semibold hover:bg-gray-100 hover:text-black`}
                onClick={() => {
                  alleCombiChange(true, data.match.id, data.match.home_team_name + "-" + data.match.away_team_name);
                }}
              >
                <span className="mr-1">
                  <img src={tabIcons[7]} alt={7}></img>
                </span>
                MatchKombi+
                <span className="mr-2 px-1 bg-red-700 text-white font-semibold text-xxs p-1 rounded-xl ">Neu</span>
              </button>
              <button className="px-1 py-3 h-9 flex border-2 items-center bg-white rounded-3xl text-customDetail font-semibold text-black hover:bg-gray-100">
                <span className="mr-1">
                  <img src={tabIcons[8]} alt={8}></img>
                </span>
                Top-MatchKombis
              </button>
            </div>
          </div>

          <div className="flex flex-wrap w-full justify-between pt-2 shadow-md px-3 bg-white text-gray-500">
            {filteredData.map((e) => {
              const groupedData = e.reduce((acc, k) => {
                if (!acc[k.line]) {
                  acc[k.line] = [];
                }
                acc[k.line].push({
                  value: k.value,
                  name: k.name,
                  id: k.id,
                  line: k.line,
                  market_name: k.market_name,
                  outcome_id: k.outcome_id,
                  market_id: k.market_id,
                  status: k.status,
                });
                return acc;
              }, {});
              return (
                <div key={e.id} className="w-full">
                  <div className="font-bold text-left text-detailText text-sm mt-5 mb-3">{e[0].market_name}</div>
                  <div className="flex flex-wrap gap-2">
                    {Object.keys(groupedData).map((line) => (
                      <div key={line} className="flex w-full mb-3">
                        {line ? <span className="text-xs text-detailText text-left w-1/4">{line}</span> : null}
                        <div className={`flex flex-wrap ${line ? "w-3/4" : "w-full"} gap-2 justify-end`}>
                          {groupedData[line].map((value) => (
                            <OddButton key={value.id} value={value} data={data} bgColors={bgColors} />
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  <hr className="border-1 border-gray-200" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const OddButton = memo(({ data, value, bgColors }) => {
  const [isSelected, setIsSelected] = useState(false);
  const handleOdd = useHandleOddsClick();
  const errors = useSelector((state) => state?.calculateBetSlip?.errors);
  const oddsList = useSelector((state) => state.homereducer.odds);
  const isHaveError =
    errors.includes(data.match.id) &&
    oddsList.some((e) => e.id == data.match.id + "-" + value.market_id + "-" + value.outcome_id);
  const nonCombineMarkets = useSelector((state) => state?.calculateBetSlip?.nonCombineMarkets);

  const isAvailable = nonCombineMarkets.includes(data.match.id + "-" + value.market_id + "-" + value.outcome_id);
  const bets = useSelector((state) => state?.homereducer.odds);

  useEffect(() => {
    const bet = bets.filter((e) => e.id === data.match.id + "-" + value.market_id + "-" + value.outcome_id);
    setIsSelected(bet !== null && bet.length !== 0);
  }, [bets]);
  if (value.status !== 0) {
    return (
      <div className="flex justify-center h-[32px] w-[75px] md:h-8 bg-newButton md:bg-buttonbg rounded-custom items-center border md:shadow-md border-gray-300 py-2 cursor-not-allowed col-span-1">
        <span className="text-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15">
            <g fill="none" fillRule="evenodd" transform="translate(0 1)">
              <rect width="11" height="9" y="5" fill="#BBB" rx="1" />
              <path
                stroke="#BBB"
                strokeWidth="1.5"
                d="M3 6.106c-.017 0 4.543.003 5 0v-3.66C8 1.094 6.88 0 5.5 0S3 1.095 3 2.445v3.66z"
              />
            </g>
          </svg>
        </span>
      </div>
    );
  }

  return (
    <div
      onClick={() => {
        handleOdd({ match: data.match, odd: value, isSelected });
      }}
      className={`${isHaveError && "bg-red-500"} flex relative items-center border ${
        isAvailable ? "pointer-events-none bg-gray-400 opacity-50" : ""
      } ${
        isSelected ? "bg-focusBg" : "bg-buttonbg"
      } border-gray-300 rounded h-8 py-1 text-detailText px-1 cursor-pointer w-[30%] justify-between`}
    >
      <span className={`text-gray-700 text-xxs p-1 ${isSelected ? "text-white" : "text-black"} `}>{value.name}</span>
      <span
        className={` ${isSelected ? "text-white" : "text-black"} gap-2 text-xs font-semibold text-center ${
          bgColors[value.id]
        }`}
      >
        {value.value}
      </span>
      {bgColors[value.id] === "text-green-500" && (
        <span
          className={`absolute text-gray-700 text-l ${bgColors[value.id]}`}
          style={{
            right: "-2px",
            top: "15%",
            transform: "translateY(-50%)",
          }}
        >
          ◥
        </span>
      )}
      {bgColors[value.id] === "text-red-500" && (
        <span
          className={`absolute text-gray-700 text-l ${bgColors[value.id]}`}
          style={{
            right: "-2px",
            top: "81%",
            transform: "translateY(-50%)",
          }}
        >
          ◢
        </span>
      )}
    </div>
  );
});

const ScoreMapBasketball = ({ scoreMap }) => {
  if (!scoreMap) {
    return null;
  }
  const pScores = scoreMap.filter(([key]) => key.startsWith("p"));
  const ftScores = scoreMap.filter(([key]) => key.startsWith("ft"));
  const scoreSums = pScores.map((_, i) => {
    let sumHome = 0;
    let sumAway = 0;

    for (let j = 0; j <= i; j++) {
      sumHome += pScores[j][1].scoreList[0];
      sumAway += pScores[j][1].scoreList[1];
    }

    return [sumHome, sumAway];
  });

  return (
    <table>
      <thead>
        <tr>
          {scoreSums.map((e, index) => (
            <td key={index}>{index + 1}</td>
          ))}
          <td>T</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          {scoreSums.map((scoreList, index) => {
            return (
              <td key={index} className="OtherScoreList-styles-module-highlighted">
                <span>{scoreList[0]}</span>
              </td>
            );
          })}
          <td className="OtherScoreList-styles-module-highlighted">
            <span>{ftScores[0][1].scoreList[0]}</span>
          </td>
        </tr>
        <tr>
          {scoreSums.map((scoreList, index) => {
            return (
              <td key={index} className="OtherScoreList-styles-module-highlighted">
                <span>{scoreList[1]}</span>
              </td>
            );
          })}
          <td className="OtherScoreList-styles-module-highlighted">
            <span>{ftScores[0][1].scoreList[1]}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const ScoreMapTennis = ({ scoreMap }) => {
  if (!scoreMap) {
    return null;
  }
  const pScores = scoreMap.filter(([key]) => key.startsWith("p"));
  const ftScores = scoreMap.filter(([key]) => key.startsWith("ft"));
  const scoreSums = pScores.map((_, i) => {
    let sumHome = 0;
    let sumAway = 0;

    for (let j = 0; j <= i; j++) {
      sumHome += pScores[j][1].scoreList[0];
      sumAway += pScores[j][1].scoreList[1];
    }

    return [sumHome, sumAway];
  });

  return (
    <table>
      <thead>
        <tr>
          {scoreSums.map((e, index) => (
            <td key={index}>{index + 1}</td>
          ))}
          <td>T</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          {scoreSums.map((scoreList, index) => {
            return (
              <td key={index} className="OtherScoreList-styles-module-highlighted">
                <span>{scoreList[0]}</span>
              </td>
            );
          })}
          <td className="OtherScoreList-styles-module-highlighted">
            <span>{ftScores[0][1].scoreList[0]}</span>
          </td>
        </tr>
        <tr>
          {scoreSums.map((scoreList, index) => {
            return (
              <td key={index} className="OtherScoreList-styles-module-highlighted">
                <span>{scoreList[1]}</span>
              </td>
            );
          })}
          <td className="OtherScoreList-styles-module-highlighted">
            <span>{ftScores[0][1].scoreList[1]}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
