import { useState } from "react";
import {
  bet_guide,
  carccashout,
  cashout,
  favorite,
} from "../../../assets/images";
import "../../../app/pages/user-page/userPage.css";

export default function BetGuide() {
  const [activeTab, setActiveTab] = useState("Bet Guide");

  const renderSection = () => {
    switch (activeTab) {
      case "Bet Guide":
        return <BetGuideSection />;
      case "Car Cashout":
        return <CarCashoutSection />;
      case "Favorite":
        return <FavoriteSection />;
      case "Cashout":
        return <CashoutSection />;
      case "Wett Assistent":
        return <WetAssistentSection />;
      case "Mein Live":
        return <MeinLive />;
      default:
        return <BetGuideSection />;
    }
  };

  return (
    <div className="flex w-full ">
      <BetGuideSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="w-full flex justify-center  h-full bg-gray-200 px-5 overflow-auto scrollbar-hide">
        <div className="featurePage rounded-lg shadow-topCombisShadow bg-white h-full w-4/12 m-10">
          {renderSection()}
        </div>
      </div>
    </div>
  );
}

const list = [
  {
    name: "Bet Guide",
    image:
      "https://content.prod.irl.aws.tipicodev.com/cms/custom/tutorial_item/blt01c8635ee1cbff75.png",
  },
  {
    name: "Car Cashout",
    image:
      "https://content.prod.irl.aws.tipicodev.com/cms/custom/tutorial_item/blt0f9417d8573bb315.png",
  },
  {
    name: "Favorite",
    image:
      "https://content.prod.irl.aws.tipicodev.com/cms/custom/tutorial_item/bltae94432b1abf71d0.png",
  },
  {
    name: "Cashout",
    image:
      "https://content.prod.irl.aws.tipicodev.com/cms/custom/tutorial_item/bltf042b0b634d5360d.png",
  },
  {
    name: "Wett Assistent",
    image:
      "https://content.prod.irl.aws.tipicodev.com/cms/custom/tutorial_item/bltd19ce43feca1fcdd.png",
  },
  {
    name: "Mein Live",
    image:
      "https://content.prod.irl.aws.tipicodev.com/cms/custom/tutorial_item/blt1e14341fa500ddef.png",
  },
];

function BetGuideSidebar({ activeTab, setActiveTab }) {
  return (
    <div className="betGuide hidden sm:block w-1/3 lg:w-96 h-[95vh] overflow-auto p-3 shadow-sm bg-white scrollbar-hide">
      <ul className="space-y-2">
        {list.map((row) => (
          <li
            className={`flex items-center cursor-pointer p-3 rounded hover:bg-gray-200 ${
              activeTab === row.name ? "bg-gray-200" : ""
            }`}
            onClick={() => setActiveTab(row.name)}
            key={row.name}
          >
            <img
              className="mr-3"
              src={row.image}
              alt={row.name}
              width={45}
              height={45}
            />
            <div className="flex flex-col">
              <span className="text-sm font-semibold">{row.name}</span>
              <span className="text-xs text-gray-500">
                Learn the basics of sports betting in just a few minutes.
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

function BetGuideSection() {
  return (
    <div className="shodow-topCombisShadow">
      <div
        className="FeatureInfo-styles-module-container FeatureInfo-styles-module-desktop"
        data-testid="tutorial-feature-page-content"
      >
        <div>
          <div
            className="components-styles-module-heading components-styles-module-h1 text-xs"
            style={{ textAlign: "center" }}
          >
            Wett-Guide
            <p className="components-styles-module-paragraph components-styles-module-desktop">
              Lerne die Grundlagen der Sportwetten in unserem kurzen{" "}
              <span>
                <strong>2-Minuten-Video</strong>
              </span>{" "}
              oder scrolle nach unten, um weitere Informationen zu finden.
            </p>
          </div>
          <iframe
            className="components-styles-module-iframe components-styles-module-desktop"
            src="https://www.youtube.com/embed/KS5AXN7YaD0"
            allowFullScreen
            frameBorder="0"
          />
        </div>
        <div className="quoten">
          <div className="components-styles-module-heading components-styles-module-h2">
            Was sind Quoten?
          </div>
          <p className="components-styles-module-paragraph components-styles-module-desktop">
            Quoten sind der Faktor, mit dem der Einsatz im Gewinnfall
            multipliziert wird.
          </p>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            name="img"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/bltbdec50376a4a72ec.png"
            width="1005"
            height="302"
          ></img>
          <p className="components-styles-module-paragraph components-styles-module-desktop">
            <strong>Einsatz x Quote = Möglicher Gewinn.</strong>
          </p>
          <p className="components-styles-module-paragraph components-styles-module-desktop">
            <strong>Beispiel:</strong> Wenn du <strong>1,00 €</strong> Einsatz
            auf eine Wette mit der Quote <strong>2,00</strong> setzt, beträgt
            dein möglicher Gewinn <strong>2,00 €</strong>.
          </p>
        </div>
        <div>
          <div className="components-styles-module-heading components-styles-module-h2">
            Beliebte Wettmärkte
          </div>
          <div className="components-styles-module-heading components-styles-module-h3">
            Die Tipp-Wette
          </div>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            name="img"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt9de63f9dfedf43ff.png"
            width="993"
            height="179"
          ></img>
          <p className="components-styles-module-paragraph components-styles-module-desktop">
            Wette auf den Ausgang des Events.
          </p>
          <p className="components-styles-module-paragraph components-styles-module-desktop">
            <strong>1</strong> = Das Heimteam gewinnt. <br />
            <strong>X</strong> = Das Event endet Unentschieden. <br />
            <strong>2</strong> = Das Auswärtsteam gewinnt.
          </p>
          <div className="components-styles-module-heading components-styles-module-h3">
            <strong>Die Über/Unter-Wette</strong>
          </div>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt3151cfe9077e9c8b.png"
            width="993"
            height="178"
            alt="Über/Unter-Wette"
          />
          <p className="components-styles-module-paragraph components-styles-module-desktop">
            Wette darauf, ob die Gesamtanzahl der Tore, die in einem Event
            fallen, entweder über (+) oder unter (-) einer bestimmten Zahl
            liegen.
          </p>
          <p className="components-styles-module-paragraph components-styles-module-desktop">
            <strong>Beispiel:</strong> Wettest du auf Über (+) 2,5 Tore, müssen
            mindestens 3 Tore erzielt werden, damit deine Wette erfolgreich ist.
          </p>
          <div className="components-styles-module-heading components-styles-module-h3">
            Die Nächstes Tor Wette
          </div>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/bltb7e8c383c73409b3.png"
            width="993"
            height="179"
            alt="Nächstes Tor Wette"
          />
          <p className="components-styles-module-paragraph components-styles-module-desktop">
            Tippe, wer im ausgewählten Event das nächste Tor erzielen wird. Das
            bezieht sich nur auf das erste Tor, das nach Platzierung deiner
            Wette gültig ist.
          </p>
        </div>
        <div className="quoten">
          <div className="components-styles-module-heading components-styles-module-h2">
            Kombiwetten
          </div>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/bltaa4da8aaccabb76d.png"
            width="1005"
            height="531"
            alt="Kombiwetten"
          />
          <p className="components-styles-module-paragraph components-styles-module-desktop">
            Eine Kombiwette ist eine Wette auf mehrere Events. Dabei werden alle
            ausgewählten Quoten multipliziert, um die Gesamtquote zu berechnen.
          </p>
          <p className="components-styles-module-paragraph components-styles-module-desktop">
            <strong>Beispiel:</strong>
            <br />
            Event 1 - Quote: 1,50
            <br />
            Event 2 - Quote: 2,00
            <br />
            <strong>Gesamtquote: 1,50 x 2,00 = 3,00</strong>
          </p>
        </div>
        <div>
          <div className="components-styles-module-heading components-styles-module-h2">
            Die Wettabgabe
          </div>
          <div className="components-styles-module-heading components-styles-module-h3">
            Öffne deinen Wettschein
          </div>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            name="img"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt0204b54da04739e7.png"
            width="993"
            height="179"
          />
          <p className="components-styles-module-paragraph components-styles-module-desktop">
            Er zeigt die Übersicht deiner Wettauswahl. Dort hast du auch die
            Möglichkeit, deine Auswahl zu überprüfen und gegebenenfalls
            anzupassen.
          </p>
          <div className="components-styles-module-heading components-styles-module-h3">
            Lege deinen Gesamteinsatz fest
          </div>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            name="img"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt5bde4d9e13ef16fa.png"
            width="993"
            height="421"
          ></img>
          <p className="components-styles-module-paragraph components-styles-module-desktop">
            Lass dir den möglichen Gewinn berechnen und gebe deinen Wettschein
            ab.
          </p>
        </div>
      </div>
    </div>
  );
}

function CarCashoutSection() {
  return (
    <div className="">
      <div
        className="FeatureInfo-styles-module-container FeatureInfo-styles-module-desktop"
        data-testid="tutorial-feature-page-content"
      >
        <div>
          <p
            className="components-styles-module-paragraph components-styles-module-desktop"
            name="p"
          ></p>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            name="img"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt4ad268be9d9b3d14.png"
            width="981"
            height="294"
            alt="Auto-Cashout"
          />
          <div
            className="components-styles-module-heading components-styles-module-h2"
            name="h2"
            style={{ textAlign: "center" }}
          >
            <span>Auto-Cashout</span>
          </div>
          <p
            className="components-styles-module-paragraph components-styles-module-desktop"
            name="p"
            style={{ textAlign: "center" }}
          >
            Lege fest bei welchem Betrag dein Wettschein automatisch ausgecasht
            werden soll.
          </p>
        </div>

        <div className="quoten">
          <div
            className="components-styles-module-heading components-styles-module-h2"
            name="h2"
            style={{ textAlign: "center" }}
          >
            <span>Auto-Cashout aktivieren</span>
          </div>
          <p
            className="components-styles-module-paragraph components-styles-module-desktop"
            name="p"
          ></p>
          <figure style={{ margin: "auto", textAlign: "center" }}>
            <img
              className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
              name="img"
              src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt363136c6a6f37e7f.png"
              width="885"
              height="369"
              style={{ margin: "auto", width: "auto" }}
              alt="Activate Auto-Cashout"
            />
          </figure>
          <p
            className="components-styles-module-paragraph components-styles-module-desktop"
            name="p"
            style={{ textAlign: "center" }}
          >
            Den Auto-Cashout findest du im Wettschein unter dem normalen
            Cashout.
          </p>
        </div>

        <div>
          <div
            className="components-styles-module-heading components-styles-module-h2"
            name="h2"
            style={{ textAlign: "center" }}
          >
            <span>Wunschbetrag festlegen</span>
          </div>
          <p
            className="components-styles-module-paragraph components-styles-module-desktop"
            name="p"
          ></p>
          <figure style={{ margin: "auto", textAlign: "center" }}>
            <img
              className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
              name="img"
              src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blta35497b1ee4a0534.png"
              width="885"
              height="393"
              style={{ margin: "auto", width: "auto" }}
              alt="Set Desired Amount"
            />
          </figure>
          <p
            className="components-styles-module-paragraph components-styles-module-desktop"
            name="p"
            style={{ textAlign: "center" }}
          >
            Gib den Betrag ein bei dem dein Wettschein automatisch ausgecasht
            werden soll.
          </p>
        </div>

        <div className="quoten">
          <div
            className="components-styles-module-heading components-styles-module-h2"
            name="h2"
            style={{ textAlign: "center" }}
          >
            <span>Zurücklehnen</span>
          </div>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            name="img"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/bltb06149930bedbeb1.png"
            width="981"
            height="247"
            alt="Relax"
          />
          <p
            className="components-styles-module-paragraph components-styles-module-desktop"
            name="p"
            style={{ textAlign: "center" }}
          >
            Dein Wettschein wird automatisch ausgecasht sobald dein eingegebener
            Betrag erreicht wurde.
          </p>
        </div>
      </div>
    </div>
  );
}

function FavoriteSection() {
  return (
    <div
      className="FeatureInfo-styles-module-container FeatureInfo-styles-module-desktop"
      data-testid="tutorial-feature-page-content"
    >
      <div>
        <img
          className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
          name="img"
          src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt36e1baa8ea056b41.png"
          width="981"
          height="288"
          alt="Favoriten"
        />
        <div
          className="components-styles-module-heading components-styles-module-h1"
          name="h1"
          style={{ textAlign: "center" }}
        >
          <span>Favoriten</span>
        </div>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
          style={{ textAlign: "center" }}
        >
          Deine Lieblingswettbewerbe an einem Ort.
        </p>
      </div>

      <div className="quoten">
        <div
          className="components-styles-module-heading components-styles-module-h2"
          name="h2"
          style={{ textAlign: "center" }}
        >
          <span>Favoriten hinzufügen</span>
        </div>
        <figure style={{ margin: "auto", textAlign: "center" }}>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            name="img"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/bltd1f49b146cf11356.png"
            width="889"
            height="444"
            style={{ margin: "auto", width: "auto" }}
            alt="Favoriten hinzufügen"
          />
        </figure>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
          style={{ textAlign: "center" }}
        >
          Klicke auf den <strong>weißen Stern</strong>.
        </p>
      </div>

      <div>
        <div
          className="components-styles-module-heading components-styles-module-h2"
          name="h2"
          style={{ textAlign: "center" }}
        >
          <span>Favoriten entfernen</span>
        </div>
        <figure style={{ margin: "auto", textAlign: "center" }}>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            name="img"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt0560bb10c07fb4e8.png"
            width="895"
            height="447"
            style={{ margin: "auto" }}
            alt="Favoriten entfernen"
          />
        </figure>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
          style={{ textAlign: "center" }}
        >
          Klicke auf den <strong>gelben Stern</strong>.
        </p>
      </div>

      <div className="quoten">
        <div
          className="components-styles-module-heading components-styles-module-h2"
          name="h2"
          style={{ textAlign: "center" }}
        >
          <span>Alles an einem Ort</span>
        </div>
        <figure style={{ margin: "auto", textAlign: "center" }}>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            name="img"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/bltc4db310cc9aa4e74.png"
            width="885"
            height="216"
            style={{ margin: "auto", width: "auto" }}
            alt="Alles an einem Ort"
          />
        </figure>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
          style={{ textAlign: "center" }}
        >
          Deine Favoriten sind jetzt nur noch einen Klick entfernt.
        </p>
      </div>
    </div>
  );
}

function CashoutSection() {
  return (
    <div
      className="FeatureInfo-styles-module-container FeatureInfo-styles-module-desktop"
      data-testid="tutorial-feature-page-content"
    >
      <div>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
        ></p>
        <img
          className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
          name="img"
          src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/bltd825638130bfc068.png"
          width="981"
          height="294"
          alt="Cashout"
        />
        <div
          className="components-styles-module-heading components-styles-module-h2"
          name="h2"
          style={{ textAlign: "center" }}
        >
          <span>Cashout</span>
        </div>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
          style={{ textAlign: "center" }}
        >
          Zahle deine Wette vor dem Ende aus.
        </p>
      </div>

      <div className="quoten">
        <div
          className="components-styles-module-heading components-styles-module-h2"
          name="h2"
          style={{ textAlign: "center" }}
        >
          <span>Cashout abrufen</span>
        </div>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
        ></p>
        <img
          className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
          name="img"
          src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt0f4c8c72d4c80eab.png"
          width="985"
          height="177"
          alt="Cashout abrufen"
        />
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
          style={{ textAlign: "center" }}
        >
          Den Cashout findest du in den meisten deiner abgegebenen Wettscheine.
        </p>
      </div>

      <div>
        <div
          className="components-styles-module-heading components-styles-module-h2"
          name="h2"
          style={{ textAlign: "center" }}
        >
          <span>Cashout nutzen</span>
        </div>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
        ></p>
        <img
          className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
          name="img"
          src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt062287661bcda6dd.png"
          width="885"
          height="369"
          alt="Cashout nutzen"
        />
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
          style={{ textAlign: "center" }}
        >
          Du kannst den Cashout zu jedem Zeitpunkt nutzen.
        </p>
      </div>

      <div className="quoten">
        <div
          className="components-styles-module-heading components-styles-module-h2"
          name="h2"
          style={{ textAlign: "center" }}
        >
          <span>Cashout bestätigen</span>
        </div>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
        ></p>
        <img
          className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
          name="img"
          src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/bltf5932d267e8d8668.png"
          width="885"
          height="369"
          alt="Cashout bestätigen"
        />
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
          style={{ textAlign: "center" }}
        >
          Bestätige den Cashout - den Gewinn erhältst du nachdem der Wettschein
          regulär ausgewertet wurde.
        </p>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
        ></p>
      </div>
    </div>
  );
}
function WetAssistentSection() {
  return (
    <div
      className="FeatureInfo-styles-module-container FeatureInfo-styles-module-desktop"
      data-testid="tutorial-feature-page-content"
    >
      <div>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
        ></p>
        <figure style={{ margin: "auto", textAlign: "center", width: "auto" }}>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            name="img"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt6b70811e5dacdbc2.png"
            width="981"
            height="288"
            alt="Wett-Assistent"
          />
        </figure>
        <div
          className="components-styles-module-heading components-styles-module-h1"
          name="h1"
          style={{ textAlign: "center" }}
        >
          <span>Wett-Assistent</span>
        </div>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
          style={{ textAlign: "center" }}
        >
          Greife schnell und einfach auf die relevantesten Statistiken zu.
        </p>
      </div>

      <div>
        <div
          className="components-styles-module-heading components-styles-module-h2"
          name="h2"
          style={{ textAlign: "center" }}
        >
          <span>Einfacher Zugriff</span>
        </div>
        <img
          className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
          name="img"
          src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/bltffefbb0624d9ff7c.png"
          width="895"
          height="303"
          alt="Einfacher Zugriff"
        />
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
          style={{ textAlign: "center" }}
        >
          Den Wett-Assistenten findest du für Fußball, Tennis und Basketball.
        </p>
      </div>

      <div>
        <div
          className="components-styles-module-heading components-styles-module-h2"
          name="h2"
          style={{ textAlign: "center" }}
        >
          <span>Alles auf einen Blick</span>
        </div>
        <figure>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            name="img"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/bltfd49e73205983fc9.png"
            width="981"
            height="411"
            alt="Alles auf einen Blick"
          />
        </figure>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
          style={{ textAlign: "center" }}
        >
          Die wichtigsten Statistiken werden dir nun übersichtlich aufgelistet.
        </p>
      </div>

      <div>
        <div
          className="components-styles-module-heading components-styles-module-h2"
          name="h2"
          style={{ textAlign: "center" }}
        >
          <span>Analysieren</span>
        </div>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
          style={{ textAlign: "center" }}
        ></p>
        <img
          className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
          name="img"
          src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt808330c22bd683a9.png"
          width="1125"
          height="411"
          alt="Analysieren"
        />
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
          style={{ textAlign: "center" }}
        >
          Vergleiche Statistiken mit Quoten und treffe so deine Entscheidung.
        </p>
      </div>
    </div>
  );
}
function MeinLive() {
  return (
    <div
      className="FeatureInfo-styles-module-container FeatureInfo-styles-module-desktop"
      data-testid="tutorial-feature-page-content"
      style={{ textAlign: "center" }}
    >
      <div>
        <img
          className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
          name="img"
          src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blte6392d3f3738553b.png"
          width="981"
          height="288"
          alt="Mein Live"
        />
        <div
          className="components-styles-module-heading components-styles-module-h1"
          name="h1"
        >
          <span>Mein Live</span>
        </div>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
        >
          Verfolge alle deine Livewetten an einem Ort.
        </p>
      </div>

      <div className="quoten">
        <div
          className="components-styles-module-heading components-styles-module-h2"
          name="h2"
        >
          <span>Wetten abgeben</span>
        </div>
        <figure>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            name="img"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt9300bbaa765e550a.png"
            width="885"
            height="374"
            alt="Wetten abgeben"
          />
        </figure>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
        >
          Das war's, den Rest machen wir.
        </p>
      </div>

      <div>
        <div
          className="components-styles-module-heading components-styles-module-h2"
          name="h2"
        >
          <span>Alles an einem Ort</span>
        </div>
        <figure>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            name="img"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt86b32955a3d9682e.png"
            width="885"
            height="217"
            alt="Alles an einem Ort"
          />
        </figure>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
        >
          Mein Live ist über die Sport- sowie Livewetten Seiten aufrufbar.
        </p>
      </div>

      <div className="quoten">
        <div
          className="components-styles-module-heading components-styles-module-h2"
          name="h2"
        >
          <span>Übersichtlicher denn je</span>
        </div>
        <figure>
          <img
            className="components-styles-module-image components-styles-module-full-width components-styles-module-desktop"
            name="img"
            src="https://content.prod.irl.aws.tipicodev.com/cms/custom/assets/blt4e8242b6e5b14d41.png"
            width="885"
            height="277"
            alt="Übersichtlicher denn je"
          />
        </figure>
        <p
          className="components-styles-module-paragraph components-styles-module-desktop"
          name="p"
        >
          Jetzt kannst du alle deine Livewetten einfach und entspannt verfolgen.
        </p>
      </div>
    </div>
  );
}
