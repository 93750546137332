import React, { useState } from "react";
export const LimitsClosure = () => {
    const [activeTab, setActiveTab] = useState("userData");
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="flex flex-col items-center w-full max-w-5xl mx-auto md:p-6">
            {/* Başlık */}
            <h2 className="w-full text-lg font-semibold text-gray-700 mb-4 text-left hidden md:block">
                Meine Daten
            </h2>

            {/* İçerik Kartı */}
            <div className="w-full bg-white rounded-lg shadow-lg p-6">
                {/* Tab Menüsü */}
                <div className="flex border-b border-gray-200 mb-6">
                    <button
                        onClick={() => handleTabClick("userData")}
                        className={`pb-4 px-4 text-xs text-nowrap font-semibold ${activeTab === "userData" ? "border-b-2 border-red-500 " : "text-gray-600"}`}
                    >
                        Kullanıcı Verileri
                    </button>
                    <button
                        onClick={() => handleTabClick("contactInfo")}
                        className={`pb-4 px-4 text-xs text-nowrap font-semibold ${activeTab === "contactInfo" ? "border-b-2 border-red-500 " : "text-gray-600"}`}
                    >
                        İletişim Bilgileri
                    </button>
                    <button
                        onClick={() => handleTabClick("changePassword")}
                        className={`pb-4 px-4 text-xs text-nowrap font-semibold ${activeTab === "changePassword" ? "border-b-2 border-red-500 " : "text-gray-600"}`}
                    >
                        Parola Değiştir
                    </button>
                </div>

                {/* Tab İçerikleri */}
                <div className={` ${activeTab === "userData" ? "block" : "hidden"}`}>
                    <div className=" flex-row justify-center md:p-10  items-center  ">
                        <div className="w-12/12">
                            <h1 className="text-sm md:text-md text-black font-medium mb-4 text-center md:text-left">Einzahlungslimit</h1>
                            <p className="text-gray-600 text-sm md:text-sm mb-4 text-center md:text-left">
                                Setze dir ein tägliches, wöchentliches oder monatliches Einzahlungslimit. Dein monatliches Einzahlungslimit wird an das länderübergreifende Glücksspieldatensystem LUGAS übermittelt und gilt anbieterübergreifend. Ein tägliches oder wöchentliches Limit gilt nur für dieses online Sportwetten-Konto.
                            </p>

                            {/* Monthly Limit */}
                            <div className="bg-gray-100 p-4 md:p-6 rounded-lg mb-4 shadow-sm">
                                <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                                    <h2 className="text-sm md:text-base text-gray-600 font-semibold text-center md:text-left">Monatlich (anbieterübergreifend)</h2>
                                    <button className="px-4 py-2 mt-2 md:mt-0 border text-sm md:text-base font-semibold border-combisColor text-combisColor rounded-md hover:bg-blue-50 w-full md:w-auto">
                                        Limit ändern
                                    </button>
                                </div>
                                <p className="text-xl md:text-2xl border-b-2 font-semibold text-gray-600 text-center md:text-left">10.000 €</p>
                                <p className="text-gray-500 text-sm md:text-base mt-2 text-center md:text-left">
                                    Wir können dir nur dein bei Tipico eingestelltes LUGAS-Limit anzeigen. Änderungen, die du bei anderen Anbietern machst, werden hier nicht angezeigt.
                                    <br />
                                    Diesen Monat bei Tipico eingezahlt: <strong>810 €</strong>
                                </p>

                                {/* Weekly Limit */}
                                <div className="bg-gray-100 border-b-2 mt-6">
                                    <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                                        <h2 className="text-sm md:text-base font-semibold text-gray-600 text-center md:text-left">Wöchentlich</h2>
                                        <button className="px-4 py-2 mt-2 md:mt-0 border text-sm md:text-base font-semibold border-combisColor text-combisColor rounded-md hover:bg-blue-50 w-full md:w-auto">
                                            Limit ändern
                                        </button>
                                    </div>
                                    <p className="text-gray-500 text-center md:text-left">Kein Limit</p>
                                </div>

                                {/* Daily Limit */}
                                <div className="bg-gray-100 border-b-2 mt-6">
                                    <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                                        <h2 className="text-sm md:text-base text-gray-600 font-semibold text-center md:text-left">Täglich</h2>
                                        <button className="px-4 py-2 mt-2 md:mt-0 border text-sm md:text-base font-semibold border-combisColor text-combisColor rounded-md hover:bg-blue-50 w-full md:w-auto">
                                            Limit ändern
                                        </button>
                                    </div>
                                    <p className="text-gray-500 text-center md:text-left">Kein Limit</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-12/12 mt-12">
                            <h1 className="text-sm md:text-md text-black font-medium mb-4 text-center md:text-left">Einzahlungslimit</h1>
                            <p className="text-gray-600 text-sm md:text-sm mb-4 text-center md:text-left">
                                Setze dir ein tägliches, wöchentliches oder monatliches Einzahlungslimit. Dein monatliches Einzahlungslimit wird an das länderübergreifende Glücksspieldatensystem LUGAS übermittelt und gilt anbieterübergreifend. Ein tägliches oder wöchentliches Limit gilt nur für dieses online Sportwetten-Konto.
                            </p>

                            {/* Monthly Limit */}
                            <div className="bg-gray-100 p-4 md:p-6 rounded-lg mb-4 shadow-sm">
                                <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                                    <h2 className="text-sm md:text-base text-gray-600 font-semibold text-center md:text-left">Monatlich (anbieterübergreifend)</h2>
                                    <button className="px-4 py-2 mt-2 md:mt-0 border text-sm md:text-base font-semibold border-combisColor text-combisColor rounded-md hover:bg-blue-50 w-full md:w-auto">
                                        Limit ändern
                                    </button>
                                </div>
                                <p className="text-xl md:text-2xl border-b-2 font-semibold text-gray-600 text-center md:text-left">10.000 €</p>
                                <p className="text-gray-500 text-sm md:text-base mt-2 text-center md:text-left">
                                    Wir können dir nur dein bei Tipico eingestelltes LUGAS-Limit anzeigen. Änderungen, die du bei anderen Anbietern machst, werden hier nicht angezeigt.
                                    <br />
                                    Diesen Monat bei Tipico eingezahlt: <strong>810 €</strong>
                                </p>

                                {/* Weekly Limit */}
                                <div className="bg-gray-100 border-b-2 mt-6">
                                    <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                                        <h2 className="text-sm md:text-base font-semibold text-gray-600 text-center md:text-left">Wöchentlich</h2>
                                        <button className="px-4 py-2 mt-2 md:mt-0 border text-sm md:text-base font-semibold border-combisColor text-combisColor rounded-md hover:bg-blue-50 w-full md:w-auto">
                                            Limit ändern
                                        </button>
                                    </div>
                                    <p className="text-gray-500 text-center md:text-left">Kein Limit</p>
                                </div>

                                {/* Daily Limit */}
                                <div className="bg-gray-100 border-b-2 mt-6">
                                    <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                                        <h2 className="text-sm md:text-base text-gray-600 font-semibold text-center md:text-left">Täglich</h2>
                                        <button className="px-4 py-2 mt-2 md:mt-0 border text-sm md:text-base font-semibold border-combisColor text-combisColor rounded-md hover:bg-blue-50 w-full md:w-auto">
                                            Limit ändern
                                        </button>
                                    </div>
                                    <p className="text-gray-500 text-center md:text-left">Kein Limit</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={` ${activeTab === "contactInfo" ? "block" : "hidden"}`}>
                    <div className=" flex justify-center md:p-10  items-center  ">
                        <div className="w-12/12">
                            <h1 className="text-sm md:text-md text-black font-medium mb-4 text-center md:text-left">Einzahlungslimit</h1>
                            <p className="text-gray-600 text-sm md:text-sm mb-4 text-center md:text-left">
                                Setze dir ein tägliches, wöchentliches oder monatliches Einzahlungslimit. Dein monatliches Einzahlungslimit wird an das länderübergreifende Glücksspieldatensystem LUGAS übermittelt und gilt anbieterübergreifend. Ein tägliches oder wöchentliches Limit gilt nur für dieses online Sportwetten-Konto.
                            </p>

                            {/* Monthly Limit */}
                            <div className="bg-gray-100 p-4 md:p-6 rounded-lg mb-4 shadow-sm">
                                <div className="bg-gray-100 border-b-2 mt-6">
                                    <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                                        <h2 className="text-sm md:text-base font-semibold text-gray-600 text-center md:text-left">Monatlich</h2>
                                        <button className="px-4 py-2 mt-2 md:mt-0 border text-sm md:text-base font-semibold border-combisColor text-combisColor rounded-md hover:bg-blue-50 w-full md:w-auto">
                                            Limit ändern
                                        </button>
                                    </div>
                                    <p className="text-gray-500 text-center md:text-left">Kein Limit</p>
                                </div>

                                {/* Weekly Limit */}
                                <div className="bg-gray-100 border-b-2 mt-6">
                                    <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                                        <h2 className="text-sm md:text-base font-semibold text-gray-600 text-center md:text-left">Wöchentlich</h2>
                                        <button className="px-4 py-2 mt-2 md:mt-0 border text-sm md:text-base font-semibold border-combisColor text-combisColor rounded-md hover:bg-blue-50 w-full md:w-auto">
                                            Limit ändern
                                        </button>
                                    </div>
                                    <p className="text-gray-500 text-center md:text-left">Kein Limit</p>
                                </div>

                                {/* Daily Limit */}
                                <div className="bg-gray-100 border-b-2 mt-6">
                                    <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                                        <h2 className="text-sm md:text-base text-gray-600 font-semibold text-center md:text-left">Täglich</h2>
                                        <button className="px-4 py-2 mt-2 md:mt-0 border text-sm md:text-base font-semibold border-combisColor text-combisColor rounded-md hover:bg-blue-50 w-full md:w-auto">
                                            Limit ändern
                                        </button>
                                    </div>
                                    <p className="text-gray-500 text-center md:text-left">Kein Limit</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className={` ${activeTab === "changePassword" ? "block" : "hidden"}`}>
                    <div className="w-12/12 p-12">
                        <div className="w-full md:w-12/12 lg:w-12/12 bg-white ">
                            <p className="text-gray-700 text-sm mb-6">
                                Du kannst dein Konto vorübergehend oder auf unbestimmte Zeit schließen. Eine Kontoschließung wirkt sich sowohl auf das Tipico Sportwetten Konto als auch auf das Tipico Games Konto aus.
                            </p>
                            <ul className="text-gray-700 text-sm mb-4 list-disc list-inside">
                                <li className="font-semibold">Nach Ablauf des gewählten Zeitraumes werden deine Konten automatisch reaktiviert.</li>
                                <li className="font-semibold">Geschlossene Konten können auf der Plattform jederzeit reaktiviert werden.</li>
                            </ul>
                            <p className="text-gray-600 mb-6 text-sm">
                                Solltest du dich selbst aus Gründen der Suchtprävention vom Glücksspiel ausschließen wollen, wähle stattdessen bitte die Option <span className="text-blue-500 cursor-pointer">Sperre</span>.
                            </p>

                            <div className="flex flex-wrap text-sm mb-6">
                                <div className="w-full md:w-1/2 pr-0 md:pr-2 mb-4 md:mb-0">
                                    <label className="block text-gray-700 mb-2">Zeitraum:</label>
                                    <select className="w-full p-3 border border-gray-300 rounded-md bg-gray-100 text-gray-600">
                                        <option>Option wählen ...</option>
                                        <option>1 Woche</option>
                                        <option>1 Monat</option>
                                        <option>3 Monate</option>
                                    </select>
                                </div>
                                <div className="w-full md:w-1/2 pl-0 md:pl-2">
                                    <label className="block text-gray-700 mb-2">Grund:</label>
                                    <select className="w-full p-3 border border-gray-300 rounded-md bg-gray-100 text-gray-600">
                                        <option>Option wählen ...</option>
                                        <option>Persönliche Gründe</option>
                                        <option>Finanzielle Gründe</option>
                                        <option>Andere Gründe</option>
                                    </select>
                                </div>
                            </div>

                            <div className="flex justify-center">
                                <button className="w-full md:w-auto px-6 py-3 border border-blue-500 text-blue-500 rounded-md hover:bg-blue-50">
                                    Bearbeiten
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};
