import { useEffect, useState } from "react";
import "./rightSidebarMobile.css";
import { bet_guide, carccashout, cashout, favorite, nodata } from "../../assets/images";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { Button } from "../Button/Button";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearOdds, removeOdd } from "../../store/actions/homeTypes";
import { TopWinnerApi } from "../../store/actions/topWinners";
import { BetSlipMobile } from "./BetSlipMobile";
const list = [
  {
    name: "Bet Guide",
    image: bet_guide,
  },
  {
    name: "Car ccashout",
    image: carccashout,
  },
  {
    name: "Favorite",
    image: favorite,
  },
  {
    name: "Cashout",
    image: cashout,
  },
];
export function RightSidebarMobile() {
  const [betTab, setBet] = useState("Bet Slip");
  const [winnerTab, setWinnerTab] = useState(0);
  const [winner, setWinner] = useState([]);
  const [totalRate, setTotalRate] = useState(0);
  const oddsList = useSelector((state) => state.homereducer.odds);
  const dispatch = useDispatch();
  const allWinner = useSelector((state) => state.topWinner);

  const [stakeValue, setStakeValue] = useState("€5"); // Başlangıç değeri

  const handleInputChange = (e) => {
    const input = e.target.value;

    // Sadece rakam ve nokta girişine izin verme
    const sanitizedInput = input.replace(/[^0-9.]/g, "");

    // Eğer kullanıcı tamamen silmişse, '€0.00' olarak ayarla
    if (sanitizedInput.length === 0) {
      setStakeValue("€0");
      return;
    }

    // Sayı bölümleri
    const parts = sanitizedInput.split(".");
    let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "."); // 3 basamak ayırma

    // İlk karakter sıfırsa ve yalnızca bir rakam varsa temizle
    if (integerPart.startsWith("0") && integerPart.length > 1) {
      integerPart = integerPart.substring(1);
    }

    // Nokta ile devam etme
    const decimalPart = parts[1] ? "." + parts[1] : "";

    // Sayının uzunluğuna göre düzenleme
    if (integerPart.length >= 7) {
      // 1.000.000'den fazla ise
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    } else if (integerPart.length >= 4) {
      // 10.000'den fazla ise
      integerPart = integerPart.replace(/(\d)(?=(\d{4})+(?!\d))/g, "$1.");
    }

    // Güncellenmiş değeri ayarlama
    setStakeValue(`€${integerPart}${decimalPart}`);
  };

  const getNumericStakeValue = () => {
    const numericValue = parseFloat(stakeValue.replace("€", "").replace(/\./g, "").replace(",", "."));
    return isNaN(numericValue) ? 0 : numericValue;
  };

  const totalProfit = getNumericStakeValue() * totalRate;

  useEffect(() => {
    const total = oddsList.reduce((acc, bet) => acc * bet.value, 1);
    if (total) {
      setTotalRate(total.toFixed(2));
    }
  }, [oddsList]);
  useEffect(() => {
    dispatch(TopWinnerApi());
    changeWinnerTab(0);
  }, [dispatch]);

  const changeWinnerTab = (winnerTab) => {
    setWinnerTab(winnerTab);
    setWinner(allWinner?.data?.data[winnerTab]);
  };

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(),
  });
  return (
    <>
      <div className="bg-custom sticky  md:block md:w-[18%] lg:w-[18%]  overflow-auto shadow-sm ">
        <div className="w-23% shadow-topCombisShadow ">
          <BetSlipMobile />
        </div>

        {/* // second section  */}

        <div className="w-full hidden ">
          {/* main tabs  */}
          <div className="flex justify-start py-4 px-4 font-semibold">TOP WINNERS</div>
          <div className="shadow-topCombisShadow">
            <div className="flex items-center w-full h-12 bg-white shadow-custom rounded-t-md">
              <div
                className={`flex items-center cursor-pointer justify-center w-1/2 h-full  border-b-2 ${
                  winnerTab === 0 ? "border-b-2  border-red-700" : "border-b-2  border-gray-50"
                } text-center`}
                onClick={() => {
                  changeWinnerTab(0);
                }}
              >
                <div className="flex ">
                  <span className="font-semibold text-xs">1 day</span>
                </div>
              </div>
              <div
                className={`flex items-center cursor-pointer justify-center w-1/2 h-full text-center ${
                  winnerTab === 1 ? "border-b-2  border-red-700" : "border-b-2  border-gray-50"
                } `}
                onClick={() => {
                  changeWinnerTab(1);
                }}
              >
                <div className="flex">
                  <span className="font-semibold text-xs"> 1 week</span>
                </div>
              </div>
              <div
                className={`flex items-center cursor-pointer justify-center w-1/2 h-full text-center ${
                  winnerTab === 2 ? "border-b-2  border-red-700" : "border-b-2  border-gray-50"
                } `}
                onClick={() => {
                  changeWinnerTab(2);
                }}
              >
                <div className="flex ">
                  <span className="font-semibold text-xs">1 month</span>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center shadow-custom  px-4 w-full h-12 bg-gray-100 text-black">
              <span className="pl-4 ">Win</span>
              <span>Use</span>
            </div>
            {/* tabs div  */}
            <div className="flex flex-col  justify-between  w-full shadow-custom  bg-white">
              {Array.isArray(winner) && winner.length > 0 ? (
                winner.map((data, index) => {
                  return (
                    <div key={index} className="flex flex-col  w-full justify-center items-center ">
                      <Accordion slotProps={{ heading: { component: "h4" } }} className="">
                        <AccordionSummary
                          expandIcon={
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="#e8eaed"
                              >
                                <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
                              </svg>
                            </>
                          }
                          aria-controls=""
                          id="panel1-header"
                          className="hover:bg-gray-300 "
                        >
                          <div className="flex justify-between w-full items-center">
                            <div className="flex gap-3">
                              <span className="text-black">{index + 1}.</span>
                              <span className="font-semibold text-sm">{data.totalPayout.toFixed(2)} €</span>
                            </div>

                            <span className="font-semibold text-sm text-black">{data.originCurStake.toFixed(2)} €</span>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className="bg-gray-200">
                          <div className="flex  ">
                            <div className="flex flex-col">
                              {[1, 2, 3, 4, 5].map((index) => {
                                return (
                                  <div key={index} className="flex flex-col pl-3 border-b py-1 border-b-gray-200">
                                    <span className="text-sm font-semibold">
                                      Rephaaol Gerrerio meets and Bayern Munich winds : Yes
                                    </span>
                                    <span className="text-xs font-extralight text-gray-500">
                                      Bayern Munich - Dinamo Zagreb
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </div>

        {/* third section  */}

        <div className="w-full hidden ">
          {/* main tabs  */}
          <div className="flex justify-start py-4 px-4 font-semibold">FEATURES</div>

          {/* tabs div  */}
          <div className="flex flex-col gap-2 justify-between  w-full p-3 bg-white">
            <div>
              {list.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex  w-full justify-start p-1 gap-2 items-center border-b border-gray-200"
                  >
                    <img src={item.image} alt="f" className="w-10 h-10" />
                    <div className="flex flex-col">
                      <span className="text-sm font-semibold">{item.name}</span>
                      <span className="text-xs text-gray-500">
                        Learn the basics of sports betting in just a few minutes.
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <Link to="/bet-guide" className="logo">
              <div className="flex items-center justify-end gap-1 py-1 cursor-pointer" onClick={() => {}}>
                <span className="text-black font-semibold">Show all</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16px"
                  viewBox="0 -960 960 960"
                  width="16px"
                  fill="#9ca3af"
                >
                  <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
