import axios from "axios";
import { TOPWINNER_SUCCESS, TOPWINNER_FAILURE } from "./actiontypes";
import { top_winner } from "../paths";

export const TopWinnerApi = () => async (dispatch) => {
  try {
    // if (token) {

    const response = await axios.get(top_winner);
    dispatch({
      type: TOPWINNER_SUCCESS,
      payload: {
        data: response.data,
      },
    });
    // } else {
    // dispatch({
    //   type: MATCHES_FAILURE,
    //   payload: "api failed",
    // });
    // }
  } catch (error) {
    dispatch({
      type: TOPWINNER_FAILURE,
      payload: error.response?.data?.message || "api failed. Please try again.",
    });
  }
};
