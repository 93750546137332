// src/app/pages/auth/Login.js
import React, { useEffect, useState } from "react";
import registerImage from "../../../assets/images/register.jpeg";
import SimpleInput from "../../../components/Input/Input";
import { Button } from "../../../components/Button/Button";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../store/actions/authActions";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Stack } from "@mui/material";
import { loginValidationSchema } from "../../schema/login";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const error = useSelector((state) => state.auth.error);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  const formHandler = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: loginValidationSchema,

    onSubmit: async (values) => {
      setloading(true);
      await dispatch(login(values));

      setloading(false);
      // Navigate only if login is successful
      if (isAuthenticated) {
        navigate("/");
      }
    },
  });

  return (
    <div className="w-full flex justify-center bg-gray-100 ">
      <div className="md:max-w-[1000px] md:bg-white bg-customs w-full flex h-screen  md:my-12 my-6">
        {/* Image Section - 50% width */}
        <div className="w-1/2  hidden sm:block">
          <img src={registerImage} alt="Register" className="w-full h-full object-cover" />
        </div>

        {/* Form Section - 50% width */}
        <div className="w-full md:w-1/2 flex justify-center p-5 items-start md:p-12 md:p-8">
          <div className="w-full md:p-8">
            <h1 className="text-lg md:text-2xl font-normal text-center md:text-left mb-4">Welcome</h1>
            <p className="mb-12 text-center text-xs md:text-md md:text-left">
              Not an account yet?{" "}
              <Link to={"/register"} className="text-[#4D90A7] hover:underline">
                Register now
              </Link>
            </p>

            <form onSubmit={formHandler.handleSubmit}>
              {/* Email / Username */}
              <div className="mb-4">
                <SimpleInput
                  type="text"
                  label={"Email / username"}
                  name="username"
                  className="w-full"
                  errormessage={formHandler.errors.username}
                  value={formHandler.values.username}
                  onChange={formHandler.handleChange}
                />
              </div>

              {/* Password */}
              <div className="mb-6 relative">
                <SimpleInput
                  type="password"
                  label={"Password"}
                  name="password"
                  errormessage={formHandler.errors.password}
                  value={formHandler.values.password}
                  onChange={formHandler.handleChange}
                />
              </div>

              {/* Register Button */}
              <div className="mb-12">
                <Stack>
                  {loading ? (
                    <div className="flex justify-center items-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-full h-[47px] bg-[#4D90A7] hover:bg-[#5198af] text-white rounded-lg"
                    >
                      Login
                    </Button>
                  )}
                </Stack>
              </div>
            </form>
            {/* Forgot Password */}
            <div className="text-center">
              <Link to="/" className="text-[#4D90A7] hover:underline">
                Forgot your password?
              </Link>
            </div>
            {/* Activate Online Account */}
            <div className="text-center mt-16">
              <span>
                Tipico Shop customer?
                <Link to="/" className="text-[#4D90A7] text-sm hover:underline">
                  {" "}
                  Activate Online Account
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
