import React, { useState } from "react";
export const Angebote = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("userData");
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    return (
        <div className="flex flex-col items-center w-full max-w-5xl mx-auto md:p-6">
            <h2 className="w-full text-lg font-semibold text-gray-700 mb-4 text-left hidden md:block">Meine Daten</h2>

            <div className="w-full bg-white rounded-lg shadow-lg p-6">
                <div className="flex border-b border-gray-200 mb-6">
                    <button
                        onClick={() => handleTabClick("userData")}
                        className={`pb-4 px-4 text-xs font-semibold ${activeTab === "userData" ? "border-b-2 border-red-500 " : "text-gray-600"}`}
                    >
                        Kullanıcı Verileri
                    </button>
                    <button
                        onClick={() => handleTabClick("contactInfo")}
                        className={`pb-4 px-4 text-xs  font-semibold ${activeTab === "contactInfo" ? "border-b-2 border-red-500 " : "text-gray-600"}`}
                    >
                        İletişim Bilgileri
                    </button>
                    <button
                        onClick={() => handleTabClick("changePassword")}
                        className={`pb-4 px-4 text-xs  font-semibold ${activeTab === "changePassword" ? "border-b-2 border-red-500 " : "text-gray-600"}`}
                    >
                        Parola Değiştir
                    </button>
                    <button
                        onClick={() => handleTabClick("changeSettings")}
                        className={`pb-4 px-4 text-xs  font-semibold ${activeTab === "changeSettings" ? "border-b-2 border-red-500 " : "text-gray-600"}`}
                    >
                        Parola Değiştir
                    </button>
                </div>

                {/* Tab İçerikleri */}
                <div className={` ${activeTab === "userData" ? "block" : "hidden"}`}>
                    <a href="#" className="text-blue-500 mt-4 text-sm  block text-center">
                        Angebotsbedingungen ansehen
                    </a>
                </div>

                <div className={` ${activeTab === "contactInfo" ? "block" : "hidden"}`}>
                    <div className="w-[90%] mx-auto my-4">
                        <div
                            className="bg-white rounded-lg shadow-md p-4 cursor-pointer"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <div className="flex justify-between items-center">
                                <h2 className="font-semibold text-xs">Rückerstattung der risikofreien Wette</h2>
                                <span className="text-gray-500 text-sm">{isOpen ? 'GENUTZT ▲ ' : 'GENUTZT ▼'}</span>
                            </div>

                            {isOpen && (
                                <div className="mt-4">
                                    <div className="flex justify-between mb-2 text-sm">
                                        <span>Datum akzeptiert:</span>
                                        <span>14.12.2023 00:46</span>
                                    </div>
                                    <div className="flex justify-between mb-2 text-sm">
                                        <span>Ablaufdatum:</span>
                                        <span>21.12.2023 01:59</span>
                                    </div>
                                    <div className="flex justify-between mb-2 text-sm">
                                        <span>Mindestquote:</span>
                                        <span>1</span>
                                    </div>

                                    <div className="mt-4 border-t pt-2">
                                        <div className="flex justify-between mb-2 text-sm">
                                            <span>Umsatzbedingungen:</span>
                                            <span>10,00 €</span>
                                        </div>
                                        <div className="flex justify-between mb-2 text-sm">
                                            <span>Umgesetzter Betrag:</span>
                                            <span>10,00 €</span>
                                        </div>
                                        <div className="flex justify-between mb-2 text-sm">
                                            <span>Verbleibende Umsatzbedingungen:</span>
                                            <span>0,00 €</span>
                                        </div>

                                        <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                                            <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: '100%' }}></div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                        <a href="#" className="text-blue-500 mt-4 text-sm block text-center">
                            Angebotsbedingungen ansehen
                        </a>
                    </div>
                </div>

                <div className={` ${activeTab === "changePassword" ? "block" : "hidden"}`}>
                    <div className="w-[90%] mx-auto my-4">

                        <a href="#" className="text-blue-500 mt-4 text-sm block text-center">
                            Angebotsbedingungen ansehen
                        </a>
                    </div>

                </div>

                <div className={` ${activeTab === "changeSettings" ? "block" : "hidden"}`}>
                    <div className="w-[90%] mx-auto my-4">
                        <div
                            className="bg-white rounded-lg shadow-md p-4 cursor-pointer"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <div className="flex justify-between items-center">
                                <h2 className="font-semibold text-xs">Rückerstattung der risikofreien Wette</h2>
                                <span className="text-gray-500 text-sm">{isOpen ? 'GENUTZT ▲ ' : 'GENUTZT ▼'}</span>
                            </div>

                            {isOpen && (
                                <div className="mt-4">
                                    <div className="flex justify-between mb-2 text-sm">
                                        <span>Datum akzeptiert:</span>
                                        <span>14.12.2023 00:46</span>
                                    </div>
                                    <div className="flex justify-between mb-2 text-sm">
                                        <span>Ablaufdatum:</span>
                                        <span>21.12.2023 01:59</span>
                                    </div>
                                    <div className="flex justify-between mb-2 text-sm">
                                        <span>Mindestquote:</span>
                                        <span>1</span>
                                    </div>

                                    <div className="mt-4 border-t pt-2">
                                        <div className="flex justify-between mb-2 text-sm">
                                            <span>Umsatzbedingungen:</span>
                                            <span>10,00 €</span>
                                        </div>
                                        <div className="flex justify-between mb-2 text-sm">
                                            <span>Umgesetzter Betrag:</span>
                                            <span>10,00 €</span>
                                        </div>
                                        <div className="flex justify-between mb-2 text-sm">
                                            <span>Verbleibende Umsatzbedingungen:</span>
                                            <span>0,00 €</span>
                                        </div>

                                        <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                                            <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: '100%' }}></div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                        <a href="#" className="text-blue-500 mt-4 text-sm block text-center">
                            Angebotsbedingungen ansehen
                        </a>
                    </div>

                </div>
            </div>
        </div>
    );
};
