import { useCallback } from "react";
import { Matches } from "./MatchesRow";
import { Button } from "../Button/Button";

export const LiveMatchesBody = ({
  betTab,
  selectedOddsIds,
  showDetail,
  setSelectedOddsIds,
  setShowDetail,
  dropDownCount,
}) => {
  const handleComboboxValuesChange = useCallback(
    (values) => {
      const filteredValues = values.filter((value) => value !== null && value !== "");
      setSelectedOddsIds(filteredValues);
    },
    [setSelectedOddsIds]
  );
  return (
    <div className="grid">
      <div className={`col-md-7 transition-transform duration-1000 ease-in-out transform translate-x-0`}>
        <div className=" shadow-xl ">
          <div className="bg-white w-full">
            <div className="grid grid-cols-12 border-b border-gray-300">
              <Matches
                betTab={betTab}
                selectedOddsIds={selectedOddsIds}
                handleDetail={setShowDetail}
                showDetail={showDetail}
                dropDownCount={dropDownCount}
                handleComboboxValuesChange={handleComboboxValuesChange}
              />
              {/* <div className="col-span-12 flex justify-center items-center">
                <Button>Nrxt Pagr </Button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
