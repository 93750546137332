import axios from "axios";
import { LASTMIN_SUCCESS, LASTMIN_FAILURE } from "./actiontypes";
import { last_min } from "../paths";

export const LastMinApi = (body) => async (dispatch) => {
  try {
    const response = await axios.post(last_min, body);
    dispatch({
      type: LASTMIN_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    dispatch({
      type: LASTMIN_FAILURE,
      payload: error.response?.data?.message || "api failed. Please try again.",
    });
  }
};
