import { useCallback, useMemo, useState } from "react";
import CustomSwitch from "../../../components/CustomSwitch";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { initializeWebSocket, LiveMatchesApi } from "../../../store/actions/live_matches";
import { sportsApi } from "../../../store/actions/sportsNav";
import { LiveMatchesBody } from "../../../components/LiveBetting/LivePageBody";
import { DetailCard2 } from "../../../components/LiveBetting/DetailCard2";
import ComboboxGroup from "../../../components/LiveBetting/ComboboxGroup";
import { PiSlidersHorizontalLight } from "react-icons/pi";
import { PopupMatchCombi } from "../../../components/Dialog/PopupMatchCombi";
import { isEqual } from "lodash";
import { Button } from "../../../components/Button/Button";

export default function LiveBetting({}) {
  const dispatch = useDispatch();
  const [betTab, setBetTab] = useState(9999);
  const [selectedOddsIds, setSelectedOddsIds] = useState([]);
  const [showDetail, setShowDetail] = useState(null);
  const [dropDownCount, setDropDownCount] = useState(5);
  const maxDropDownCount = useSelector((state) => state?.livematches?.dropDownCount);
  const [showSwitch, setShowSwitch] = useState(false);
  const [currentPage, setCurrentPage] = useState({ page: 0 });

  const updateDropDownCount = () => {
    const width = window.innerWidth;
    let count;

    if (width < 650) {
      count = 1;
    } else if (width < 1000) {
      count = 2;
    } else if (width < 1250) {
      count = 3;
    } else if (width < 1500) {
      count = 4;
    } else {
      count = maxDropDownCount; // 800 ve üzeri için
    }
    if (showDetail) {
      count -= 1;
    }
    setDropDownCount(count);
  };

  const handleComboboxValuesChange = useCallback(
    (values) => {
      const filteredValues = values.filter((value) => value !== null && value !== "");
      setSelectedOddsIds(filteredValues);
    },
    [setSelectedOddsIds]
  );

  useEffect(() => {
    updateDropDownCount();
    window.addEventListener("resize", updateDropDownCount);
    return () => {
      window.removeEventListener("resize", updateDropDownCount);
    };
  }, [maxDropDownCount, showDetail]);

  const counts = useSelector((state) => state?.apisport?.counts);
  const sportsArray = useMemo(() => {
    if (!counts || counts.length === 0) {
      return [];
    }
    const array = [...counts.filter((e) => e.length)];
    const totalLength = array.reduce((sum, sport) => Number(sum) + Number(sport.length), 0);
    array.push({ id: 9999, name: "AllBalls", length: totalLength });
    const minBetTab = Math.min(...array.map((e) => e.id));
    if (betTab || !isEqual(betTab, minBetTab)) {
      setBetTab(minBetTab);
    }
    return array;
  }, [counts]);

  // useEffect(() => {
  //   setCurrentPage({ page: 0 });
  // }, [betTab]);

  useEffect(() => {
    dispatch(
      LiveMatchesApi({
        pageNumber: 0,
        pageSize: 100,
        sport_ids: betTab === 9999 ? undefined : [betTab],
      })
    );
  }, [currentPage, betTab]);

  useEffect(() => {
    dispatch(sportsApi());
    const socket = dispatch(initializeWebSocket());
    return () => {
      socket.close();
    };
  }, [dispatch]);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <div className="grid grid-cols-12">
      <div className={`${showDetail ? "col-span-9" : "col-span-12 "} bg-custom  overflow-auto scrollbar-hide`}>
        <div className="flex items-center w-full overflow-x-auto bg-white md:hidden">
          <div
            className="flex-shrink-0 cursor-pointer bg-white border-r flex flex-col items-center justify-center w-2/12 h-16"
            onClick={() => setShowSwitch(!showSwitch)}
          >
            <PiSlidersHorizontalLight className="text-3xl font-bold" />
          </div>
          {showSwitch && (
            <div>
              <CustomSwitch />
            </div>
          )}
          {sportsArray.map((entries) => {
            return (
              <div
                key={entries.id}
                onClick={() => {
                  setBetTab(entries.id);
                }}
                className={`flex-shrink-0 cursor-pointer flex flex-col py-2 bg-white justify-between items-center w-2/12 h-16 ${
                  betTab === entries.id ? "border-b-2 border-b-red-700" : "bg-livePageTop"
                }`}
              >
                <div className="flex  gap-1">
                  <img
                    style={{ textAlign: "center", marginLeft: "14px" }}
                    src={"http://38.180.122.61/images/sports/" + entries.name.toLowerCase().replace(" ", "-") + ".png"}
                    alt={entries.name}
                    width={30}
                    height={30}
                  />
                  <span style={{ fontSize: "11px" }} className="text-xs text-gray-500 font-extralight">
                    {entries.length}
                  </span>
                </div>
                <span
                  style={{ fontSize: "11px" }}
                  className="flex w-full text-xs text-gray-500 font-semibold justify-center"
                >
                  {entries.name}
                </span>
              </div>
            );
          })}
        </div>

        <div className="flex items-center w-full overflow-x-auto gap-1.5 bg-custom md:mt-12 md:flex hidden">
          {sportsArray.map((entries) => {
            return (
              <div
                key={entries.id}
                onClick={() => {
                  setBetTab(entries.id);
                }}
                className={`flex-shrink-0 cursor-pointer flex flex-col py-2 justify-between grid-cols-1 my-1 rounded-md items-end w-16 h-16 ${
                  betTab === entries.id
                    ? "bg-white border-b-2 border-b-red-700"
                    : "bg-livePageTop shadow-sm shadow-gray-400"
                }`}
              >
                <div className="flex justify-end gap-1 px-2">
                  <img
                    style={{ textAlign: "center" }}
                    src={"http://38.180.122.61/images/sports/" + entries.name.toLowerCase().replace(" ", "-") + ".png"}
                    alt={entries.name}
                    width={25}
                    height={25}
                  />
                  <span style={{ fontSize: "11px" }} className="text-xs text-gray-500 font-extralight">
                    {entries.length}
                  </span>
                </div>
                <span
                  style={{ fontSize: "11px" }}
                  className="flex w-full text-xs text-gray-500 font-semibold justify-center"
                >
                  {entries.name}
                </span>
              </div>
            );
          })}
        </div>

        <div className={`flex items-center text-xs w-full gap-4 hidden md:flex  py-4`}>
          <span>Sort by:</span>
          <CustomSwitch />
        </div>
        <div className="sticky flex md:hidden mt-2 mb-2 justify-between bg-custom top-0 gap-3 z-0 col-span-7">
          <div className="flex gap-4  place-content-center">
            <ComboboxGroup
              dropDownCount={dropDownCount}
              hasEmptyLabel={false}
              onValuesChange={handleComboboxValuesChange}
            />
          </div>
        </div>
        <div className="grid">
          <LiveMatchesBody
            betTab={betTab}
            showDetail={showDetail}
            setShowDetail={setShowDetail}
            selectedOddsIds={selectedOddsIds}
            setSelectedOddsIds={setSelectedOddsIds}
            dropDownCount={dropDownCount}
            imgSource={
              sportsArray.find((sport) => sport.id === String(betTab))
                ? sportsArray.find((sport) => sport.id === String(betTab)).name
                : "AllBalls"
            }
          />
        </div>
        {/* <Button onClick={handleNextPage}>Next Page</Button> */}
      </div>
      {showDetail ? (
        <>
          {/* Sadece mobilde tam ekran göster */}
          <div className="fixed inset-0 z-50 flex justify-center items-center bg-custom overflow-auto scrollbar-hide md:hidden">
            <div className="w-full h-full">
              <DetailCard2 data={showDetail} hndleDetail={setShowDetail} />
            </div>
          </div>
          {/* Büyük ekranlar için sağda sabit alan */}
          <div
            className={`${showDetail ? "col-span-3 flex justify-center" : ""} bg-custom overflow-auto scrollbar-hide  `}
          >
            <div
              className={`fixed mt-11 h-[100vh] col-md-5 transition-transform duration-1000 ease-in-out transform ${
                showDetail ? "translate-x-1" : "translate-x-12"
              }`}
            >
              {showDetail && <DetailCard2 data={showDetail} hndleDetail={setShowDetail} />}
            </div>
          </div>
        </>
      ) : null}

      <PopupMatchCombi />
    </div>
  );
}
