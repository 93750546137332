// src/layouts/AuthLayout.js
import React from 'react';
import Header from '../../components/Headers/Header';
// import { Outlet } from 'react-router-dom';
import AuthFooter from '../../components/AuthComponents/AuthFooter';
import { BottomAppbar } from '../../components/BottomAppbar';



const DefaultLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen font-Source-Code-Pro">
      {/* Uncomment to display Header */}
      <Header />
      <main className="flex-1 md:mt-14">
        {/* <Outlet/> */}
        {children}
      </main>
      <BottomAppbar />
      <AuthFooter />
    </div>
  );
};

export default DefaultLayout;
