export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const MATCHES_SUCCESS = "MATCHES_SUCCESS";
export const MATCHES_FAILURE = "MATCHES_FAILURE";

export const SPORTS_SUCCESS = "SPORTS_SUCCESS";
export const SPORTS_FAILURE = "SPORTS_FAILURE";

export const TOPMATCHES_SUCCESS = "TOPMATCHES_SUCCESS";
export const TOPMATCHES_FAILURE = "TOPMATCHES_FAILURE";
export const TOPMATCHES_WEBSOCKET_UPDATE_ODDS_2 = "TOPMATCHES_WEBSOCKET_UPDATE_ODDS_2";

export const CALCULATE_BETSLIP_SUCCESS = "CALCULATE_BETSLIP_SUCCESS";
export const CALCULATE_BETSLIP_FAILURE = "CALCULATE_BETSLIP_FAILURE";

export const PLACEBETSLIP_SUCCESS = "PLACEBETSLIP_SUCCESS";
export const PLACEBETSLIP_FAILURE = "PLACEBETSLIP_FAILURE";

export const LASTMIN_SUCCESS = "LASTMIN_SUCCESS";
export const LASTMIN_FAILURE = "LASTMIN_FAILURE";

export const SHOW_DETAIL = "SHOW_DETAIL";
export const SHOW_DETAIL_HOME = "SHOW_DETAIL_HOME";

export const TOPWINNER_SUCCESS = "TOPWINNER_SUCCESS";
export const TOPWINNER_FAILURE = "TOPWINNER_FAILURE";

export const LIVEMATCHES_SUCCESS = "LIVEMATCHES_SUCCESS";
export const LIVEMATCHES_REMOVE = "LIVEMATCHES_REMOVE";
export const LIVEMATCHES_FAILURE = "LIVEMATCHES_FAILURE";

export const LIVEMATCHES_WEBSOCKET_UPDATE_ODDS_1 = "LIVEMATCHES_WEBSOCKET_UPDATE_ODDS_1";

export const LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES = "LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES";

export const LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES_SCORE = "LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES_SCORE";

export const CALCULATE_ODD = "CALCULATE_ODD";

export const REMOVE_ODD = "REMOVE_ODD";

export const CLEAR_ODD = "CLEAR_ODD";

export const FILTERED_HOME_SUCCESS = "FILTERED_HOME_SUCCESS";
export const FILTERED_HOME_FAILURE = "FILTERED_HOME_FAILURE";

export const TOPCOMPETITION_SUCCESS = "TOPCOMPETITION_SUCCESS";
export const TOPCOMPETITION_FAILURE = "TOPCOMPETITION_FAILURE";
