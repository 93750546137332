export function sortAvailableOdds(availableOddsMap) {
  return Object.fromEntries(
    Object.entries(availableOddsMap).map(([key, value]) => {
      const sortedCaptions = value.captions.sort((a, b) => {
        const order = { Under: 1, Over: 0 };
        if (order[a] !== undefined || order[b] !== undefined) {
          return (order[a] || 2) - (order[b] || 2);
        }
        const valueA = a.charAt(0) === "X" ? 1.5 : parseFloat(a) || a;
        const valueB = b.charAt(0) === "X" ? 1.5 : parseFloat(b) || b;
        return valueA - valueB;
      });
      return [key, { ...value, captions: sortedCaptions }];
    })
  );
}
