import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateRequestBody } from "../../store/actions/homeTypes";
import { TopCompetitionApi } from "../../store/actions/topCompetition";
import { setSelectedItem } from "../../store/actions/sidebarActions";

export const DynamicLeftMenus = ({ activeMenu }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetch("http://38.180.122.61:3001/api/menu/navigation-tree")
      // fetch(process.env.PUBLIC_URL + "/navigationTree.json")
      .then((response) => response.json())
      .then((data) => {
        setMenus({ children: data });
        setFilteredMenus({ children: data });
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    dispatch(TopCompetitionApi());
  }, [dispatch]);

  const [menus, setMenus] = useState(null);
  const [activeTab, setActiveTab] = useState("All");
  const [filteredMenus, setFilteredMenus] = useState(null);
  const [tabList, setTabList] = useState([]);
  const topCompetitions = useSelector((state) => state?.topCompetition?.data);

  useEffect(() => {
    const filterChildren = (children) => {
      if (!activeTab || !menus) {
        return [];
      }
      return children
        .map((child) => {
          const filteredChildren = filterChildren(child.children);
          if (
            child.meta.some(
              (metaItem) => metaItem.title === activeTab && metaItem.value !== 0
            ) ||
            filteredChildren.length > 0
          ) {
            return {
              ...child,
              children: filteredChildren,
            };
          }
          return null;
        })
        .filter(Boolean);
    };
    const filteredData = {
      ...menus,
      children: filterChildren(menus?.children),
    };
    setFilteredMenus({ ...filteredData });
  }, [activeTab, menus]);

  const onSelectionChange = (selection) => {
    dispatch(setSelectedItem(selection));
    dispatch(updateRequestBody(selection));
  };

  useEffect(() => {
    let tabList2 = [];
    menus?.children.forEach((e) => {
      e.meta.forEach((meta) => {
        const exists = tabList2.some((tab) => tab.title === meta.title);
        if (!exists) {
          tabList2.push({ title: meta.title, value: meta.title });
        }
      });
    });
    setTabList(tabList2);
  }, [menus]);

  if (!menus) {
    return (
      <div className="shadow rounded-md p-4 max-w-sm w-64 mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded-full bg-slate-200 h-10 w-10"></div>
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-200 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-slate-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <h6 className="text-gray-400 font-semibold text-xs py-4">Top Competitions</h6>
      <ul className="space-y-3">
        <TopCompetition
          data={topCompetitions}
          onSelectionChange={onSelectionChange}
        />
      </ul>
      <div className="relative mt-6">
        <div className="flex items-center gap-2 mb-4 pr-12 z-20 w-72">
          {tabList.slice(0, 4).map((item, i) => (
            <div
              key={i}
              className={`flex items-center justify-center bg-customs font-semibold cursor-pointer h-7 text-center text-xs text-gray-500 w-12 ${activeTab === item.title
                  ? "bg-white border-b-2 shadow-custom rounded-sm border-red-700"
                  : "hover:text-black"
                }`}
              onClick={() => setActiveTab(item.title)}
            >
              {item.title}
            </div>
          ))}
        </div>
        <h6 className="text-gray-400 font-semibold text-xs mb-4">Top-Sport</h6>
        <ul className="space-y-2">
          <MenuItems
            data={filteredMenus}
            onSelectionChange={onSelectionChange}
            activeTab={activeTab}
          />
        </ul>
      </div>

    </>
  );
};

const TopCompetition = ({ data, onSelectionChange }) => {
  return data?.map((e, index) => {
    return (
      <li className="cursor-pointer mb-2" key={index}>
        <div
          className="flex gap-3.5"
          onClick={() => onSelectionChange([{ tournamentId: e.tournament_id }])}
        >
          <img src={e.icon} alt={e.icon} className="w-6 h-4" />
          <span className="text-xs md:text-sm">{e.group_name}</span>
        </div>
      </li>
    );
  });
};

const MenuItems = ({ data, onSelectionChange, activeTab }) => {
  const [menuData, setMenuData] = useState(null);
  useEffect(() => {
    setMenuData(data);
  }, [data]);
  const handleSelectItem = (id) => {
    const updateCheckedStatus = (data) => {
      if (data.id === id) {
        const checked = !data.checked;
        data.checked = checked;

        if (data.children) {
          data.children.forEach((element) => {
            element.checked = checked;
          });
        }
        return true;
      }
      if (data.children) {
        for (let child of data.children) {
          if (updateCheckedStatus(child)) {
            updateCheckStatusParent(data);
            return true;
          }
        }
      }
      return false;
    };

    const updateCheckStatusParent = (data) => {
      const allFalse = data.children.every((child) => !child.checked);
      const allTrue = data.children.every((child) => child.checked);

      data.checked = allTrue;
      data.indeterminate = !allFalse && !allTrue;
    };

    const gatherCheckedGroupIds = (data, checkedIds = []) => {
      if (data.checked) {
        if (data.tree_key === "tournament") {
          checkedIds.push({
            tournamentId: data.tree_value,
            title: data.name,
          });
        } else if (data.tree_key === "country") {
          checkedIds.push({
            countryId: data.tree_value,
            title: data.name,
          });
        }
      }
      if (data.children) {
        data.children.forEach((child) => {
          gatherCheckedGroupIds(child, checkedIds);
        });
      }
      return checkedIds;
    };

    const newData = { ...menuData };
    updateCheckedStatus(newData);
    setMenuData(newData);
    onSelectionChange(gatherCheckedGroupIds(newData));
  };

  return menuData?.children.map((menu, index) => (
    <BaseMenuItem
      key={index}
      menu={menu}
      handleSelectItem={handleSelectItem}
      activeTab={activeTab}
    />
  ));
};

const BaseMenuItem = ({ menu, handleSelectItem, activeTab }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const displayedChildren = showAll ? menu.children : menu.children.slice(0, 5);
  const icon = menu.icon.includes("https://")
    ? menu.icon
    : "http://38.180.122.61/images/sports/" +
    (menu.icon + "").toLowerCase().replace(" ", "-") +
    ".png";
  return (
    <li className="cursor-pointer mb-2">
      <div className="flex gap-2.5" onClick={toggleExpand}>
        <img src={icon} alt={menu.name} width={20} height={20} />
        <span className="text-xs md:text-sm">{menu.name}</span>
      </div>

      {isExpanded && menu.children && menu.children.length !== 0 && (
        <ul className="mt-2 block bg-featuresBg rounded">
          {displayedChildren.map((child, index) => (
            <MenuItem
              key={index}
              child={child}
              handleSelectItem={handleSelectItem}
              activeTab={activeTab}
            />
          ))}

          {menu.children.length > 5 && !showAll && (
            <li>
              <button
                onClick={toggleShowAll}
                className="text-black font-semibold text-sm h-10 border-t-2 border-customs w-full pl-24 justify-end"
              >
                More {menu.children.length} ᐯ
              </button>
            </li>
          )}

          {showAll && (
            <li>
              <button
                onClick={toggleShowAll}
                className="text-black font-semibold text-sm h-10 border-t-2 border-customs w-full pl-24 justify-end"
              >
                Show Less ᐱ
              </button>
            </li>
          )}
        </ul>
      )}
    </li>
  );
};

const MenuItem = ({ child, handleSelectItem, activeTab }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const checkboxRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = child.indeterminate;
    }
  }, [child.indeterminate]);
  const icon = child.icon.includes("https://")
    ? child.icon
    : "http://38.180.122.61/images/sports/" +
    (child.icon + "").toLowerCase().replace(" ", "-") +
    ".png";
  return (
    <li className="pt-1 bg-transparent ">
      <div className="flex items-center justify-between ">
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="flex items-center ml-10 justify-between"
        >
          <div className="flex items-center">
            <input
              type="checkbox"
              onChange={() => handleSelectItem(child.id)}
              onClick={() => setIsExpanded(!child.checked)}
              checked={child.checked || false}
              ref={checkboxRef}
              className={` w-6 h-4 ${isHovered ||
                  child.checked ||
                  child.indeterminate ||
                  !child.children ||
                  child.children.length === 0
                  ? "block"
                  : "hidden"
                }`}
            />

            <img
              onClick={toggleExpand}
              src={icon}
              alt={child.name}
              className={`w-6 h-4 ${isHovered ||
                  child.checked ||
                  child.indeterminate ||
                  !child.children ||
                  child.children.length === 0
                  ? "hidden"
                  : "block"
                }`}
            />

            <span onClick={toggleExpand} className="text-xs md:text-sm ml-1">
              {child.name}
            </span>
          </div>
        </div>

        {child.children.length === 0 && (
          <div className="bg-transparent text-xs text-gray-500">
            {child.meta.filter((e) => e.title === activeTab)[0].value}
          </div>
        )}
      </div>
      {isExpanded && child.children && child.children.length !== 0 && (
        <ul className="ml-4 mt-2 block">
          {child.children.map((child2, index) => (
            <MenuItem
              key={index}
              child={child2}
              handleSelectItem={handleSelectItem}
              activeTab={activeTab}
            />
          ))}
        </ul>
      )}
    </li>
  );
};
