import axios from 'axios';
import { REGISTER_SUCCESS, REGISTER_FAILURE } from './actiontypes';
import {Registration_URL} from '../paths'
import { toast } from 'react-toastify';



export const registerAction = (credentials) => async (dispatch) => {
  try {
   
    const response = await axios.post(Registration_URL, credentials);

    if (response.data) {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: {
          user: response.data
        },
      });
      toast.success("Thank you For the Registration!")

    } else {
        toast.error('Registration failed')
      dispatch({
        type: REGISTER_FAILURE,
        payload: response.data.message || 'Registration failed',
      });
      
    }
  } catch (error) {
    toast.error('Registration failed')
    dispatch({
      type: REGISTER_FAILURE,
      payload: error.response?.data?.message || 'Registration failed. Please try again.',
    });
  }
};
