 const toggleSidebar = () => {
    return {
      type: 'TOGGLE_SIDEBAR',
    };
  };
  
   const setSelectedItem = (item) => {
    return {
      type: 'SET_SELECTED_ITEM',
      payload: item, // Payload will be the selected item
    };
  };



  export {toggleSidebar,setSelectedItem}