import { useDispatch } from "react-redux";
import { calculateOdds, removeOdd } from "../../store/actions/homeTypes";

export const useHandleOddsClick = () => {
  const dispatch = useDispatch();

  const handleOddsClick = ({ isSelected = false, match, odd }) => {
    if (isSelected) {
      dispatch(
        removeOdd({ id: `${match.id}-${odd.market_id}-${odd.outcome_id}` })
      );
    } else {
      dispatch(
        calculateOdds({
          id: match.id + "-" + odd.market_id + "-" + odd.outcome_id,
          matchId: match.id,
          home_team_name: match.home_team_name,
          away_team_name: match.away_team_name,
          oddsId: odd.id,
          option: odd.name,
          value: odd.value,
          line: odd.line,
          market_name: odd.market_name,
        })
      );
    }
  };

  return handleOddsClick;
};
