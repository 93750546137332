import React, { useState } from "react";
import Header from "../../components/Headers/Header";
import UserPageHeader from "../../components/Headers/UserPageHeader";
import { BottomAppbar } from "../../components/BottomAppbar";
import UserPageSidebar from "../pages/user-page/userPageSidebar";
import { UserMain } from "../pages/user-page/userMain";
import { Account } from "../pages/user-page/accountVerification";
import { Transaktionsverlauf } from "../pages/user-page/transaktionsverlauf";
import { LimitsClosure } from "../pages/user-page/limitClosure";
import { Limitverlauf } from "../pages/user-page/limitverlauf";
import { Guthaben } from "../pages/user-page/guthaben";
import { Angebote } from "../pages/user-page/angebote";
import { Settings } from "../pages/user-page/settings";
import { Navigate } from "react-router-dom";

const UserLayout = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState("Meine Daten");

  return (
    <div className="flex flex-col min-h-screen font-Source-Code-Pro">
      <div className="md:hidden">
        <UserPageHeader activeMenu={activeMenu} /> 
      </div>
      <div className="hidden md:block">
        <Header /> 
      </div>
      <div className="flex flex-grow md:mt-14  bg-customs">
        <UserPageSidebar setActiveMenu={setActiveMenu} />
        <main className="flex  overflow-x-auto  w-full  place-content-center">
          {SelectTab({ activeMenu: activeMenu })}
        </main>
      </div>
    </div>

  );
};

const SelectTab = ({ activeMenu }) => {
  if (activeMenu === "Meine Daten") {
    return <UserMain />;
  }
  if (activeMenu === "Kontoverifizierung") {
    return <Account />;
  }
  if (activeMenu === "Transaktionsverlauf") {
    return <Transaktionsverlauf />;
  }
  if (activeMenu === "Limits & Schließung") {
    return <LimitsClosure />;
  }
  if (activeMenu === "Limitverlauf") {
    return <Limitverlauf />;
  }
  if (activeMenu === "Guthaben") {
    return <Guthaben />;
  }
  if (activeMenu === "Angebote") {
    return <Angebote />;
  }
  if (activeMenu === "Auszahlung") {
    return <Navigate to="/payout" replace />;
  }
  if (activeMenu === "Einstellungen") {
    return <Settings />;
  }
  else {
    return <div>TESTTSSS</div>;
  }
};

export default UserLayout;
