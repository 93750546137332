import {
  TOPCOMPETITION_FAILURE,
  TOPCOMPETITION_SUCCESS,
} from "../actions/actiontypes";

const initialState = {
  lastUpdateTime: null,
  data: null,
  error: null,
};

export const TopCompetitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOPCOMPETITION_SUCCESS:
      return {
        ...state,
        lastUpdateTime: Date.now(),
        data: action.payload.data,
      };
    case TOPCOMPETITION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
