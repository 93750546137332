import React, { forwardRef, useState } from "react";
import clsx from "clsx";
import { IoEyeSharp, IoEyeOffSharp } from "react-icons/io5"; // Ensure you have react-icons installed

const SimpleInput = forwardRef(({ label, type, errormessage, className, ...props }, ref) => {
  const [isVisible, setIsVisible] = useState(type !== "password");

  const toggleVisibility = () => setIsVisible(!isVisible);
  return (
    <div className="mb-2">
      <label
        className={clsx("block text-black font-normal leading-[21.97px] mb-2 text-base", { "text-center": false })}
        htmlFor={label}
      >
        {label}
      </label>
      <div className="flex items-center relative">
        <input
          id={label}
          {...props}
          ref={ref}
          type={type === "password" ? (isVisible ? "text" : "password") : type}
          className={clsx(
            "appearance-none w-full rounded-md bg-gray-100 border py-2.5 px-[15px] focus:outline-none text-sm focus:shadow-outline placeholder-text-black placeholder:font-normal leading-[22px] h-11",
            errormessage ? "border-[#b3b2b2]" : "border-[#b3b2b2]"
          )}
        />
        {type === "password" && (
          <div className="right-4 absolute cursor-pointer">
            {isVisible ? (
              <span className="flex text-xs items-center gap-1 text-gray-400 font-bold" onClick={toggleVisibility}>
                <IoEyeOffSharp className="w-6 h-6" />
                HIDING
              </span>
            ) : (
              <span className="flex text-xs items-center gap-1 text-gray-400 font-bold" onClick={toggleVisibility}>
                <IoEyeSharp className="w-6 h-6" />
                SHOWING
              </span>
            )}
          </div>
        )}
      </div>
      {errormessage && <span className="text-red-500 text-xs">{errormessage}</span>}
    </div>
  );
});

export default SimpleInput;
