import axios from "axios";
import { TOPCOMPETITION_SUCCESS, TOPCOMPETITION_FAILURE } from "./actiontypes";
import { top_competition } from "../paths";

export const TopCompetitionApi = () => async (dispatch, getState) => {
  const state = getState();
  const { lastUpdateTime } = state.topCompetition;
  if (lastUpdateTime && Date.now() - lastUpdateTime < 25000) {
    return;
  }

  try {
    const response = await axios.get(top_competition);
    dispatch({
      type: TOPCOMPETITION_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    dispatch({
      type: TOPCOMPETITION_FAILURE,
      payload: error.response?.data?.message || "API failed. Please try again.",
    });
  }
};
