import { useDispatch, useSelector } from "react-redux";
import { TMOddButtons } from "./TMCustomOddButtons";
import React, { memo } from "react";
import moment from "moment";
import { TMOddsNameGroup } from "./TMOddsNameGroup";
import { showDetailHome } from "../../store/actions/homeTypes";
import { Statistic } from "../../components/LandingPage/liveIcons";
import { shirt1, shirt2 } from "../../assets/images";

const Minutes = memo(({ time }) => {
  return (
    <div className={`col-span-2 flex place-content-center flex-col w-20  text-gray-600 overflow-hidden text-[11px]`}>
      <span className="w-full mb-1 whitespace-nowrap truncate">{moment(time * 1000).format("dddd")}</span>
      <span className="whitespace-nowrap truncate">{moment(time * 1000).format("hh:mm")}</span>
    </div>
  );
});

const MatchItem = memo(({ item, selectedOddsIds }) => {
  const dispatch = useDispatch();

  const odds = useSelector((state) => state?.topmatches?.odds);
  const hndleDetail = () => {
    dispatch(
      showDetailHome({
        match: item,
        time: item.match_time,
        odds: odds,
      })
    );
  };
  return (
    <div
      key={item.id}
      className={
        "col-span-12 pl-4 grid grid-cols-12 lg:grid-cols-12 cursor-pointer hover:bg-gray-100 items-center px-3 border-b border-gray-300"
      }
    >
      <div onClick={hndleDetail} className={"col-span-12 md:col-span-4 grid grid-cols-12"}>
        <Minutes time={item.match_time} />

        <div className="flex justify-between col-span-10">
          <div className="flex flex-col">
            <div className="grid grid-cols-[auto,1fr] items-center gap-2 truncate">
              <img
                className="text-center item-center"
                src={item.home_team_logo ? item.home_team_logo : shirt1}
                alt=""
                width={15}
                height={15}
              />
              <span className="text-sm">
                {item.home_team_name.length > 10
                  ? item.home_team_name.substring(0, 20) + "..."
                  : item.home_team_name}
              </span>
            </div>
            <div className="grid grid-cols-[auto,1fr] items-center gap-2 truncate">
              <img
                className="text-center item-center"
                src={item.away_team_logo ? item.away_team_logo : shirt2}
                alt=""
                width={15}
                height={15}
              />
              <span className="text-sm">{item.away_team_name}</span>
            </div>
          </div>
          <div className=" md:hidden p-2 flex justify-end">
            <Statistic />
          </div>
        </div>
      </div>

      <div className={`col-span-12 md:col-span-8  grid grid-cols-12`}>
        <div className={`col-span-10 md:col-span-11 grid  grid-cols-${selectedOddsIds.length}  gap-3`}>
          <TMOddButtons item={item} selectedOddsIds={selectedOddsIds} />
        </div>
        <div onClick={hndleDetail} className={"col-span-2 md:col-span-1 items-center flex justify-end"}>
          <div className="flex justify-end">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16px"
                viewBox="0 -960 960 960"
                width="16px"
                fill="#B7B7B7"
              >
                <path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z" />
              </svg>
            </span>
          </div>
          <Details id={item.id} />
        </div>
      </div>
    </div>
  );
});

const Details = ({ id }) => {
  const oddCountsByMatchMap = useSelector((state) => state?.topmatches?.oddCountsByMatchMap);

  return (
    <div className="flex justify-start w-6">
      <span className="text-xs">{oddCountsByMatchMap[id]}</span>
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 -960 960 960" width="14px" fill="#CCCCCC">
          <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
        </svg>
      </span>
    </div>
  );
};

export const TMMatches = ({ betTab, selectedOddsIds, showDetail }) => {
  const matches = useSelector((state) => state?.topmatches?.matches);
  const tournamentMatchMap = useSelector((state) => state?.topmatches?.tournamentMatchMap);
  const availableTournamentsMapBySport = useSelector((state) => state?.topmatches?.availableTournamentsMapBySport);

  const selectionMode = useSelector((state) => state?.homereducer?.customSwitch);

  const filterMatches = (tournamentId) => {
    const tournamentMatches = tournamentMatchMap[tournamentId];
    if (!tournamentMatches) {
      console.log(`No matches found for tournamentId: ${tournamentId}`);
      return [];
    }

    const filteredMatches = matches?.filter((match) => tournamentMatches.includes(match.id));
    return filteredMatches || [];
  };

  if (!matches) {
    return <div>No matches found.</div>;
  }

  if (!betTab || !availableTournamentsMapBySport[betTab]) {
    return <div>No tournaments available</div>;
  }
  return availableTournamentsMapBySport[betTab].map((item, index) => {
    return (
      <div className={`grid-rows-${filterMatches(item.id).length} col-span-12`} key={index}>
        <div className=" hidden  md:block">
          <div
            className={`${showDetail ? "text-[10px]  pt-2 pb-1 font-semibold" : "text-xxs col-span-4 font-semibold"
              } lg:col-span-5 gap-1 pl-4 pr-4 grid grid-cols-12  row-span-1 bg-customs`}
          >
            <div className="col-span-4 flex items-center gap-2">
              <div className={`flex ${selectionMode ? "hidden" : "block"} items-center gap-2`}>
                {item.country_id} / {item.name}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="12px"
                  viewBox="0 -960 960 960"
                  width="12px"
                  fill="#676767"
                >
                  <path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z" />
                </svg>
              </div>
            </div>

            <div className={`${selectionMode ? "hidden" : "block"} col-span-8 grid grid-cols-12`}>
              <TMOddsNameGroup selectedOddsIds={selectedOddsIds} showDetail={showDetail} />
            </div>
          </div>
        </div>
        {filterMatches(item.id).map((match, index2) => {
          return (
            <div key={index2} className="row-span-1 grid grid-rows-1 col-span-12 h-24 md:h-14">
              <MatchItem item={match} selectedOddsIds={selectedOddsIds} />
            </div>
          );
        })}
      </div>
    );
  });
};
