import axios from 'axios';
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from './actiontypes';
import {Login_URL} from '../paths'
import { toast } from 'react-toastify';



export const login = (credentials) => async (dispatch) => {
  try {

    const response = await axios.post(Login_URL, credentials);


    if (response.data.token) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: response.data.user,
          token: response.data.token, 
        },
      });
      toast.success("Successfully login!")
      
    } else {

      dispatch({
        type: LOGIN_FAILURE,
        payload: response.data.message || 'Login failed',
      });
      toast.success("Login failed!")
    }
  } catch (error) {

    dispatch({
      type: LOGIN_FAILURE,
      payload: error.response?.data?.message || 'Login failed. Please try again.',
    });
    toast.success("Login failed. Please try again.")
  }
};

// Logout action creator
export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  localStorage.removeItem('authToken');
};