const initialState = {
  isOpen: false, // Sidebar open/close state
  selectedItem: null, // Track selected item
};
const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        isOpen: !state.isOpen, // Toggle sidebar state
      };
    case "SET_SELECTED_ITEM":
      return {
        ...state,
        selectedItem: action.payload, // Update selected item
      };
    default:
      return state;
  }
};

export default sidebarReducer;
