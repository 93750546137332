import React, { useState } from "react";
import './settings.css';

export const Settings = () => {
    // useState hooks for managing tab and toggles
    const [activeTab, setActiveTab] = useState("userData");
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const [showAccountBalance, setShowAccountBalance] = useState(true);
    const [acceptOddsChanges, setAcceptOddsChanges] = useState(false);
    const [autoWithdrawal, setAutoWithdrawal] = useState(true);

    return (
        <div className="flex flex-col items-center w-full max-w-5xl mx-auto md:p-6">
            {/* Header */}
            <h2 className="w-full text-lg font-semibold text-gray-700 mb-4 text-left hidden md:block">Meine Daten</h2>

            {/* Content Card */}
            <div className="w-full bg-white rounded-lg shadow-lg p-6">
                {/* Tab Menu */}
                <div className="flex border-b border-gray-200 mb-6">
                    <button
                        onClick={() => handleTabClick("userData")}
                        className={`pb-2 px-4 text-xs font-semibold ${activeTab === "userData" ? "border-b-2 border-red-500" : "text-gray-600"}`}
                    >
                        Sport
                    </button>
                </div>

                {/* Tab Content */}
                <div className={`${activeTab === "userData" ? "block" : "hidden"}`}>
                    <div className="space-y-4 ">
                        {/* Language Selection */}
                        {/* <div className="mb-4">
                            <label className="block text-gray-700">Language</label>
                            <select className="w-full mt-1 p-2 border rounded">
                                <option>English</option>
                                <option>Spanish</option>
                                <option>French</option>
                            </select>
                        </div> */}

                        {/* Time Zone Selection */}
                        <div className="mb-4">
                            <label className="block text-gray-700">Time zone</label>
                            <select className="w-full mt-1 p-2 border rounded">
                                <option>HST 22:22 (GMT -10)</option>
                                <option>PST 22:22 (GMT -8)</option>
                                <option>EST 22:22 (GMT -5)</option>
                                {/* Add more time zones as needed */}
                            </select>
                        </div>

                        {/* Show Account Balance Toggle */}
                        <div className="flex justify-between items-center">
                            <div>
                                <span className="text-lg font-semibold text-gray-700">Show Account Balance</span>
                                <p className="text-sm text-gray-500">Will show your current balance in the top navigation.</p>
                            </div>
                            <input
                                type="checkbox"
                                checked={showAccountBalance}
                                onChange={() => setShowAccountBalance(!showAccountBalance)}
                                className="toggle-checkbox"
                            />
                        </div>

                        <hr className="border-gray-300" />

                        {/* Odds Changes Toggle */}
                        <div className="flex justify-between items-center">
                            <div>
                                <span className="text-lg font-semibold text-gray-700">Odds changes</span>
                                <p className="text-sm text-gray-500">Automatically accept odds changes (higher and lower odds)</p>
                            </div>
                            <input
                                type="checkbox"
                                checked={acceptOddsChanges}
                                onChange={() => setAcceptOddsChanges(!acceptOddsChanges)}
                                className="toggle-checkbox"
                            />
                        </div>

                        <hr className="border-gray-300" />

                        {/* Automatic Withdrawal Toggle */}
                        <div className="flex justify-between items-center">
                            <div>
                                <span className="text-lg font-semibold text-gray-700">Automatic withdrawal</span>
                            </div>
                            <span className="text-blue-500 cursor-pointer" onClick={() => setAutoWithdrawal(!autoWithdrawal)}>
                                {autoWithdrawal ? 'On' : 'Off'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
