import React, { useState } from "react";

export const UserMain = () => {
    const [activeTab, setActiveTab] = useState("userData");
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [email, setEmail] = useState("");
    const [isEditingPhone, setIsEditingPhone] = useState(false);
    const [isEditingMobile, setIsEditingMobile] = useState(false);
    const [phone, setPhone] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");

    const storedUserData = JSON.parse(localStorage.getItem('authToken'));
    const username = storedUserData?.user?.user?.username;
    const role_name = storedUserData?.user?.user?.role_name;
    const id = storedUserData?.user?.user?.id;

    const handleEditPhoneClick = () => {
        setIsEditingPhone(true);
    };

    const handleCancelPhoneClick = () => {
        setIsEditingPhone(false);
        setPhone("");
    };

    const handleSavePhoneClick = () => {
        setIsEditingPhone(false);
        console.log("Telefon kaydedildi:", phone);
    };

    const handleEditMobileClick = () => {
        setIsEditingMobile(true);
    };

    const handleCancelMobileClick = () => {
        setIsEditingMobile(false);
        setMobilePhone("");
    };

    const handleSaveMobileClick = () => {
        setIsEditingMobile(false);
        console.log("Cep Telefonu kaydedildi:", mobilePhone);
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEmail("");
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        console.log("E-posta kaydedildi:", email);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };


    const handleSubmit = () => {
        const newError = {};

        if (!currentPassword) {
            newError.current = 'Mevcut şifre alanı boş bırakılamaz.';
        }
        if (!newPassword) {
            newError.new = 'Yeni şifre alanı boş bırakılamaz.';
        }
        if (!confirmPassword) {
            newError.confirm = 'Yeni şifreyi tekrar yazmalısınız.';
        }
        if (newPassword && confirmPassword && newPassword !== confirmPassword) {
            newError.match = 'Yeni şifreler eşleşmiyor.';
        }

        if (Object.keys(newError).length > 0) {
            setError(newError);
            return;
        }

        setError({});
        console.log('Şifre güncellendi:', newPassword);
    };

    return (
        <div className="flex flex-col items-center w-full max-w-5xl mx-auto md:p-6">
            {/* Başlık */}
            <h2 className="w-full text-lg font-semibold text-gray-700 mb-4 text-left hidden md:block">Meine Daten</h2>

            {/* İçerik Kartı */}
            <div className="w-full bg-white rounded-lg shadow-lg p-6">
                {/* Tab Menüsü */}
                <div className="flex border-b  border-gray-200 mb-6">
                    <button
                        onClick={() => handleTabClick("userData")}
                        className={`pb-4 px-4 text-xs text-nowrap font-semibold ${activeTab === "userData" ? "border-b-2 border-red-500 " : "text-gray-600"}`}
                    >
                        Kullanıcı Verileri
                    </button>
                    <button
                        onClick={() => handleTabClick("contactInfo")}
                        className={`pb-4 px-4 text-xs text-nowrap   font-semibold ${activeTab === "contactInfo" ? "border-b-2 border-red-500 " : "text-gray-600"}`}
                    >
                        İletişim Bilgileri
                    </button>
                    <button
                        onClick={() => handleTabClick("changePassword")}
                        className={`pb-4 px-4 text-xs text-nowrap font-semibold ${activeTab === "changePassword" ? "border-b-2 border-red-500 " : "text-gray-600"}`}
                    >
                        Parola Değiştir
                    </button>
                </div>

                {/* Tab İçerikleri */}
                <div className={`grid grid-cols-1 md:grid-cols-2 md:pb-16 md:pr-16 md:pl-16 gap-6 ${activeTab === "userData" ? "block" : "hidden"}`}>
                    <div className="space-y-2">
                        <label className="block text-gray-600 text-xs font-medium">Ad Soyad:</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded" disabled value={role_name} />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-gray-600 text-xs  font-medium">Kullanıcı Adı:</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded" disabled value={username} />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-gray-600 text-xs  font-medium">Doğumdaki Soyadınız:</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded" disabled />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-gray-600  text-xs font-medium">Doğum Tarihi:</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded" disabled />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-gray-600 text-xs  font-medium">E-Posta:</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            disabled={!isEditing}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-gray-600 text-xs  font-medium">Lugas Player ID:</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded" disabled value={id} />
                    </div>

                    <div className="col-span-1 md:col-span-2 flex justify-start mt-4 space-x-2">
                        {!isEditing ? (
                            <button
                                className="px-12 py-4 border border-combisColor text-combisColor rounded hover:bg-blue-50"
                                onClick={handleEditClick}
                            >
                                Düzen
                            </button>
                        ) : (
                            <>
                                <button
                                    className="px-4 py-4 border border-combisColor text-combisColor rounded hover:bg-blue-50"
                                    onClick={handleSaveClick}
                                >
                                    Değişiklikleri Kaydet
                                </button>
                                <button
                                    className="px-12 py-4 border border-combisColor text-combisColor rounded hover:bg-blue-50"
                                    onClick={handleCancelClick}
                                >
                                    İptal Et
                                </button>
                            </>
                        )}
                    </div>
                </div>

                <div className={`grid grid-cols-1 md:grid-cols-2 md:pb-16 md:pr-16 md:pl-16 gap-6 ${activeTab === "contactInfo" ? "block" : "hidden"}`}>
                    <div className="space-y-2">
                        <label className="block text-gray-600 text-xs  font-medium">Cep Telefonu (İsteğe Bağlı):</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            disabled={!isEditing}
                            value={mobilePhone}
                            onChange={(e) => setMobilePhone(e.target.value)}
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="block text-gray-600 text-xs font-medium">Doğduğu Şehir:</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded" disabled />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-gray-600 text-xs font-medium">Adres:</label>
                        <textarea className="w-full p-2 border border-gray-300 rounded" disabled></textarea>
                    </div>

                    <div className="space-y-2">
                        <label className="block text-gray-600 text-xs font-medium">Telefon (İsteğe Bağlı):</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 text-xs rounded"
                            disabled={!isEditing}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="block text-gray-600 text-xs font-medium">Doğduğu Ülke:</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded" disabled />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-gray-600 text-xs font-medium">Milliyet:</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded" disabled />
                    </div>
                    <div className="flex justify-start mt-6">
                        {!isEditing ? (
                            <button
                                className="px-12 py-4 border border-combisColor text-combisColor rounded hover:bg-blue-50"
                                onClick={handleEditClick}
                            >
                                Düzen
                            </button>
                        ) : (
                            <div className="flex space-x-2">
                                <button
                                    className="px-4 py-4 border border-combisColor text-combisColor rounded hover:bg-blue-50"
                                    onClick={handleSaveClick}
                                >
                                    Değişiklikleri Kaydet
                                </button>
                                <button
                                    className="px-10 py-4 border border-combisColor text-combisColor rounded hover:bg-blue-50"
                                    onClick={handleCancelClick}
                                >
                                    İptal Et
                                </button>
                            </div>
                        )}
                    </div>
                </div>



                <div className={`grid grid-cols-1 md:grid-cols-2 md:pb-16 md:pr-16 md:pl-16 gap-6 ${activeTab === "changePassword" ? "block" : "hidden"}`}>
                    {/* Şifre Inputları Sol Taraf */}
                    <div className="space-y-6">
                        <div className="space-y-2">
                            <label className="block text-gray-600 text-xs font-medium">Mevcut Şifre:</label>
                            <input
                                type="password"
                                className="w-full p-2 border border-gray-300 rounded"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                            {error.current && <p className="text-red-500 text-xs">{error.current}</p>}
                        </div>
                        <div className="space-y-2">
                            <label className="block text-gray-600 text-xs font-medium">Yeni Şifre:</label>
                            <input
                                type="password"
                                className="w-full p-2 border border-gray-300 rounded"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            {error.new && <p className="text-red-500 text-xs">{error.new}</p>}
                        </div>
                        <div className="space-y-2">
                            <label className="block text-gray-600 text-xs font-medium">Yeni Şifreyi Tekrarla:</label>
                            <input
                                type="password"
                                className="w-full p-2 border border-gray-300 rounded"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            {error.confirm && <p className="text-red-500 text-xs">{error.confirm}</p>}
                            {error.match && <p className="text-red-500 text-xs">{error.match}</p>}
                        </div>
                        <div className="flex justify-start mt-4">
                            <button
                                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                                onClick={handleSubmit}
                            >
                                Onaylamak
                            </button>
                        </div>
                    </div>

                    {/* Şifre Gereksinimleri Sağ Taraf */}
                    <div className="flex flex-col space-y-2 items-center">
                        <h3 className="text-gray-700 font-semibold mb-2">Şifreniz Gereksinimleri Karşılamalıdır</h3>
                        <ul className="text-gray-600 text-sm list-disc list-inside space-y-1">
                            <li>En az 8 karakter</li>
                            <li>Büyük ve küçük harflerin birleşimi</li>
                            <li>En az 2 rakam</li>
                            <li>Özel bir karakter (!"#$%&'*+./-…)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
