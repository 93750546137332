import React from "react";

export const Account = () => {
    return (
        <div className="flex justify-center w-full min-h-screen bg-gray-100 md:p-2">
            <div className="w-full max-w-lg md:max-w-2xl lg:max-w-3xl md:p-6">
                <h2 className="text-lg md:text-lg lg:text-lg font-semibold text-gray-700 mb-4 mt-5 text-center md:text-left">Hesabınız Onaylandı</h2>
                <div className="flex flex-col items-center p-4 sm:p-6 bg-white rounded-lg shadow-md">
                    <div className="flex items-center p-3 sm:p-4 bg-green-100 text-green-800 rounded-lg w-full mb-6">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 sm:h-6 sm:w-6 mr-2 text-green-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        <div>
                            <h3 className="font-medium text-gray-800 text-sm sm:text-base lg:text-lg">Hesabınız onaylandı.</h3>
                            <p className="text-xs sm:text-sm lg:text-base">Hesabınızı herhangi bir engel olmadan kullanabilirsiniz.</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-between bg-white p-3 sm:p-4 rounded-lg shadow w-full mb-4">
                        <div className="flex items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 sm:h-6 sm:w-6 text-gray-700"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path d="M18.75 8h-13.5c-.69 0-1.25.56-1.25 1.25v5.5c0 .69.56 1.25 1.25 1.25h13.5c.69 0 1.25-.56 1.25-1.25v-5.5c0-.69-.56-1.25-1.25-1.25zm-1 1.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0zm-10 2.5a.75.75 0 0 1 1.5 0v1.25a.75.75 0 1 1-1.5 0v-1.25zm8.5 0a.75.75 0 0 1 1.5 0v1.25a.75.75 0 1 1-1.5 0v-1.25zm-8.5 1.25a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5z" />
                            </svg>
                            <div className="ml-4">
                                <h4 className="font-medium text-sm sm:text-base lg:text-lg">Kimliğinizi doğrulayın</h4>
                                <p className="text-gray-500 text-xs sm:text-sm lg:text-base">Para yatırma sınırsız, para çekme için gerekli</p>
                            </div>
                        </div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 sm:h-6 sm:w-6 text-green-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <div className="flex items-center justify-between bg-white p-3 sm:p-4 rounded-lg shadow w-full mb-6">
                        <div className="flex items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 sm:h-6 sm:w-6 text-gray-700"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path d="M18.75 8h-13.5c-.69 0-1.25.56-1.25 1.25v5.5c0 .69.56 1.25 1.25 1.25h13.5c.69 0 1.25-.56 1.25-1.25v-5.5c0-.69-.56-1.25-1.25-1.25zm-1 1.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0zm-10 2.5a.75.75 0 0 1 1.5 0v1.25a.75.75 0 1 1-1.5 0v-1.25zm8.5 0a.75.75 0 0 1 1.5 0v1.25a.75.75 0 1 1-1.5 0v-1.25zm-8.5 1.25a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5z" />
                            </svg>
                            <div className="ml-4">
                                <h4 className="font-medium text-sm sm:text-base lg:text-lg">Adresinizi doğrulayın</h4>
                                <p className="text-gray-500 text-xs sm:text-sm lg:text-base">Adresinizi doğrulayın</p>
                            </div>
                        </div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 sm:h-6 sm:w-6 text-green-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <button className="bg-green-600 text-white text-sm sm:text-base lg:text-lg font-medium h-12 w-full rounded-md hover:bg-green-700">
                        Continue to Deposit
                    </button>
                </div>
            </div>
        </div>
    );
};
