export const LiveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    className="mb-0"
    height="30"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" transform="translate(2.5 1)">
      <circle cx="9.5" cy="12.139" r="7.917" fill="#FFF" fillRule="nonzero" />
      <path
        fill="#38495C"
        fillRule="nonzero"
        d="M13.858 2.609L16.307 2.609 16.307 5.775 13.858 5.775z"
        transform="rotate(-55 15.082 4.192)"
      />
      <path
        fill="#212121"
        fillRule="nonzero"
        d="M14.62 3.019l1.73 1.203-.802 1.14-1.73-1.203.801-1.14zm-.127-.739l-1.415 2.006L15.675 6.1l1.404-2.005-2.586-1.816z"
        opacity=".15"
      />
      <path
        fill="#38495C"
        fillRule="nonzero"
        d="M2.336 2.971L5.503 2.971 5.503 5.389 2.336 5.389z"
        transform="rotate(-35 3.92 4.18)"
      />
      <path
        fill="#212121"
        fillRule="nonzero"
        d="M4.391 3.019l.781 1.119-1.73 1.203-.782-1.119L4.391 3.02zm.127-.739L1.92 4.096 3.314 6.08l2.597-1.858L4.518 2.28z"
        opacity=".15"
      />
      <path
        fill="#38495C"
        fillRule="nonzero"
        d="M11.083 0H7.917c-.29.006-.522.239-.528.528v.528c.006.289.239.522.528.527h1.055v1.584h1.056V1.583h1.055c.29-.005.522-.238.528-.527V.528c-.006-.29-.239-.522-.528-.528z"
      />
      <path
        fill="#212121"
        fillRule="nonzero"
        d="M11.083.528v.528h-1.055c-.292 0-.528.236-.528.527 0-.291-.236-.527-.528-.527H7.917V.528h3.166zm0-.528H7.917c-.29.006-.522.239-.528.528v.528c.006.289.239.522.528.527h1.055v1.584h1.056V1.583h1.055c.29-.005.522-.238.528-.527V.528c-.006-.29-.239-.522-.528-.528z"
        opacity=".15"
      />
      <path
        fill="#38495C"
        fillRule="nonzero"
        d="M9.5 4.222c4.372 0 7.917 3.545 7.917 7.917s-3.545 7.917-7.917 7.917-7.917-3.545-7.917-7.917S5.128 4.222 9.5 4.222zm0-1.583c-5.247 0-9.5 4.253-9.5 9.5s4.253 9.5 9.5 9.5 9.5-4.253 9.5-9.5-4.253-9.5-9.5-9.5z"
      />
      <path
        stroke="#D7282F"
        strokeLinecap="round"
        strokeWidth=".5"
        d="M15.854 12.139L17.026 12.139M1.942 12.139L3.146 12.139M9.5 18.493L9.5 19.76M9.5 4.581L9.5 5.784"
      />
      <path
        stroke="#818B8C"
        strokeWidth=".75"
        d="M13.997 7.642L15.094 6.544M3.906 17.733L5.014 16.625M13.997 16.625L15.094 17.733M3.906 6.544L5.014 7.642M15.379 9.743L16.836 9.152M2.164 15.126L3.621 14.535M11.896 18.018L12.487 19.475M6.513 4.803L7.104 6.259M15.348 14.609L16.794 15.221M2.206 9.057L3.652 9.669M7.03 17.987L6.418 19.433M12.582 4.845L11.97 6.291"
      />
      <circle
        cx="9.5"
        cy="12.139"
        r="1.056"
        fill="#D7282F"
        fillRule="nonzero"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M9.5 4.75c4.08 0 7.389 3.308 7.389 7.389 0 4.08-3.308 7.389-7.389 7.389-4.08 0-7.389-3.308-7.389-7.39 0-4.08 3.308-7.388 7.389-7.388zm0-.528c-4.372 0-7.917 3.545-7.917 7.917s3.545 7.917 7.917 7.917 7.917-3.545 7.917-7.917c0-2.1-.834-4.113-2.32-5.598C13.614 5.056 11.6 4.222 9.5 4.222z"
      />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M16.287 5.5c.2.803.288 1.63.264 2.459-.274 5.848-5.911 11.305-11.611 11.611-.491.016-.982-.016-1.467-.095 3.902 3.144 9.575 2.716 12.962-.978 3.386-3.693 3.322-9.382-.148-12.998z"
        opacity=".12"
      />
      <path
        fill="#212121"
        fillRule="nonzero"
        d="M9.5 3.167c4.955 0 8.972 4.017 8.972 8.972 0 4.955-4.017 8.972-8.972 8.972-4.955 0-8.972-4.017-8.972-8.972.006-4.953 4.02-8.967 8.972-8.972zm0-.528c-5.247 0-9.5 4.253-9.5 9.5s4.253 9.5 9.5 9.5 9.5-4.253 9.5-9.5-4.253-9.5-9.5-9.5z"
        opacity=".15"
      />
      <path
        stroke="#D7282F"
        strokeLinecap="round"
        strokeWidth=".5"
        d="M9.5 12.139L15.854 9.553"
      />
    </g>
    <div xmlns="" id="divScriptsUsed" />
    <script
      xmlns=""
      id="globalVarsDetection"
      src="chrome-extension://cmkdbmfndkfgebldhnkbfhlneefdaaip/js/wrs_env.js"
    />
  </svg>
);
export const LiveIcons = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" transform="translate(2.5 1)">
      <circle cx="9.5" cy="12.139" r="7.917" fill="#FFF" fillRule="nonzero" />
      <path
        fill="#38495C"
        fillRule="nonzero"
        d="M13.858 2.609L16.307 2.609 16.307 5.775 13.858 5.775z"
        transform="rotate(-55 15.082 4.192)"
      />
      <path
        fill="#212121"
        fillRule="nonzero"
        d="M14.62 3.019l1.73 1.203-.802 1.14-1.73-1.203.801-1.14zm-.127-.739l-1.415 2.006L15.675 6.1l1.404-2.005-2.586-1.816z"
        opacity=".15"
      />
      <path
        fill="#38495C"
        fillRule="nonzero"
        d="M2.336 2.971L5.503 2.971 5.503 5.389 2.336 5.389z"
        transform="rotate(-35 3.92 4.18)"
      />
      <path
        fill="#212121"
        fillRule="nonzero"
        d="M4.391 3.019l.781 1.119-1.73 1.203-.782-1.119L4.391 3.02zm.127-.739L1.92 4.096 3.314 6.08l2.597-1.858L4.518 2.28z"
        opacity=".15"
      />
      <path
        fill="#38495C"
        fillRule="nonzero"
        d="M11.083 0H7.917c-.29.006-.522.239-.528.528v.528c.006.289.239.522.528.527h1.055v1.584h1.056V1.583h1.055c.29-.005.522-.238.528-.527V.528c-.006-.29-.239-.522-.528-.528z"
      />
      <path
        fill="#212121"
        fillRule="nonzero"
        d="M11.083.528v.528h-1.055c-.292 0-.528.236-.528.527 0-.291-.236-.527-.528-.527H7.917V.528h3.166zm0-.528H7.917c-.29.006-.522.239-.528.528v.528c.006.289.239.522.528.527h1.055v1.584h1.056V1.583h1.055c.29-.005.522-.238.528-.527V.528c-.006-.29-.239-.522-.528-.528z"
        opacity=".15"
      />
      <path
        fill="#38495C"
        fillRule="nonzero"
        d="M9.5 4.222c4.372 0 7.917 3.545 7.917 7.917s-3.545 7.917-7.917 7.917-7.917-3.545-7.917-7.917S5.128 4.222 9.5 4.222zm0-1.583c-5.247 0-9.5 4.253-9.5 9.5s4.253 9.5 9.5 9.5 9.5-4.253 9.5-9.5-4.253-9.5-9.5-9.5z"
      />
      <path
        stroke="#D7282F"
        strokeLinecap="round"
        strokeWidth=".5"
        d="M15.854 12.139L17.026 12.139M1.942 12.139L3.146 12.139M9.5 18.493L9.5 19.76M9.5 4.581L9.5 5.784"
      />
      <path
        stroke="#818B8C"
        strokeWidth=".75"
        d="M13.997 7.642L15.094 6.544M3.906 17.733L5.014 16.625M13.997 16.625L15.094 17.733M3.906 6.544L5.014 7.642M15.379 9.743L16.836 9.152M2.164 15.126L3.621 14.535M11.896 18.018L12.487 19.475M6.513 4.803L7.104 6.259M15.348 14.609L16.794 15.221M2.206 9.057L3.652 9.669M7.03 17.987L6.418 19.433M12.582 4.845L11.97 6.291"
      />
      <circle
        cx="9.5"
        cy="12.139"
        r="1.056"
        fill="#D7282F"
        fillRule="nonzero"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M9.5 4.75c4.08 0 7.389 3.308 7.389 7.389 0 4.08-3.308 7.389-7.389 7.389-4.08 0-7.389-3.308-7.389-7.39 0-4.08 3.308-7.388 7.389-7.388zm0-.528c-4.372 0-7.917 3.545-7.917 7.917s3.545 7.917 7.917 7.917 7.917-3.545 7.917-7.917c0-2.1-.834-4.113-2.32-5.598C13.614 5.056 11.6 4.222 9.5 4.222z"
      />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M16.287 5.5c.2.803.288 1.63.264 2.459-.274 5.848-5.911 11.305-11.611 11.611-.491.016-.982-.016-1.467-.095 3.902 3.144 9.575 2.716 12.962-.978 3.386-3.693 3.322-9.382-.148-12.998z"
        opacity=".12"
      />
      <path
        fill="#212121"
        fillRule="nonzero"
        d="M9.5 3.167c4.955 0 8.972 4.017 8.972 8.972 0 4.955-4.017 8.972-8.972 8.972-4.955 0-8.972-4.017-8.972-8.972.006-4.953 4.02-8.967 8.972-8.972zm0-.528c-5.247 0-9.5 4.253-9.5 9.5s4.253 9.5 9.5 9.5 9.5-4.253 9.5-9.5-4.253-9.5-9.5-9.5z"
        opacity=".15"
      />
      <path
        stroke="#D7282F"
        strokeLinecap="round"
        strokeWidth=".5"
        d="M9.5 12.139L15.854 9.553"
      />
    </g>
    <div xmlns="" id="divScriptsUsed" />
    <script
      xmlns=""
      id="globalVarsDetection"
      src="chrome-extension://cmkdbmfndkfgebldhnkbfhlneefdaaip/js/wrs_env.js"
    />
  </svg>
);

export const FourEightStd = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(3 1.55) translate(0 1.444)">
        <circle cx="13" cy="13" r="10.833" fill="#FFF" fillRule="nonzero" />
        <circle cx="13" cy="13" r="10.111" fill="#BEC3C7" fillRule="nonzero" />
        <path
          stroke="#38495C"
          strokeWidth=".75"
          d="M21.667 13L23.833 13M2.167 13L4.333 13M13 21.667L13 23.833M13 2.6L13 4.333"
        />
        <path
          stroke="#313E4F"
          strokeLinecap="round"
          strokeWidth=".5"
          d="M13 13L13 4.333"
        />
        <path
          stroke="#38495C"
          strokeLinecap="round"
          strokeWidth=".75"
          d="M13 13L13 6.5"
        />
        <g fillRule="nonzero" transform="translate(11.556 11.556)">
          <circle cx="1.444" cy="1.444" r="1" fill="#FFF" />
          <path
            fill="#38495C"
            d="M1.444 0C.578 0 0 .578 0 1.444 0 2.311.578 2.89 1.444 2.89c.867 0 1.445-.578 1.445-1.445C2.889.578 2.31 0 1.444 0z"
          />
        </g>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M13 2.889c5.633 0 10.111 4.478 10.111 10.111 0 5.633-4.478 10.111-10.111 10.111-5.633 0-10.111-4.478-10.111-10.111C2.889 7.367 7.367 2.889 13 2.889m0-.722C7.078 2.167 2.167 7.077 2.167 13c0 5.922 4.91 10.833 10.833 10.833 5.922 0 10.833-4.91 10.833-10.833 0-5.922-4.91-10.833-10.833-10.833z"
        />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M22.244 3.9c.29 1.156.434 2.167.29 3.322-.434 7.945-8.09 15.456-15.89 15.89-.722 0-1.3 0-2.022-.145 2.311 1.877 5.056 2.889 8.234 2.889 7.222 0 13-5.778 13-13C26 9.533 24.556 6.21 22.244 3.9z"
          opacity=".12"
        />
        <path
          fill="#38495C"
          fillRule="nonzero"
          d="M11.7 2.022L11.411 0C5.056.867 0 6.356 0 13c0 7.222 5.778 13 13 13s13-5.778 13-13C26 6.356 20.944.867 14.589.144l.433 1.156-.722 1.011c5.344.578 9.678 5.2 9.678 10.833 0 5.923-4.911 10.834-10.834 10.834-5.922 0-10.977-5.056-10.977-10.978 0-5.489 4.189-10.111 9.533-10.689v-.289z"
        />
      </g>
      <path
        fill="#38495C"
        fillRule="nonzero"
        d="M10.689 0.578L11.122 4.911 13.144 2.311z"
        transform="translate(3 1.55)"
      />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M14.733 2.311c5.778.867 10.4 5.922 10.545 11.845.144 6.788-5.2 12.422-11.99 12.566C6.5 26.867.868 21.522.723 14.733.578 8.378 5.2 3.178 11.267 2.311c0 0 .144 0 1.444-.144L10.544.722s0 .722.145 1.011C4.478 2.744-.144 8.233 0 14.733c.144 7.223 6.211 12.856 13.289 12.711C20.367 27.3 26.144 21.234 26 14.156c-.144-6.5-5.2-11.845-11.411-12.567 0 .289 0 .289.144.722z"
        opacity=".12"
        transform="translate(3 1.55)"
      />
    </g>
    <div xmlns="" id="divScriptsUsed" />
    <script
      xmlns=""
      id="globalVarsDetection"
      src="chrome-extension://cmkdbmfndkfgebldhnkbfhlneefdaaip/js/wrs_env.js"
    />
  </svg>
);
export const ThreeStd = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g fill="none" fillRule="evenodd" transform="translate(3 3)">
      <circle cx="13" cy="13" r="10.833" fill="#FFF" fillRule="nonzero" />
      <path
        fill="#E79F3C"
        fillRule="nonzero"
        d="M13 13V0c7.222 0 13 5.778 13 13"
      />
      <path
        fill="#38495C"
        fillRule="nonzero"
        d="M13 2.167c5.922 0 10.833 4.91 10.833 10.833 0 5.922-4.91 10.833-10.833 10.833-5.922 0-10.833-4.91-10.833-10.833C2.167 7.078 7.077 2.167 13 2.167M13 0C5.778 0 0 5.778 0 13s5.778 13 13 13 13-5.778 13-13S20.222 0 13 0z"
      />
      <path
        stroke="#313E4F"
        stroke-linecap="round"
        strokeWidth=".75"
        d="M13 13L20.367 13"
      />
      <path
        stroke="#38495C"
        strokeWidth=".75"
        d="M21.667 13L23.833 13M2.167 13L4.333 13M13 21.667L13 23.833M13 2.167L13 4.333"
      />
      <g fillRule="nonzero" transform="translate(11.556 11.556)">
        <circle cx="1.444" cy="1.444" r="1" fill="#FFF" />
        <path
          fill="#38495C"
          d="M1.444 0C.578 0 0 .578 0 1.444 0 2.311.578 2.89 1.444 2.89c.867 0 1.445-.578 1.445-1.445C2.889.578 2.31 0 1.444 0z"
        />
      </g>
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M13 2.889c5.633 0 10.111 4.478 10.111 10.111 0 5.633-4.478 10.111-10.111 10.111-5.633 0-10.111-4.478-10.111-10.111C2.889 7.367 7.367 2.889 13 2.889m0-.722C7.078 2.167 2.167 7.077 2.167 13c0 5.922 4.91 10.833 10.833 10.833 5.922 0 10.833-4.91 10.833-10.833 0-5.922-4.91-10.833-10.833-10.833z"
      />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M22.244 3.9c.29 1.156.434 2.167.29 3.322-.434 7.945-8.09 15.456-15.89 15.89-.722 0-1.3 0-2.022-.145 2.311 1.877 5.056 2.889 8.234 2.889 7.222 0 13-5.778 13-13C26 9.533 24.556 6.21 22.244 3.9z"
        opacity=".12"
      />
      <path
        fill="#212121"
        fillRule="nonzero"
        d="M13 .722c6.789 0 12.278 5.49 12.278 12.278 0 6.789-5.49 12.278-12.278 12.278C6.211 25.278.722 19.788.722 13 .722 6.211 6.212.722 13 .722M13 0C5.778 0 0 5.778 0 13s5.778 13 13 13 13-5.778 13-13S20.222 0 13 0z"
        opacity=".15"
      />
      <path
        stroke="#313E4F"
        stroke-linecap="round"
        strokeWidth=".5"
        d="M13 13L13 3.178"
      />
    </g>
    <div xmlns="" id="divScriptsUsed" />
    <script
      xmlns=""
      id="globalVarsDetection"
      src="chrome-extension://cmkdbmfndkfgebldhnkbfhlneefdaaip/js/wrs_env.js"
    />
  </svg>
);

export const Statistic = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11.5 20V13H7V20H11.5ZM11.5 20H16M11.5 20V4H16V20M16 20H20.5V10H16V20Z"
      stroke="#9D9D9D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <div xmlns="" id="divScriptsUsed" />
    <script
      xmlns=""
      id="globalVarsDetection"
      src="chrome-extension://cmkdbmfndkfgebldhnkbfhlneefdaaip/js/wrs_env.js"
    />
  </svg>
);
