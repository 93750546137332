import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import { openDB } from "idb";

const DB_NAME = "tipixgo";
const TBL_NAME = "resource";

const createIndexedDBStorage = () => {
  return {
    async getItem(key) {
      const db = await openDB(DB_NAME, 1, {
        upgrade(db) {
          db.createObjectStore(TBL_NAME);
        },
      });
      return db.get(TBL_NAME, key);
    },
    async setItem(key, value) {
      const db = await openDB(DB_NAME, 1);
      return db.put(TBL_NAME, value, key);
    },
    async removeItem(key) {
      const db = await openDB(DB_NAME, 1);
      return db.delete(TBL_NAME, key);
    },
  };
};

const storage = createIndexedDBStorage();

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(thunk));

const persistor = persistStore(store);

export { store, persistor };
