import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { shirt1 } from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedItem } from "../../store/actions/sidebarActions";
import { calculateOdds } from "../../store/actions/homeTypes";
import "../../app/pages/user-page/userPage.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export const TopCombis = () => {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [isScrolledToLeft, setIsScrolledToLeft] = useState(true);
  const [isScrolledToRight, setIsScrolledToRight] = useState(false);

  const show = useSelector((state) => state?.calculateBetSlip?.showPopup);
  const scrollContainerRef = useRef(null);

  const handleScrollToEnd = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollWidth,
        behavior: "smooth",
      });
    }
  };

  const handleScrollToStart = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    }
  };
  const openPopup = (matchDetails) => {
    setSelectedMatch(matchDetails);
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    setSelectedMatch(null);
  };

  useEffect(() => {
    if (show) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            "http://38.180.122.61:3000/api/combi/get-top-combis"
          );
          setData(response.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }
  }, [show]);

  const visibleCards = 3;

  const nextSlide = () => {
    if (currentIndex < data.combis.length - visibleCards) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setIsScrolledToLeft(scrollLeft === 0);
      setIsScrolledToRight(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleOdd = () => {
    data.combiMatchMap[selectedMatch.combiId].forEach((e) => {
      const asd = data.combisMatchData[e];
      dispatch(
        calculateOdds({
          id: asd.matchId + "-" + asd.marketId + "-" + asd.outcomeId,
          matchId: asd.matchId,
          home_team_name: asd.homeName,
          away_team_name: asd.awayName,
          oddsId: asd.outcomeId,
          option: asd.resultName,
          value: asd.Odd,
          line: "",
          market_name: asd.market_name,
        })
      );
    });
    closePopup();
  };

  if (!data)
    return (
      <div>
        <div className="shadow rounded-md p-4 max-w-sm w-64 mx-auto">
          <div className="animate-pulse flex space-x-4">
            <div className="rounded-full bg-slate-200 h-10 w-10"></div>
            <div className="flex-1 space-y-6 py-1">
              <div className="h-2 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="h-2 bg-slate-200 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="relative w-full mb-2 md:mb-2 mt-2 p-5 md:p-0 md:mt-5 bg-white md:bg-custom">
      <style>
        {`
          .match-container {
            border-width: thin;
            border-style: solid
            padding: 10px;
            margin-bottom: 0px;
          }

          
        `}
      </style>
      <div
        className={`flex items-center justify-between w-full gap-4  pb-2`}
      >
        <div className="flex flex-col">
          <span className="font-semibold text-lg">Top-Kombis</span>
          <span className="font-normal text-xs text-gray-400">
            from other Tipico customers
          </span>
        </div>
        <div className="flex items-center gap-2">
          <span
            className="font-Source-Code-Pro font-semibold text-sm"
            onClick={() => {
              dispatch(setSelectedItem("top-combis"));
            }}
          >
            Show all
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18px"
            viewBox="0 -960 960 960"
            width="18px"
            fill="#B7B7B7"
          >
            <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
          </svg>
        </div>
      </div>
      <div className="relative w-full">
        {/* Sol geri düğmesi */}
        {!isScrolledToLeft && (
          <button
            className="absolute left-0 hidden md:block top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg z-10"
            onClick={handleScrollToStart}
          >
            <FaChevronLeft className="text-gray-600 text-sm" />
          </button>
        )}

        <div
          className="overflow-x-scroll md:overflow-hidden whitespace-nowrap p-1 relative"
          ref={scrollContainerRef}
          onScroll={handleScroll}
        >
          <div className="flex transition-transform h-50 gap-1 mb-2 duration-500 ease-in-out">
            {Object.values(data.combis).map((card, index) => (
              <div
                key={index}
                className="w-custom flex flex-col h-full rounded-b-customs mr-1 shadow-topCombisShadow rounded-t-xl bg-white"
              >
                <div className="w-custom flex justify-evenly items-center rounded-t-customs h-12 bg-white border-b border-gray-200 pr-1">
                  <div className="flex flex-col items-center">
                    <span className="bg-combisColor rounded-sm font-semibold justify-center w-12 text-white px-1 pb-0.5 flex">
                      {card.betsPlaced}
                    </span>
                    <span className="font-semibold text-xs text-gray-500">
                      Bets placed
                    </span>
                  </div>

                  <div className="flex flex-col items-center">
                    <span className="font-bold text-black-500">
                      {data.combiMatchMap[card.combiId].length}s
                    </span>
                    <span className="font-semibold text-xs text-gray-500">
                      Combi
                    </span>
                  </div>

                  <div className="flex flex-col items-center">
                    <span className="font-bold text-black-500">
                      {card.totalOdds}
                    </span>
                    <span className="font-semibold text-xs text-gray-500">
                      Total quota
                    </span>
                  </div>
                </div>

                <div className="CombiCard-styles-module-content md:hidden">
                  <div className=" flex flex-col Markets-styles-module-list overflow-y-hidden justify-start items-start md:h-32 bg-white border-b border-gray-200">
                    {data.combiMatchMap[card.combiId].map((matchId, idx) => (
                      <div className="flex flex-col px-3 py-1" key={idx}>
                        <div className="flex items-center">
                          <img width={20} src={shirt1} alt="" className="mr-2" />
                          <span className="text-continually font-medium">
                            {{
                              1: data.combisMatchData[matchId].homeName,
                              2: data.combisMatchData[matchId].awayName,
                            }[data.combisMatchData[matchId].resultName] ||
                              data.combisMatchData[matchId].resultName}
                          </span>
                        </div>
                        <div
                          className={`${idx === 2 && "opacity-25"
                            } text-xs font-medium text-gray-500`}
                        >
                          {data.combisMatchData[matchId].homeName} -{" "}
                          {data.combisMatchData[matchId].awayName}
                        </div>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={() => openPopup(card)}
                    className="bg-combisColor w-full  h-11 text-white px-4 py-2 rounded"
                  >
                    See the betting slip #{index + 1}
                  </button>
                </div>


                <div className="w-custom flex flex-col md:flex hidden overflow-y-hidden overflow-x-hidden  justify-start items-start px-2 md:pt-0 md:my-1 h-custom bg-white border-b border-gray-200 pr-1 overflow-auto">
                  {data.combiMatchMap[card.combiId].map((matchId, idx) => (
                    <div className="flex flex-col px-3 py-1" key={idx}>
                      <div className="flex items-center">
                        <img width={20} src={shirt1} alt="" className="mr-2" />
                        <span className="text-continually font-medium">
                          {{
                            1: data.combisMatchData[matchId].homeName,
                            2: data.combisMatchData[matchId].awayName,
                          }[data.combisMatchData[matchId].resultName] ||
                            data.combisMatchData[matchId].resultName}
                        </span>
                      </div>
                      <div
                        className={`${idx === 2 && "opacity-25"
                          } text-xs font-medium text-gray-500`}
                      >
                        {data.combisMatchData[matchId].homeName} -{" "}
                        {data.combisMatchData[matchId].awayName}
                      </div>
                    </div>
                  ))}
                </div>


                <div
                  className="flex w-full py-2 font-semibold md:flex hidden text-sm rounded-b-customs bg-combisColor text-white md:bg-white md:text-combisColor justify-center items-center"
                  onClick={() => openPopup(card)}
                >
                  See the betting slip # {index + 1}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Sağ ileri düğmesi */}
        {!isScrolledToRight && (
          <button
            className="absolute right-0 hidden border shadow-xl shadow md:block top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg z-10"
            onClick={handleScrollToEnd}
          >
            <FaChevronRight className="text-gray-600 text-sm" />
          </button>
        )}
      </div>


      {
        currentIndex > 0 && (
          <button
            onClick={prevSlide}
            className="absolute w-8 h-8 top-[200px] -left-3 shadow-lg shadow-gray-300 transform -translate-y-1/2 bg-black text-black rounded-full"
          >
            &#10094;
          </button>
        )
      }
      {
        currentIndex < data.combis.length - visibleCards && (
          <button
            onClick={nextSlide}
            className="absolute w-8 h-8 top-[200px] -right-3  shadow-lg shadow-gray-300  transform -translate-y-1/2  text-black rounded-full bg-white "
          >
            &#10095;
          </button>
        )
      }
      {
        isPopupVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
            <div className="bg-white rounded-lg w-96 customs:w-1/4 relative">
              <div className="flex justify-between items-center pb-2 p-4 mb-2">
                <div>
                  <div className="flex flex-col items-start">
                    <span className="blinking text-white text-sm font-semibold px-1 rounded bg-blue-500">
                      {selectedMatch.betsPlaced}
                    </span>
                    <span className="text-black text-continually text-gray-600 text-left px-0.4 mt-0.5 rounded block ">
                      Bets Placed
                    </span>
                  </div>
                  <div></div>
                </div>
                <div className="flex-grow text-center pr-5">
                  <span className="font-semibold">
                    {data.combiMatchMap[selectedMatch.combiId].length}s Combi
                  </span>
                </div>
                <button
                  onClick={closePopup}
                  className="text-gray-600 hover:text-black text-2xl"
                  style={{ width: "24px", height: "24px" }}
                >
                  &#10005; {/* X icon */}
                </button>
              </div>

              {/* Content Section */}
              <div className="text-center mt-2">
                {/* <p>Bets Placed: {selectedMatch.betsPlaced}</p> */}
                {/* <p>Total Odds: {selectedMatch.totalOdds}</p> */}

                {/* Non-Clickable Table in Popup */}
                <div className="popup relative">
                  {data.combiMatchMap[selectedMatch.combiId].map(
                    (matchId, index) => {
                      const match = data.combisMatchData[matchId];
                      return (
                        <div key={index} className=" border text-left text-sm">
                          <div className="CombiPopupMarket-styles-module-game bg-combisBg">
                            <div className="CombiPopupMarket-styles-module-title">
                              <div className="CombiMarketResult-styles-module-market-result CombiPopupMarket-styles-module-title-left-part">
                                <span className="CombiMarketResult-styles-module-market-result-label text-popupText">
                                  Tipp : {match.resultName}{" "}
                                </span>
                              </div>
                              <div className="CombiPopupMarket-styles-module-odds CombiPopupMarket-styles-module-bold">
                                {match.Odd}
                              </div>
                            </div>
                            <div className="text-xs text-gray-500">
                              {match.homeName} - {match.awayName}
                            </div>
                            <div className="text-xs text-gray-500">
                              {match.eventStartTime}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                  <div
                    className="match-container text-left text-sm mb-0"
                    style={{ backgroundColor: "rgb(244, 246, 246)" }}
                  >
                    <div className="CombiPopup-styles-module-total">
                      <span>Total Odds:</span>
                      <div className="CombiPopup-styles-module-odds">
                        {selectedMatch.totalOdds}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-4 mb-3">
                    <button
                      onClick={() => {
                        handleOdd();
                      }}
                      className="bg-combisColor w-11/12  h-12 text-white px-4 py-2 rounded"
                    >
                      Add Bet to Coupon
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div >
  );
};
