import { useCallback } from "react";
import { useSelector } from "react-redux";
import { FHOddsNameGroup } from "./FHOddsNameGroup";
import FHComboboxGroup from "./FHComboboxGroup";
import { FHMatches } from "./FHMatchesRow";

export const FilteredHomeBody = ({ betTab, selectedOddsIds, showDetail, setSelectedOddsIds, dropDownCount }) => {
  const handleComboboxValuesChange = useCallback(
    (values) => {
      const filteredValues = values.filter((value) => value !== null && value !== "");
      setSelectedOddsIds(filteredValues);
    },
    [setSelectedOddsIds]
  );

  const selectionMode = useSelector((state) => state?.homereducer?.customSwitch);

  return (
    <div className="grid">
      <div
        className={`col-md-7 transition-transform duration-1000 w-full ease-in-out transform ${
          showDetail ? "-translate-x-0" : "translate-x-0"
        }`}
      >
        <div className=" shadow-xl ">
          <div className="sticky flex justify-end bg-customs top-0 gap-3 pr-5 pt-2 z-20 px-3 sm:flex hidden">
            <div className="flex gap-4 justify-end">
              <FHComboboxGroup
                dropDownCount={dropDownCount}
                hasEmptyLabel={false}
                onValuesChange={handleComboboxValuesChange}
              />
            </div>
          </div>

          <div className="bg-white w-full">
            <div className="grid  grid-cols-12">
              <div className="col-span-4  bg-customs"></div>
              <div className={`${!selectionMode ? "hidden" : "block"} col-span-8 grid grid-cols-12 bg-customs  `}>
                <FHOddsNameGroup selectedOddsIds={selectedOddsIds} showDetail={showDetail} />
              </div>
              <FHMatches betTab={betTab} selectedOddsIds={selectedOddsIds} showDetail={showDetail} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
