import React, { useState } from "react";

export const Limitverlauf = () => {
    const data = [
        { date: "03.09.2024, 19:25", type: "Sports Einzahlungslimit", limit: "10.000,00 €/Monatlich" },
        { date: "03.09.2024, 19:25", type: "Games Einzahlungslimit", limit: "1.000,00 €/Monatlich" },
        { date: "07.11.2023, 01:07", type: "Sports Einzahlungslimit", limit: "10.000,00 €/Monatlich" },
        { date: "07.11.2023, 01:07", type: "Games Einzahlungslimit", limit: "1.000,00 €/Monatlich" },
        { date: "05.09.2022, 20:29", type: "Sports Einzahlungslimit", limit: "1.000,00 €/Monatlich" },
        { date: "05.09.2022, 20:29", type: "Games Einzahlungslimit", limit: "1.000,00 €/Monatlich" },
        { date: "29.08.2022, 08:49", type: "Sports Einzahlungslimit", limit: "10.000,00 €/Monatlich" },
        { date: "26.07.2022, 18:51", type: "Sports Einzahlungslimit", limit: "1.000,00 €/Monatlich" },
        { date: "22.06.2022, 15:00", type: "Games Einzahlungslimit", limit: "1.000,00 €/Monatlich" },
        { date: "22.06.2022, 14:59", type: "Sports Einzahlungslimit", limit: "10.000,00 €/Monatlich" },
        { date: "22.06.2022, 14:59", type: "Games Einzahlungslimit", limit: "1.000,00 €/Monatlich" },
    ];

    return (
        <div className="flex justify-center w-full min-h-screen md:p-2 md:p-2">
            <div className="w-full max-w-4xl md:p-4">
                <h2 className="text-lg text-gray-700 font-semibold mb-4 hidden md:block">Limits & Schließung</h2>
                <div className="w-full bg-white rounded-lg shadow-lg p-4 md:p-8">
                    <p className="text-gray-500 text-xs mb-4">
                        Die Übersicht über die Limitänderungen ist ab 01.07.2021 verfügbar
                    </p>

                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-300">
                            <thead>
                                <tr className="bg-gray-100 border-b">
                                    <th className="text-left p-2 md:p-4 font-medium text-gray-600 border-r">Datum</th>
                                    <th className="text-left p-2 md:p-4 font-medium text-gray-600 border-r">Art des Limits</th>
                                    <th className="text-left p-2 md:p-4 font-medium text-gray-600">Neues Limit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index} className="border-b hover:bg-gray-50">
                                        <td className="p-2 md:p-4 text-gray-700 border-r">{item.date}</td>
                                        <td className="p-2 md:p-4 text-gray-700 border-r">{item.type}</td>
                                        <td className="p-2 md:p-4 text-gray-700">{item.limit}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
