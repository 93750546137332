import { Option, Select } from "@mui/joy";
import React, { useEffect, useMemo, useState } from "react";
import IconButton from "@mui/joy/IconButton";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { useSelector } from "react-redux";

const TMComboboxGroup = ({ hasEmptyLabel, onValuesChange, dropDownCount }) => {
  const availableOddsMap = useSelector(
    (state) => state?.topmatches?.availableOddsMap
  );

  const [selectedValues, setSelectedValues] = useState([]);

  const handleSelectChange = (index, selectedValue) => {
    const newValues = [...selectedValues];
    newValues[index] = selectedValue;
    setSelectedValues(newValues);
  };

  const options = useMemo(() => {
    return Object.values(availableOddsMap ?? []).map((element) => ({
      value: element.id,
      label: element.name,
    }));
  }, [availableOddsMap]);

  useEffect(() => {
    const initialSelectedValues =
      options.length > 0
        ? options.slice(0, dropDownCount).map((opt) => opt.value)
        : Array(dropDownCount).fill("");
    if (
      JSON.stringify(initialSelectedValues) !== JSON.stringify(selectedValues)
    ) {
      setSelectedValues(initialSelectedValues);
    }
  }, [availableOddsMap, dropDownCount]);

  useEffect(() => {
    if (onValuesChange) {
      onValuesChange(selectedValues);
    }
  }, [selectedValues]);

  const handleClearSelect = (index) => {
    const newValues = [...selectedValues];
    newValues[index] = "";
    setSelectedValues(newValues);
  };

  const renderOptions = (selectedValues, currentSelectValue) => {
    return options.map((option) => (
      <Option
        key={option.value}
        value={option.value}
        disabled={
          option.value !== "" &&
          selectedValues.includes(option.value) &&
          option.value !== currentSelectValue
        }
      >
        {option.label}
      </Option>
    ));
  };

  return (
    <div className="flex gap-3 mr-14">
      {selectedValues.map((value, index) => (
        <div key={index} className="relative flex items-center ">
          <Select
            sx={{
              fontSize: "0.75rem",
              fontWeight: "700",
              height: "28px",
              borderRadius:"4px",
              borderWidth:"1.5px",
              borderColor:"lightgray",
              width:"140px",
              justifySelf: "left",
              textAlign: "left",
            }}
            className={`${
              availableOddsMap &&
              availableOddsMap[value] &&
              availableOddsMap[value].isHaveLine
                ? "ml-2 bg-black"
                : ""
            } bg-black h-7 w-36  shadow-none  hover:border-black focus:outline-none pr-6`}
            placeholder="Select"
            value={value}
            {...(hasEmptyLabel &&
              value && {
                endDecorator: (
                  <IconButton
                    size="lg"
                    variant="plain"
                    color="neutral"
                    className="absolute right-0 mr-2"
                    onClick={() => {
                      handleClearSelect(index);
                    }}
                  >
                    <CloseRounded />
                  </IconButton>
                ),
                indicator: null,
              })}
            onChange={(e, newValue) => handleSelectChange(index, newValue)}
          >
            {renderOptions(selectedValues, value)}
          </Select>
        </div>
      ))}
    </div>
  );
};

export default TMComboboxGroup;
