import axios from "axios";
import { TOPMATCHES_SUCCESS, TOPMATCHES_FAILURE, TOPMATCHES_WEBSOCKET_UPDATE_ODDS_2 } from "./actiontypes";
import { top_matches } from "../paths";
import { io } from "socket.io-client";

export const TopMatchesApi = (body) => async (dispatch) => {
  try {
    const response = await axios.post(top_matches, body);
    dispatch({
      type: TOPMATCHES_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    dispatch({
      type: TOPMATCHES_FAILURE,
      payload: error.response?.data?.message || "api failed. Please try again.",
    });
  }
};

export const initializeWebSocketTopMatches = () => (dispatch) => {
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjAsInVzZXJuYW1lIjoicGFraXN0YW5sYWhvcmUiLCJhY3RpdmUiOnRydWUsImxvZ2luX2RhdGUiOiIyMDI0LTA5LTE1VDE3OjM2OjA0LjcwM1oiLCJwYXJlbnRfaWQiOm51bGwsInJvbGVfaWQiOjQsImJhbGFuY2UiOiIwLjAwMCIsImNob2ljZXMiOm51bGwsImNyZWF0ZWRBdCI6IjIwMjQtMDktMTVUMTc6MzY6MDQuNzAzWiIsInVwZGF0ZWRBdCI6IjIwMjQtMDktMTVUMTc6MzY6MDQuNzAzWiIsInJvbGUiOnsiaWQiOjQsIm5hbWUiOiJVU0VSIiwicG93ZXIiOjEsImNyZWF0ZWRBdCI6IjIwMjQtMDgtMDJUMjE6MDA6MDAuMDAwWiIsInVwZGF0ZWRBdCI6IjIwMjQtMDgtMDJUMjE6MDA6MDAuMDAwWiJ9LCJpYXQiOjE3MjczNzAzNjcsImV4cCI6MTcyNzM3Mzk2N30.v_7uLLdWTggkMVzECQ16va3iT9wR5E9LaARuuTjpnRk";

  const socket = io("http://38.180.122.61:3001", {
    reconnectionDelayMax: 10000,
    auth: {
      token: token,
    },
    pingInterval: 15000, // 15 saniye
    pingTimeout: 20000, // 20 saniye

    transports: ["websocket"],
  });

  socket.on("odds_2", (data) => {
    dispatch({
      type: TOPMATCHES_WEBSOCKET_UPDATE_ODDS_2,
      payload: data,
    });
  });

  socket.on("connect", () => {
    console.log("Connected to Socket.IO server");
  });

  socket.on("connect_error", (err) => {
    console.error("Connection error:", err);
  });

  socket.on("disconnect", () => {
    console.log("Socket disconnected");
  });

  return socket;
};
