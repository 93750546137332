import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customSwitch } from "../../store/actions/homeTypes";

const CustomSwitch = () => {
  const dispatch = useDispatch();
  const selectionMode = useSelector((state) => state?.homereducer?.customSwitch);
  const [isModalOpen, setIsModalOpen] = useState(false); // Başlangıçta kapalı
  const [selectedOption, setSelectedOption] = useState("Zeit");

  useEffect(() => {
    setIsModalOpen(true); // Bileşen yüklendiğinde modal açılır
  }, []);

  const updateSelection = (option) => {
    setSelectedOption(option); // Seçili opsiyonu güncelle
  };

  const confirmSelection = () => {
    dispatch(customSwitch(selectedOption)); // Seçilen opsiyonu onayla ve modalı kapat
    setIsModalOpen(false);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="flex justify-center items-center hidden md:flex">
        <div
          className={`flex w-32 h-7 bg-customs border border-gray-300 rounded-full`}
        >
          <button
            onClick={() => dispatch(customSwitch())}
            className={`flex-1 flex text-xs font-medium justify-center items-center ${selectionMode
              ? `rounded-full bg-white border border-gray-100 shadow-md`
              : ""
              } `}
          >
            {"Time"}
          </button>
          <button
            onClick={() => dispatch(customSwitch())}
            className={`flex-1 flex text-xs font-medium justify-center items-center ${!selectionMode
              ? `rounded-full bg-white border border-gray-100 shadow-md`
              : ""
              } `}
          >
            {"Contest"}
          </button>
        </div>
      </div>

      {/* Modal görünümü */}
      {isModalOpen && (
        <div className="flex justify-center items-center md:hidden">
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-end">
            <div
              className={`bg-white rounded-t-lg w-full max-w-md p-4 transition-opacity transition-transform duration-300 ease-out 
              ${isModalOpen ? 'opacity-100 translate-y-1' : 'opacity-0 translate-y-4'}`}
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">Sortiere nach</h2>
                <button onClick={toggleModal} className="text-gray-500 text-2xl">&times;</button>
              </div>

              <ul className="space-y-2">
                {["Zeit", "Wettbewerb"].map((option) => (
                  <li key={option}>
                    <button
                      onClick={() => updateSelection(option)}
                      className="flex items-center w-full text-left p-2 border rounded-md"
                    >
                      <span className="flex-grow">{option}</span>
                      {selectedOption === option && (
                        <span className="relative flex items-center pr-2 justify-center">
                          <span className="absolute w-6 h-6 border border-green-500 rounded-full"></span>
                          <span className="text-green-500 text-1xl">&#x25CF;</span>
                        </span>
                      )}
                    </button>
                  </li>
                ))}
              </ul>

              <button
                onClick={confirmSelection}
                className="mt-4 w-full bg-blue-500 text-white rounded-md py-2"
              >
                Schließen
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomSwitch;
