// src/app/pages/auth/Login.js
import React, { useEffect, useState } from 'react';
import registerImage from "../../../assets/images/register.jpeg";
import "./userPage.css";
import { Navigate } from "react-router-dom";

const Payout = () => {
    return (
        <div className="w-full flex justify-center pt-12 pb-12 bg-gray-100">
            <div className="LayoutWrapper-styles-module-layout w-full md:w-12/12 lg:w-12/12 xl:w-12/12">
                <div className="flex flex-col md:flex-row place-self-center">
                    
                    {/* Image container (visible only on larger screens) */}
                    <div className="hidden md:block BannerImage-styles-module-banner-container BannerImage-styles-module-banner-container-cashier">
                        <div className="BannerImage-styles-module-image-container">
                            <picture><img src={registerImage} alt="cashier-banner" className="w-full h-auto" /></picture>
                        </div>
                    </div>

                    {/* Right div fills the full width on mobile */}
                    <div className="w-full md:w-12/12 flex bg-gray-100 relative  ">
                        <button className="absolute top-4 right-4 p-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="#333"
                            >
                                <path d="M256-200l-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                            </svg>
                        </button>

                        <div className="bg-white p-20 md:p-12 rounded-lg shadow-lg max-w-sm w-full">
                            <h2 className="text-center text-lg font-semibold mb-4">Auszahlung</h2>
                            <p className="text-center text-sm text-gray-600 mb-6">Wähle deine Auszahlungsmethode</p>

                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                                <button className="flex flex-col items-center text-xs p-2 bg-gray-100 rounded-md shadow hover:bg-gray-200 transition">
                                    <img src="/path/to/zastrpay-logo.png" alt="Zastrpay" className="h-10 mb-2" />
                                </button>
                                <button className="flex flex-col items-center text-xs p-2 bg-gray-100 rounded-md shadow hover:bg-gray-200 transition">
                                    <img src="/path/to/bank-logo.png" alt="Bank Überweisung" className="h-10 mb-2" />
                                </button>
                                <button className="flex flex-col items-center text-xs p-2 bg-gray-100 rounded-md shadow hover:bg-gray-200 transition">
                                    <img src="/path/to/paysafecard-logo.png" alt="Paysafecard" className="h-10 mb-2" />
                                </button>
                                <button className="flex flex-col items-center text-xs p-2 bg-gray-100 rounded-md shadow hover:bg-gray-200 transition">
                                    <img src="/path/to/tipico-logo.png" alt="Tipico Shop" className="h-10 mb-2" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Payout;
