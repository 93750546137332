import React from 'react';
import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer';
import { RightSidebar } from '../../components/Sidebar/RightSidebar';
import { BottomAppbar } from '../../components/BottomAppbar';
import BetGuideSidebar from '../../components/BetGuide/BetGuideSidebar';
import Payout from '../pages/user-page/payout';
import BetsPage from '../pages/user-page/userWetten';
import News from '../pages/user-page/news';




const MyBetsLayout = ({ }) => {
    return (
        <div className="flex flex-col min-h-screen font-Source-Code-Pro ">
            <Header />
            <div className="flex flex-grow md:mt-14">
                <main className="flex-1 overflow-x-auto bg-custom ">
                    <News />
                </main>
            </div>
            <BottomAppbar />
        </div>
    );
};

export default MyBetsLayout;
