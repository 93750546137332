const initialState = {
  isAuthenticated: false,
  allWinner: [],
  error: null,
};

export const TopWinnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOPWINNER_SUCCESS":
      return {
        ...state,
        isAuthenticated: true,
        data: action.payload,
        error: null,
      };
    case "TOPWINNER_FAILURE":
      return {
        ...state,
        isAuthenticated: false,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
