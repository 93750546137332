import * as yup from "yup";


const registerValidationSchema = yup.object().shape({
  // fname: yup.string().required("First Name is required"), 

  // surname: yup.string().required("Surname is required"), 

  username: yup.string().required("Username is required"), 

  // email: yup.string().email("Invalid email address") .required("Email is required"), 

  password: yup.string().required("Password is required"), 


});


const secondRegisterPageValidationSchema = yup.object().shape({
  // streetAndHouseNumber: yup.string().required(
  //   "Street and house number is required"
  // ), 

  // postcode: yup.string().required("Postcode is required"), 

  // location: yup.string().required("Location is required"), 

  // nationality: yup.string().required("Nationality is required"), 

  // birthName: yup.string().required("Birth name is required"), 

  // birthCountry: yup.string().required("Birth country is required"), 

  // birthplace: yup.string().required("Birthplace is required"), 

  // termsAndConditions: yup.boolean().oneOf(
  //   [true],
  //   "You must accept the terms and conditions"
  // ), // Validates checkbox acceptance
});

export { registerValidationSchema, secondRegisterPageValidationSchema };
