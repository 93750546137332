import { useSelector } from "react-redux";
import { memo, useCallback, useEffect, useState } from "react";
import { useHandleOddsClick } from "../utility/handleOdd";
import { LockButton } from "../Button/LockButton";

const OddButton = memo(({ i, item, textColor }) => {
  const handleOdd = useHandleOddsClick();
  const [isSelected, setIsSelected] = useState(false);
  const bets = useSelector((state) => state?.homereducer.odds);
  useEffect(() => {
    const bet = bets.filter((e) => e.id === item.id + "-" + i.market_id + "-" + i.outcome_id);
    setIsSelected(bet !== null && bet.length !== 0);
  }, [bets]);

  // console.log("render");

  if (i.status !== 0) {
    return <LockButton />;
  }
  return (
    <div
      className={`font-custom text-gray-900 flex h-[32px] md:h-9 text-center text-sm font-semibold justify-center rounded-custom items-center border 
        ${
          isSelected ? "bg-focusBg" : "bg-newButton md:bg-buttonbg"
        } border-gray-300 bg-buttonbg py-2 cursor-pointer col-span-1 relative`}
      onClick={() => {
        handleOdd({ isSelected: isSelected, match: item, odd: i });
      }}
    >
      <span className="block sm:hidden text-left text-xs flex-shrink-0 ml-2 font-normal text-gray-500">{i.name}</span>
      <span className={`${isSelected ? "text-white" : "text-oddColor"} mx-auto text-center`}>{i.value}</span>
      {textColor === "text-green-500" && (
        <span
          className={`absolute text-gray-700 text-l ${textColor}`}
          style={{ right: "0", top: "15%", transform: "translateY(-50%)" }}
        >
          ◥
        </span>
      )}
      {textColor === "text-red-500" && (
        <span
          className={`absolute text-gray-700 text-l ${textColor}`}
          style={{ right: "0", top: "85%", transform: "translateY(-50%)" }}
        >
          ◢
        </span>
      )}
    </div>
  );
});

const OddButtonGold = ({ i, item, textColor }) => {
  const handleOdd = useHandleOddsClick();
  const [isSelected, setIsSelected] = useState(false);
  const bets = useSelector((state) => state?.homereducer.odds);
  useEffect(() => {
    const bet = bets.filter((e) => e.id === item.id + "-" + i.market_id + "-" + i.outcome_id);
    setIsSelected(bet !== null && bet.length !== 0);
  }, [bets]);

  if (i.status !== 0) {
    return <LockButton />;
  }
  return (
    <div
      className={`font-custom text-gray-900 flex h-[32px] md:h-9 text-center text-sm font-semibold justify-center rounded-custom items-center border 
        ${
          isSelected ? "bg-focusBg" : "bg-newButton md:bg-buttonbg"
        } border-gray-300 bg-buttonbg py-2 cursor-pointer col-span-1 relative`}
      onClick={() => {
        handleOdd({ isSelected: isSelected, match: item, odd: i });
      }}
    >
      <span className="block sm:hidden text-left text-xs flex-shrink-0 ml-2 font-normal text-gray-500">{i.name}</span>
      <span className={`${isSelected ? "text-white" : "text-oddColor"} mx-auto text-center`}>{i.value}</span>
      {textColor === "text-green-500" && (
        <span
          className={`absolute text-gray-700 text-l ${textColor}`}
          style={{ right: "0", top: "15%", transform: "translateY(-50%)" }}
        >
          ◥
        </span>
      )}
      {textColor === "text-red-500" && (
        <span
          className={`absolute text-gray-700 text-l ${textColor}`}
          style={{ right: "0", top: "85%", transform: "translateY(-50%)" }}
        >
          ◢
        </span>
      )}
    </div>
  );
};

export const OddButtons = memo(({ item, selectedOddsIds }) => {
  const [bgColors, setBgColors] = useState({});
  const [previousValues, setPreviousValues] = useState({});

  const oddList = useSelector((state) => state?.livematches?.odds);
  const availableOdds = useSelector((state) => state?.livematches?.availableOddsMap);

  useEffect(() => {
    // console.log("oddList", oddList);
    const newValues = selectedOddsIds.reduce((acc, oddId) => {
      const oddData = Object.values(oddList[item.id + "_" + oddId] ?? []);
      oddData.forEach((odd) => {
        if (odd.id !== undefined) acc[odd.id] = odd.value;
      });
      return acc;
    }, {});
    const updatedIds = Object.keys(newValues).filter((id) => {
      const oldValue = previousValues[id];
      const newValue = newValues[id];
      return oldValue !== undefined && oldValue !== newValue;
    });
    updatedIds.forEach((id) => {
      const oldValue = previousValues[id];
      const newValue = newValues[id];
      handleDataChange(id, oldValue > newValue ? "text-red-500" : "text-green-500");
    });

    setPreviousValues(newValues);
  }, [oddList, selectedOddsIds]);

  const handleDataChange = useCallback((id, color) => {
    setBgColors((prev) => ({ ...prev, [id]: color }));
    setTimeout(() => {
      setBgColors((prev) => ({ ...prev, [id]: undefined }));
    }, 3000);
  }, []);

  return selectedOddsIds.map((oddId, index) => {
    const oddData = Object.values(oddList[item.id + "_" + oddId] ?? []);
    const availableOdd = availableOdds[oddId + ""];
    const minLineValue =
      availableOdd?.isHaveLine && oddData.length > 0 ? oddData.map((item) => item.line).sort((a, b) => a - b)[0] : "";
    const filteredData = availableOdd?.isHaveLine ? oddData.filter((item) => item.line === minLineValue) : oddData;

    const captionsLength = availableOdd?.captions.length;

    const oddButtonMap = filteredData.slice(0, captionsLength);

    const count = captionsLength + (availableOdd?.isHaveLine ? 1 : 0);

    // console.log("oddData", oddData);

    return (
      <div key={index} className={`col-span-1 grid grid-cols-${count} gap-1`}>
        <>
          {availableOdd?.isHaveLine && (
            <span className="text-xs font-normal min-w-5 items-center text-gray-500 flex col-span-1 justify-center text-right">
              {minLineValue}
            </span>
          )}
          {availableOdd?.captions.map((captionsName) => {
            const i = oddButtonMap.filter((e) => e.name === captionsName);

            if (i[0] == null) {
              return <LockButton key={captionsName} />;
            }
            return <OddButton key={captionsName} textColor={bgColors[i[0].id]} i={i[0]} item={item} />;
          })}
        </>
      </div>
    );
  });
});
