import { useState } from "react";
import {
  calendar,
  combination,
  earth,
  german,
  topevents,
} from "../../assets/images";
import { DynamicLeftMenus } from "./MenuItems";
import { useDispatch } from "react-redux";
import { setSelectedItem } from "../../store/actions/sidebarActions";

const quickLinks = [
  {
    icon: topevents,
    title: "Top Events",
    value: "top-events",
  },
  {
    icon: combination,
    title: "Top Combis",
    value: "top-combis",
  },
  {
    icon: calendar,
    title: "Today's Matches",
    value: "todays-matches",
  },
  {
    icon: german,
    title: "German Football",
    value: "german-football",
  },
  {
    icon: earth,
    title: "European Football",
    value: "european-football",
  },
];

export const LeftSideBar = () => {
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = useState(null);

  const activeMenuChange = (value) => {
    dispatch(setSelectedItem(value));
    setActiveMenu(value);
  };

  return (
    <div
      className={`bg-custom p-6 hidden sm:block w-[13.33%] lg:w-[13.33%] h-[100vh] overflow-auto shadow-sm scrollbar-hide hover:bg-customs`}
    >
      <div className="w-full">
        <h6 className="text-gray-400 font-semibold text-xs mb-4">Quick access</h6>
        <ul className="space-y-2">
          {quickLinks.map((row, index) => (
            <li
              className={`flex items-center cursor-pointer p-1 rounded hover:bg-white ${
                activeMenu === row.value ? "bg-white font-semibold" : ""
              }`}
              onClick={() => activeMenuChange(row.value)}
              key={index}
            >
              <img
                className="mr-3"
                src={row.icon}
                alt="icon"
                width={20}
                height={20}
              />
              <span className="text-xs md:text-sm">{row.title}</span>
            </li>
          ))}
        </ul>
        <DynamicLeftMenus activeMenu={activeMenu} />
      </div>
    </div>
  );
};
