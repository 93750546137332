import axios from "axios";
import { SPORTS_SUCCESS, SPORTS_FAILURE } from "./actiontypes";
import { sports_Api, sports_length_Api } from "../paths";

export const sportsApi = () => async (dispatch) => {
  try {
    const response = await axios.get(sports_Api);
    const response2 = await axios.post(sports_length_Api, { sport_ids: response?.data?.map((e) => e.id) });
    dispatch({
      type: SPORTS_SUCCESS,
      payload: {
        data: response?.data,
        counts: response2?.data,
      },
    });
  } catch (error) {
    dispatch({
      type: SPORTS_FAILURE,
      payload: "api failed. Please try again.",
    });
  }
};
