export const Login_URL = "http://38.180.122.61:3000/api/user/login";
export const Registration_URL = "http://38.180.122.61:3000/api/user/register";
export const MatchData_URL = "http://38.180.122.61:3000/api/match/get-match-data";
export const sports_Api = "http://38.180.122.61:3000/api/mapping/sports";
export const sports_length_Api = "http://38.180.122.61.:3000/api/match/get-total-counts";
export const top_matches = "http://38.180.122.61:3000/api/match//top-matches";
export const placeBetSlip = "http://38.180.122.61:3000/api/betslip/place-betslip/";
export const calculateBetSlip = "http://38.180.122.61:3000/api/betslip/calculate-betslip/";
export const top_competition = "http://38.180.122.61:3001/api/menu/top-tournaments";
export const filtered_home = "http://38.180.122.61:3000/api/match/get-match-data";
export const last_min = "http://38.180.122.61:3000/api/match/last-minute";
export const top_winner = "http://38.180.122.61:3000/api/top-winner/get-winner-list";
export const lives_matches = "http://38.180.122.61:3000/api/match/live";
export const webSocketUrl = "http://38.180.122.61:3001";
