import { useSelector } from "react-redux";
import React, { useState, memo, useEffect } from "react";
import { useHandleOddsClick } from "../utility/handleOdd";
import { LockButton } from "../Button/LockButton";

const TMOddButton = memo(({ i, item }) => {
  const handleOdd = useHandleOddsClick();
  const [isSelected, setIsSelected] = useState(false);
  const bets = useSelector((state) => state?.homereducer.odds);

  useEffect(() => {
    const bet = bets.filter(
      (e) => e.id === item.id + "-" + i.market_id + "-" + i.outcome_id
    );
    setIsSelected(bet !== null && bet.length !== 0);
  }, [bets]);

  if (i.status !== 0) {
    return <LockButton />;
  }

  return (
    <div
      className={`font-custom text-gray-900 flex h-[32px] md:h-[35px] text-center text-sm font-semibold justify-between rounded-custom items-center border border-gray-300   ${
        isSelected ? "bg-focusBg" : "bg-newButton md:bg-buttonbg"
      } py-1 cursor-pointer col-span-1 relative`}
      onClick={() => {
        handleOdd({ isSelected: isSelected, match: item, odd: i });
      }}
    >
      <span className="block sm:hidden text-left text-xs flex-shrink-0 ml-2 font-normal text-gray-500">
        {i.name}
      </span>
      <span
        className={`${
          isSelected ? "text-white" : "text-oddColor"
        } mx-auto text-center`}
      >
        {i.value}
      </span>
    </div>
  );
});

export default TMOddButton;

export const TMOddButtons = ({ item, selectedOddsIds }) => {
  const oddList = useSelector((state) => state?.topmatches?.odds);
  const availableOdds = useSelector(
    (state) => state?.topmatches?.availableOddsMap
  );

  return selectedOddsIds.map((oddId) => {
    const oddData = Object.values(oddList[item.id + "_" + oddId] ?? []);
    const availableOdd = availableOdds[oddId + ""];
    const minLineValue =
      availableOdd?.isHaveLine && oddData?.length > 0
        ? oddData.map((item) => item.line).sort((a, b) => a - b)[0]
        : "";
    const filteredData = availableOdd?.isHaveLine
      ? oddData.filter((item) => item.line === minLineValue)
      : oddData;

    const captionsLength = availableOdd?.captions.length;

    const oddButtonMap = filteredData.slice(0, captionsLength);

    const count = captionsLength + (availableOdd?.isHaveLine ? 1 : 0);

    return (
      <div
        key={item.id + "_" + oddId}
        className={`col-span-1 grid grid-cols-${count} gap-1`}
      >
        <>
          {availableOdd?.isHaveLine && (
            <span className="text-xs font-normal min-w-5 items-center flex col-span-1 justify-center text-right">
              {minLineValue}
            </span>
          )}
          {availableOdd?.captions.map((captionsName, index) => {
            const i = oddButtonMap.filter((e) => e.name === captionsName);
            if (i[0] == null) {
              return (
                <div
                  key={index}
                  className="flex justify-center h-8 rounded-custom items-center border shadow-md border-gray-300 bg-disabledOdds py-2 cursor-not-allowed col-span-1"
                >
                  <span className="text-gray-700">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="15"
                      viewBox="0 0 11 15"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        transform="translate(0 1)"
                      >
                        <rect width="11" height="9" y="5" fill="#BBB" rx="1" />
                        <path
                          stroke="#BBB"
                          strokeWidth="1.5"
                          d="M3 6.106c-.017 0 4.543.003 5 0v-3.66C8 1.094 6.88 0 5.5 0S3 1.095 3 2.445v3.66z"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              );
            }
            return <TMOddButton key={index} i={i[0]} item={item} />;
          })}
        </>
      </div>
    );
  });
};
