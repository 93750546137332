import { useDispatch } from "react-redux";
import { removeMatches } from "../../store/actions/live_matches";
import { useEffect } from "react";

export const PeriodChecker = ({ match_id, sport_id, period_id, status, time }) => {
  const sportId = Number(sport_id);
  const period = Number(period_id);
  const matchId = Number(match_id);
  const dispatch = useDispatch();
  useEffect(() => {
    if (status > 3) {
      const timeoutId = setTimeout(() => {
        dispatch(removeMatches(matchId));
      }, 15000);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  if (Number(status) > 3) {
    return (
      <div className="flex">
        <span className="font-bold">END</span>
      </div>
    );
  }
  if (sportId === 1) {
    if (period === 5) {
      return (
        <>
          <div className="flex">
            <span className={`font-bold`}>HT</span>
          </div>
          <div>
            <span className="font-bold text-xxs text-red-800">LIVE</span>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <div className="flex ">
        <span
          className={` font-bold mt-1  place-content-centermd:font-semibold md:text-popupText text-red-600 md:text-gray-800`}
        >
          {time}
        </span>
      </div>
      <div className="hidden md:block">
        <span className="font-bold text-xxs text-red-700"> LIVE</span>
      </div>
    </>
  );
};
